import React from 'react';

const iconWizardStep = ({ color = '#ECEFF3', size = 1 }) => {
  return (
    <svg
      width={(size * 26).toString()}
      height={(size * 24).toString()}
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.8018 5.11499L24.5348 4.14599L12.9998 0.776993L1.46177 4.14599L1.19477 5.11499C0.906766 6.17399 0.759766 7.26299 0.759766 8.35799C0.759766 14.751 6.04277 21.357 12.5378 23.088L12.9998 23.211L13.4618 23.088C19.9568 21.36 25.2398 14.751 25.2398 8.35799C25.2398 7.26299 25.0928 6.17399 24.8018 5.11499ZM13.2308 22.218L12.9998 22.278L12.7688 22.215C6.64277 20.586 1.65977 14.37 1.65977 8.35799C1.65977 7.34399 1.79477 6.33299 2.06477 5.35199L2.19677 4.86599L12.9998 1.71599L23.8028 4.86899L23.9348 5.35499C24.2048 6.33599 24.3398 7.34699 24.3398 8.36099C24.3398 14.37 19.3568 20.586 13.2308 22.218Z"
        fill={color}
      />
      <path
        d="M13.0005 2.65199L2.93255 5.59199C2.68955 6.47399 2.56055 7.40099 2.56055 8.35799C2.56055 14.124 7.48055 19.878 13.0005 21.348C18.5206 19.878 23.4406 14.124 23.4406 8.35799C23.4406 7.40099 23.3086 6.47399 23.0686 5.59199L13.0005 2.65199Z"
        fill={color}
      />
    </svg>
  );
};

export default iconWizardStep;
