const useBase64ToBlobUrl = (base64String) => {
  const base64toBlob = (data) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.substr(
      'data:application/pdf;base64,'.length
    );

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: 'application/pdf' });
  };

  const blob = base64toBlob(base64String);
  const url = URL.createObjectURL(blob);

  return {
    url,
  };
};
export default useBase64ToBlobUrl;
