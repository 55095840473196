const useApplicationFormInitialStates = () => {
  const applicationFormWizardInitialStates = {
    applicationFormWizard: [
      {
        value: 1,
        label: 'APPLICATION FORM',
        wrapper: {
          title: 'Application for a new liquor licence',
          subtitle: 'Liquor licence application form',
          description:
            'Personal Information \nTell us about yourself! All fields are required.',
          width: '892px',
        },
      },
      {
        value: 2,
        label: 'ADVERTISING FEE PAYMENT',
        wrapper: {
          title: 'Application for a new liquor licence',
          subtitle: 'Advertising fee payment',
          description:
            'This license shall be of no force and effect unless the prescribed fees have been paid to the government revenue office or through mobile money. \nPlease enter the receipt number found on your proof of payment.',
          width: '1202px',
        },
      },
      {
        value: 3,
        label: 'UPLOAD DOCUMENTATION',
        wrapper: {
          title: 'Application for a new liquor licence',
          subtitle: '',
          description: '',
          width: '892px',
        },
      },

      {
        value: 4,
        label: 'ISSUANCE FEE PAYMENT',
        wrapper: {
          title: 'Application for a new liquor licence',
          subtitle: '',
          description: '',
          width: '1202px',
        },
      },
    ],
    applicationFormRenewWizard: [
      {
        value: 1,
        label: 'RENEWAL FORM',
        wrapper: {
          title: 'Renewal liquor license',
          subtitle: 'Renew Liquor licence application form',
          description:  'Personal Information \nTell us about yourself! All fields are required.',
          width: '892px',
        },
      },
      {
        value: 2,
        label: 'ADVERTISING FEE PAYMENT',
        wrapper: {
          title: 'Renewal liquor license',
          subtitle: 'Advertising fee payment',
          description:
            'This license shall be of no force and effect unless the prescribed fees have been paid to the government revenue office or through mobile money. \nPlease enter the receipt number found on your proof of payment.',
          width: '1202px',
        },
      },
      {
        value: 3,
        label: 'UPLOAD DOCUMENTATION',
        wrapper: {
          title: 'Renewal liquor license',
          subtitle: 'Upload Documentation',
          description: 'Complete the process by uploading the following documentation, all files are necessary for your request. You can download de affidavit form template',
          width: '892px',
        },
      },

      {
        value: 4,
        label: 'ISSUANCE FEE PAYMENT',
        wrapper: {
          title: 'Application for a new liquor licence',
          subtitle: '',
          description: '',
          width: '1202px',
        },
      },
    ],
    currentStep: 1,
    stepsEnabled: 1,
  };
  const applicationFormInitialStates = {
    applicationReady: false,
    applicationForm: {
      id: '',
      personal_identification_number: '',
      names: '',
      surnames: '',
      licenceType: 2,
      business_relationship_id: '',
      business_style: '',
      license_nature_type_id: '',
      area_id: '',
      business_property_id: '',
      business_plot_number: '',
      farm_number: '',
      company_number: '',
      portion: '',
      office_id: '',
      business_building: '',
      business_street: '',
      business_suburb: '',
      postal_address: '',
      residential_address: '',
      business_premises: '',
      premises_owner_id: '',
      business_land_id: '',
      business_town: '',
      city: '',

      receiptNumber: '',
      advertisingPrescribedFees: '',
      advertisingIssueDate: '',

      inspectionComment: '',
      identity_card: {
        name: '',
        data: '',
      },
      lease_agreement: {
        name: '',
        data: '',
      },
      leased_business_premise: {
        name: '',
        data: '',
      },
      permit_no: {
        name: '',
        data: '',
      },
      title_deed: {
        name: '',
        data: '',
      },
      certificate_of_partnership: {
        name: '',
        data: '',
      },
      certificate_of_incorporation: {
        name: '',
        data: '',
      },
      form_j: {
        name: '',
        data: '',
      },
      memorandum_and_articles_of_association: {
        name: '',
        data: '',
      },
      previous_application: {
        name: '',
        data: '',
      },
      affidavit: {
        name: '',
        data: '',
      },

      typeOfPaymentFifthStep: '',
      issueProofOfPayment: {
        name: '',
        data: '',
      },
      issuanceFeeExpires: '',

      requestForChangesComment: '',
      status: '',
      is_renewal: 0,
      isNewRenewal: 0,
    },
  };

  const applicationFormDraftInitialStates = {
    blankPdf:
      'data:application/pdf;base64,JVBERi0xLjcKCjQgMCBvYmoKPDwKL0ZpbHRlciAvRmxhdGVEZWNvZGUKL0xlbmd0aCA5MQo+PgpzdHJlYW0KeJxljDEOgCAQBPt9BR/wsneAyDN8g4laSKH/L6TRQLhqMpM9Soqs5yhkD9PYlqCSjQzBbQU39AsjPAco3vp9Qcpe0kxqY6/WqppJrBxzDf+LXp/YseIFLLIj/wplbmRzdHJlYW0KZW5kb2JqCjUgMCBvYmoKPDwKPj4KZW5kb2JqCjMgMCBvYmoKPDwKL0NvbnRlbnRzIFsgNCAwIFIgXQovQ3JvcEJveCBbIDAuMCAwLjAgNTk1LjMyMDAxIDg0MS45MjAwNCBdCi9NZWRpYUJveCBbIDAuMCAwLjAgNTk1LjMyMDAxIDg0MS45MjAwNCBdCi9QYXJlbnQgMiAwIFIKL1Jlc291cmNlcyA1IDAgUgovUm90YXRlIDAKL1R5cGUgL1BhZ2UKPj4KZW5kb2JqCjIgMCBvYmoKPDwKL0NvdW50IDEKL0tpZHMgWyAzIDAgUiBdCi9UeXBlIC9QYWdlcwo+PgplbmRvYmoKMSAwIG9iago8PAovUGFnZXMgMiAwIFIKL1R5cGUgL0NhdGFsb2cKPj4KZW5kb2JqCjYgMCBvYmoKPDwKL0F1dGhvciAoSmF2aWVyIEdhbWJvYSkKL0NyZWF0aW9uRGF0ZSAoRDoyMDIxMTIyOTE2MjkwNC0wNScwMCcpCi9Nb2REYXRlIChEOjIwMjExMjI5MTYyOTA0LTA1JzAwJykKL1Byb2R1Y2VyIChNaWNyb3NvZnQ6IFByaW50IFRvIFBERikKL1RpdGxlIChEb2N1bWVudG8gc2luIHTtdHVsbyAtIERvY3VtZW50b3MgZGUgR29vZ2xlKQo+PgplbmRvYmoKeHJlZgowIDcNCjAwMDAwMDAwMDAgNjU1MzUgZg0KMDAwMDAwMDQyOCAwMDAwMCBuDQowMDAwMDAwMzY5IDAwMDAwIG4NCjAwMDAwMDAxOTIgMDAwMDAgbg0KMDAwMDAwMDAwOSAwMDAwMCBuDQowMDAwMDAwMTcxIDAwMDAwIG4NCjAwMDAwMDA0NzcgMDAwMDAgbg0KdHJhaWxlcgo8PAovSW5mbyA2IDAgUgovUm9vdCAxIDAgUgovU2l6ZSA3Cj4+CnN0YXJ0eHJlZgo2ODcKJSVFT0YK',

    applicationFormStep1: {
      id: '',
      personal_identification_number: '',
      names: '',
      surnames: '',
      licenceType: 2,
      business_relationship_id: '',
      business_style: '',
      license_nature_type_id: '',
      area_id: '',
      company_number: '',
      business_property_id: '',
      business_plot_number: '',
      farm_number: '',
      portion: '',
      office_id: '',
      business_building: '',
      business_street: '',
      business_suburb: '',
      postal_address: '',
      residential_address: '',
      business_premises: '',
      premises_owner_id: '',
      business_land_id: '',
      city: '',
      applicationType: {
        id: '',
        name: '',
      },
      business_town: '',
    },
    applicationFormStep2: {
      receiptNumber: '',
      advertisingPrescribedFees: '',
      advertisingIssueDate: '',
    },
    applicationFormStep3: {
      identity_card: {
        name: '',
        data: '',
      },
      lease_agreement: {
        name: '',
        data: '',
      },
      leased_business_premise: {
        name: '',
        data: '',
      },
      permit_no: {
        name: '',
        data: '',
      },
      title_deed: {
        name: '',
        data: '',
      },
      certificate_of_partnership: {
        name: '',
        data: '',
      },
      certificate_of_incorporation: {
        name: '',
        data: '',
      },
      form_j: {
        name: '',
        data: '',
      },
      memorandum_and_articles_of_association: {
        name: '',
        data: '',
      },
      previous_application: {
        name: '',
        data: '',
      },
      affidavit: {
        name: '',
        data: '',
      },
    },

    applicationFormStep5: {
      typeOfPaymentFifthStep: '',
      issueProofOfPayment: {
        name: '',
        data: '',
      },
      issuanceFeeExpires: '',
    },
  };
  const applicationFormHelperListsInitialStates = {
    licenceTypes: [
      { id: 1, name: 'Trading Licence' },
      { id: 2, name: 'Liquor Licence' },
      { id: 3, name: 'Bookmakers Licence' },
      { id: 4, name: 'Special Economic Zone Licence' },
    ],

    businessRelationships: [
      {
        id: 3,
        code: 'C',
        name: 'Company',
      },
      {
        id: 1,
        code: 'I',
        name: 'Individual',
      },
      {
        id: 2,
        code: 'P',
        name: 'Partnership',
      },
    ],

    natureOfLicences: [
      {
        id: 13,
        code: '213',
        name: 'AFRICAN BEER LICENCE',
        category: null,
      },
      {
        id: 10,
        code: '210',
        name: 'AFRICAN BEER MANUFACTURING AND WHOLESALE LICENCE',
        category: null,
      },
      {
        id: 7,
        code: '207',
        name: 'BOTTLE STORE LIQUOR LICENCE',
        category: null,
      },
      {
        id: 9,
        code: '209',
        name: "BREWER'S LICENCE",
        category: null,
      },
      {
        id: 11,
        code: '211',
        name: 'CANTEEN LICENCE',
        category: null,
      },
      {
        id: 3,
        code: '203',
        name: 'CLUB LIQUOR LICENCE',
        category: null,
      },
      {
        id: 12,
        code: '212',
        name: "EMPLOYER'S LIQUOR LICENCE",
        category: null,
      },
      {
        id: 2,
        code: '202',
        name: 'HOTEL LIQUOR LICENCE',
        category: null,
      },
      {
        id: 6,
        code: '206',
        name: 'RESTAURANT LIQUOR LICENCE',
        category: null,
      },
      {
        id: 8,
        code: '208',
        name: 'RESTRICTED BOTTLE STORE LIQUOR LICENCE',
        category: null,
      },
      {
        id: 4,
        code: '204',
        name: 'SPORT CLUB LIQUOR LICENCE',
        category: null,
      },
      {
        id: 5,
        code: '205',
        name: 'UNFORTIFIED WINE AND MALT',
        category: null,
      },
      {
        id: 1,
        code: '201',
        name: 'WHOLESALE LIQUOR LICENCE',
        category: null,
      },
    ],

    areas: [
      {
        id: 1,
        inkhundla_code: '014',
        business_property: 'P',
        long_desc: 'LUPHOHLO FARM',
      },
      {
        id: 2,
        inkhundla_code: '016',
        business_property: 'R',
        long_desc: 'MPHEMBEKATI',
      },
      {
        id: 6,
        inkhundla_code: '029',
        business_property: 'P',
        long_desc: 'EKUKHULUMENI',
      },
      {
        id: 7,
        inkhundla_code: '045',
        business_property: 'P',
        long_desc: 'MASHOBENI',
      },
      {
        id: 8,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: 'KAJAKIZA',
      },
      {
        id: 10,
        inkhundla_code: '004',
        business_property: 'R',
        long_desc: 'MADLOLO',
      },
      {
        id: 55,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'MZACENI',
      },
      {
        id: 132,
        inkhundla_code: '051',
        business_property: 'R',
        long_desc: 'MAGELE',
      },
      {
        id: 2050,
        inkhundla_code: '031',
        business_property: 'R',
        long_desc: 'SANKOLWENI',
      },
      {
        id: 2081,
        inkhundla_code: '019',
        business_property: 'P',
        long_desc: 'MAHLANYA',
      },
      {
        id: 3562,
        inkhundla_code: '051',
        business_property: 'U',
        long_desc: 'HLATHIKHULU (SIBETSAMOYA)',
      },
      {
        id: 6050,
        inkhundla_code: '022',
        business_property: 'R',
        long_desc: 'MGOMFELWENI',
      },
      {
        id: 6202,
        inkhundla_code: '022',
        business_property: 'R',
        long_desc: 'MJIKAZI SIYENDLE',
      },
      {
        id: 6203,
        inkhundla_code: '033',
        business_property: 'R',
        long_desc: 'SICUNUSA',
      },
      {
        id: 6204,
        inkhundla_code: '033',
        business_property: 'R',
        long_desc: 'ENKANYEZINI',
      },
      {
        id: 6205,
        inkhundla_code: '029',
        business_property: 'R',
        long_desc: 'MHLATANE',
      },
      {
        id: 6206,
        inkhundla_code: '022',
        business_property: 'R',
        long_desc: 'NTFUNGULA',
      },
      {
        id: 6207,
        inkhundla_code: '022',
        business_property: 'P',
        long_desc: 'MAHLANGATSHA',
      },
      {
        id: 10000,
        inkhundla_code: '000',
        business_property: 'T',
        long_desc: 'MBABANE REGION',
      },
      {
        id: 10001,
        inkhundla_code: '001',
        business_property: 'R',
        long_desc: 'DLANGENI',
      },
      {
        id: 10002,
        inkhundla_code: '009',
        business_property: 'R',
        long_desc: 'ENDUMO',
      },
      {
        id: 10003,
        inkhundla_code: '001',
        business_property: 'R',
        long_desc: 'NTSINTSA',
      },
      {
        id: 10004,
        inkhundla_code: '009',
        business_property: 'R',
        long_desc: 'MPOLONJENI',
      },
      {
        id: 10005,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: 'SIBOVINI',
      },
      {
        id: 10006,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'TITININI',
      },
      {
        id: 10007,
        inkhundla_code: '002',
        business_property: 'R',
        long_desc: 'MVUTSHINI',
      },
      {
        id: 10008,
        inkhundla_code: '011',
        business_property: 'R',
        long_desc: 'MNYOKANE',
      },
      {
        id: 10009,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: 'NKOMAZI',
      },
      {
        id: 10010,
        inkhundla_code: '011',
        business_property: 'R',
        long_desc: 'KA NCESI',
      },
      {
        id: 10011,
        inkhundla_code: '001',
        business_property: 'R',
        long_desc: 'ETIYENI',
      },
      {
        id: 10012,
        inkhundla_code: '002',
        business_property: 'R',
        long_desc: 'KABELLINA',
      },
      {
        id: 10013,
        inkhundla_code: '011',
        business_property: 'R',
        long_desc: 'HAWANE',
      },
      {
        id: 10014,
        inkhundla_code: '011',
        business_property: 'R',
        long_desc: 'NKHABA',
      },
      {
        id: 10015,
        inkhundla_code: '002',
        business_property: 'R',
        long_desc: 'MVUTSHINI',
      },
      {
        id: 10016,
        inkhundla_code: '014',
        business_property: 'R',
        long_desc: 'KAMASEKO',
      },
      {
        id: 10017,
        inkhundla_code: '011',
        business_property: 'P',
        long_desc: 'MALOLOTSHA',
      },
      {
        id: 10018,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'LUPHALWANE',
      },
      {
        id: 10019,
        inkhundla_code: '002',
        business_property: 'R',
        long_desc: 'NKOKHOKHWENI',
      },
      {
        id: 10020,
        inkhundla_code: '014',
        business_property: 'R',
        long_desc: 'KUCITSEKENI',
      },
      {
        id: 10021,
        inkhundla_code: '014',
        business_property: 'R',
        long_desc: 'MANTABENI',
      },
      {
        id: 10022,
        inkhundla_code: '001',
        business_property: 'R',
        long_desc: 'NTSHAKABILI',
      },
      {
        id: 10023,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'RIVERSIDE',
      },
      {
        id: 10024,
        inkhundla_code: '002',
        business_property: 'R',
        long_desc: 'BUKA',
      },
      {
        id: 10025,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'NGWEMBE',
      },
      {
        id: 10026,
        inkhundla_code: '011',
        business_property: 'R',
        long_desc: 'LONGADVUMI',
      },
      {
        id: 10027,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'LAMDUDU',
      },
      {
        id: 10028,
        inkhundla_code: '009',
        business_property: 'R',
        long_desc: 'MASOKENI',
      },
      {
        id: 10029,
        inkhundla_code: '009',
        business_property: 'R',
        long_desc: 'NKOYOYO',
      },
      {
        id: 10030,
        inkhundla_code: '004',
        business_property: 'R',
        long_desc: 'JOZIDE',
      },
      {
        id: 10031,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'NHLALAKAHLE',
      },
      {
        id: 10032,
        inkhundla_code: '020',
        business_property: 'R',
        long_desc: 'KUDZENI',
      },
      {
        id: 10033,
        inkhundla_code: '001',
        business_property: 'R',
        long_desc: 'MLINDAZWE',
      },
      {
        id: 10034,
        inkhundla_code: '001',
        business_property: 'R',
        long_desc: 'HHUKWINI',
      },
      {
        id: 10035,
        inkhundla_code: '010',
        business_property: 'R',
        long_desc: 'NDZINGENI',
      },
      {
        id: 10036,
        inkhundla_code: '010',
        business_property: 'R',
        long_desc: 'BULANDZENI',
      },
      {
        id: 10037,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'BETSAMOYA',
      },
      {
        id: 10038,
        inkhundla_code: '011',
        business_property: 'R',
        long_desc: 'LUVINJELWENI',
      },
      {
        id: 10039,
        inkhundla_code: '011',
        business_property: 'R',
        long_desc: 'MAGUGA',
      },
      {
        id: 10040,
        inkhundla_code: '005',
        business_property: 'R',
        long_desc: 'MKHUZWENI',
      },
      {
        id: 10041,
        inkhundla_code: '008',
        business_property: 'R',
        long_desc: 'ZINYANE',
      },
      {
        id: 10042,
        inkhundla_code: '010',
        business_property: 'R',
        long_desc: 'BUHLENI',
      },
      {
        id: 10043,
        inkhundla_code: '008',
        business_property: 'R',
        long_desc: 'MALIBENI',
      },
      {
        id: 10044,
        inkhundla_code: '008',
        business_property: 'R',
        long_desc: 'ZINYANE',
      },
      {
        id: 10045,
        inkhundla_code: '010',
        business_property: 'R',
        long_desc: 'NKAMAZI',
      },
      {
        id: 10046,
        inkhundla_code: '008',
        business_property: 'R',
        long_desc: 'MHLANGATANE',
      },
      {
        id: 10047,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MBUZINI',
      },
      {
        id: 10048,
        inkhundla_code: '018',
        business_property: 'R',
        long_desc: 'SIBOVU',
      },
      {
        id: 10049,
        inkhundla_code: '005',
        business_property: 'R',
        long_desc: 'MAYIWANE',
      },
      {
        id: 10050,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'MADLANGEMPISI',
      },
      {
        id: 10051,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'MAVULA',
      },
      {
        id: 10052,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'BUHLEBUYEZA',
      },
      {
        id: 10053,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'SIHHOYE',
      },
      {
        id: 10054,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'BALEGANE',
      },
      {
        id: 10055,
        inkhundla_code: '008',
        business_property: 'R',
        long_desc: 'NHLANGUYAVUKA',
      },
      {
        id: 10056,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MAHLATHINI',
      },
      {
        id: 10057,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'NKABENI',
      },
      {
        id: 10058,
        inkhundla_code: '011',
        business_property: 'R',
        long_desc: 'MNCINA',
      },
      {
        id: 10059,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'MNYOKANYOKA',
      },
      {
        id: 10060,
        inkhundla_code: '012',
        business_property: 'R',
        long_desc: 'NJAKENI',
      },
      {
        id: 10061,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'MZACENI',
      },
      {
        id: 10062,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'ZANDONDO',
      },
      {
        id: 10063,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MGANWINI',
      },
      {
        id: 10064,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'DVOKOLWAKO',
      },
      {
        id: 10065,
        inkhundla_code: '010',
        business_property: 'R',
        long_desc: 'MAJOVANE',
      },
      {
        id: 10066,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: 'KALA ZULU',
      },
      {
        id: 10067,
        inkhundla_code: '008',
        business_property: 'R',
        long_desc: 'NYAKATFO',
      },
      {
        id: 10068,
        inkhundla_code: '002',
        business_property: 'R',
        long_desc: 'MAKHEBELELE',
      },
      {
        id: 10069,
        inkhundla_code: '002',
        business_property: 'R',
        long_desc: 'LUDZIDZINI',
      },
      {
        id: 10070,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'SIDVUNGE',
      },
      {
        id: 10071,
        inkhundla_code: '001',
        business_property: 'R',
        long_desc: 'KA SIKO',
      },
      {
        id: 10072,
        inkhundla_code: '011',
        business_property: 'R',
        long_desc: 'NKOMAZI',
      },
      {
        id: 10073,
        inkhundla_code: '009',
        business_property: 'R',
        long_desc: 'WHITECITY',
      },
      {
        id: 10074,
        inkhundla_code: '011',
        business_property: 'R',
        long_desc: 'MAJUBA',
      },
      {
        id: 10075,
        inkhundla_code: '002',
        business_property: 'R',
        long_desc: 'GELEKECENI',
      },
      {
        id: 10076,
        inkhundla_code: '002',
        business_property: 'R',
        long_desc: 'NGONINI',
      },
      {
        id: 10077,
        inkhundla_code: '002',
        business_property: 'R',
        long_desc: 'NYONYANE',
      },
      {
        id: 10078,
        inkhundla_code: '002',
        business_property: 'R',
        long_desc: 'EMALAYININI',
      },
      {
        id: 10079,
        inkhundla_code: '002',
        business_property: 'R',
        long_desc: 'LOBAMBA',
      },
      {
        id: 10080,
        inkhundla_code: '004',
        business_property: 'R',
        long_desc: 'LUBUYANE',
      },
      {
        id: 10081,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'PHUZWENI',
      },
      {
        id: 10082,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: 'NGINAMADOLO',
      },
      {
        id: 10083,
        inkhundla_code: '002',
        business_property: 'R',
        long_desc: 'SATELITE',
      },
      {
        id: 10084,
        inkhundla_code: '014',
        business_property: 'R',
        long_desc: 'NKHUBE',
      },
      {
        id: 10085,
        inkhundla_code: '002',
        business_property: 'R',
        long_desc: 'MCAPHOZINI',
      },
      {
        id: 10086,
        inkhundla_code: '011',
        business_property: 'R',
        long_desc: 'JUBUKWENI',
      },
      {
        id: 10087,
        inkhundla_code: '010',
        business_property: 'R',
        long_desc: 'MVUMA',
      },
      {
        id: 10088,
        inkhundla_code: '012',
        business_property: 'R',
        long_desc: 'MSUMPE',
      },
      {
        id: 10089,
        inkhundla_code: '004',
        business_property: 'R',
        long_desc: 'MBULUZI',
      },
      {
        id: 10090,
        inkhundla_code: '011',
        business_property: 'R',
        long_desc: 'JUBUKWENI',
      },
      {
        id: 10091,
        inkhundla_code: '009',
        business_property: 'R',
        long_desc: 'MOTSHANE',
      },
      {
        id: 10092,
        inkhundla_code: '009',
        business_property: 'R',
        long_desc: 'KAMAFUTHA',
      },
      {
        id: 10093,
        inkhundla_code: '004',
        business_property: 'R',
        long_desc: 'MTJODVWENI',
      },
      {
        id: 10094,
        inkhundla_code: '005',
        business_property: 'R',
        long_desc: 'HEREFORDS',
      },
      {
        id: 10095,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'MKHWENI',
      },
      {
        id: 10096,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'BHUDLWENI',
      },
      {
        id: 10097,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'SIBAYA',
      },
      {
        id: 10098,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'DAMBANE',
      },
      {
        id: 10099,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'ZONDANANI',
      },
      {
        id: 10100,
        inkhundla_code: '014',
        business_property: 'R',
        long_desc: 'KANYAMA',
      },
      {
        id: 10101,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'COKISENI',
      },
      {
        id: 10102,
        inkhundla_code: '005',
        business_property: 'R',
        long_desc: 'KUTHULENI',
      },
      {
        id: 10103,
        inkhundla_code: '008',
        business_property: 'R',
        long_desc: 'MANGWENI',
      },
      {
        id: 10104,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'KUPHAKAMENI',
      },
      {
        id: 10105,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'SIDWASHINI MBABANE',
      },
      {
        id: 10106,
        inkhundla_code: '014',
        business_property: 'R',
        long_desc: 'KUSITI',
      },
      {
        id: 10107,
        inkhundla_code: '008',
        business_property: 'R',
        long_desc: 'MANGWENI',
      },
      {
        id: 10108,
        inkhundla_code: '008',
        business_property: 'R',
        long_desc: 'MPOFU',
      },
      {
        id: 10109,
        inkhundla_code: '005',
        business_property: 'R',
        long_desc: 'MALAYININI',
      },
      {
        id: 10110,
        inkhundla_code: '014',
        business_property: 'R',
        long_desc: 'SIPHOCOSINI',
      },
      {
        id: 10111,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MAHHONGA',
      },
      {
        id: 10112,
        inkhundla_code: '011',
        business_property: 'R',
        long_desc: 'KUFIKENI',
      },
      {
        id: 10113,
        inkhundla_code: '015',
        business_property: 'R',
        long_desc: 'TIMPHISINI',
      },
      {
        id: 10114,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: 'NSANGWINI',
      },
      {
        id: 10115,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'NKONYANENI',
      },
      {
        id: 10116,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'MZACENI',
      },
      {
        id: 10117,
        inkhundla_code: '002',
        business_property: 'R',
        long_desc: 'LANGENI',
      },
      {
        id: 10118,
        inkhundla_code: '007',
        business_property: 'R',
        long_desc: 'MANYISA',
      },
      {
        id: 10119,
        inkhundla_code: '012',
        business_property: 'R',
        long_desc: 'ZIBONELE',
      },
      {
        id: 10120,
        inkhundla_code: '014',
        business_property: 'R',
        long_desc: 'MANTABENI',
      },
      {
        id: 10121,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MAGENGENI',
      },
      {
        id: 10122,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'MATFUNTINI',
      },
      {
        id: 10123,
        inkhundla_code: '006',
        business_property: 'R',
        long_desc: 'MDZANGWINI',
      },
      {
        id: 10124,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MAMPONDWENI',
      },
      {
        id: 10125,
        inkhundla_code: '012',
        business_property: 'R',
        long_desc: 'MSAHWENI',
      },
      {
        id: 10126,
        inkhundla_code: '011',
        business_property: 'R',
        long_desc: 'MALANTI',
      },
      {
        id: 10127,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MKHUNJINI',
      },
      {
        id: 10128,
        inkhundla_code: '012',
        business_property: 'R',
        long_desc: 'LOMSHIYO',
      },
      {
        id: 10129,
        inkhundla_code: '011',
        business_property: 'R',
        long_desc: 'MAJOTINI',
      },
      {
        id: 10130,
        inkhundla_code: '009',
        business_property: 'R',
        long_desc: 'KUPHELENI',
      },
      {
        id: 10131,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'ZIZWENI',
      },
      {
        id: 10132,
        inkhundla_code: '001',
        business_property: 'R',
        long_desc: 'TIYENI',
      },
      {
        id: 10133,
        inkhundla_code: '010',
        business_property: 'R',
        long_desc: 'NTANDWENI',
      },
      {
        id: 10134,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'KESS',
      },
      {
        id: 10135,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: 'NDEVA',
      },
      {
        id: 10136,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: 'LUHLANGOTSINI',
      },
      {
        id: 10137,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: 'LUHHUMANENI',
      },
      {
        id: 10138,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'DRYHOEK',
      },
      {
        id: 10139,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'SIGCINENI',
      },
      {
        id: 10140,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MSILINGENI',
      },
      {
        id: 10141,
        inkhundla_code: '007',
        business_property: 'R',
        long_desc: 'MANZANA',
      },
      {
        id: 10142,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'ZANDONDO',
      },
      {
        id: 10143,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'GUCUKA',
      },
      {
        id: 10144,
        inkhundla_code: '012',
        business_property: 'R',
        long_desc: 'MVEMBILI',
      },
      {
        id: 10145,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MSHINDANE',
      },
      {
        id: 10146,
        inkhundla_code: '007',
        business_property: 'R',
        long_desc: 'MNYAMATSINI',
      },
      {
        id: 10147,
        inkhundla_code: '009',
        business_property: 'R',
        long_desc: 'MAHANGENI',
      },
      {
        id: 10148,
        inkhundla_code: '014',
        business_property: 'R',
        long_desc: 'SIGANGENI',
      },
      {
        id: 10149,
        inkhundla_code: '024',
        business_property: 'R',
        long_desc: 'MOYENI',
      },
      {
        id: 10150,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MABHOKO',
      },
      {
        id: 10151,
        inkhundla_code: '032',
        business_property: 'R',
        long_desc: 'NCABANENI',
      },
      {
        id: 10152,
        inkhundla_code: '005',
        business_property: 'R',
        long_desc: 'LUDZIBINI',
      },
      {
        id: 10153,
        inkhundla_code: '041',
        business_property: 'R',
        long_desc: 'MANANGA',
      },
      {
        id: 10154,
        inkhundla_code: '010',
        business_property: 'R',
        long_desc: 'MANANGA',
      },
      {
        id: 10155,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'LUHLEKWENI',
      },
      {
        id: 10156,
        inkhundla_code: '012',
        business_property: 'R',
        long_desc: 'TINGADZENI',
      },
      {
        id: 10157,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MBONDVWENI',
      },
      {
        id: 10158,
        inkhundla_code: '015',
        business_property: 'R',
        long_desc: 'MASHOBENI',
      },
      {
        id: 10159,
        inkhundla_code: '014',
        business_property: 'R',
        long_desc: 'MAKHWANE',
      },
      {
        id: 10160,
        inkhundla_code: '006',
        business_property: 'R',
        long_desc: 'GOBHOLO',
      },
      {
        id: 10161,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'KUDVWALENI',
      },
      {
        id: 10162,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'EDLIZENI',
      },
      {
        id: 10163,
        inkhundla_code: '010',
        business_property: 'R',
        long_desc: 'GUNGUNDLOVU',
      },
      {
        id: 10164,
        inkhundla_code: '010',
        business_property: 'R',
        long_desc: 'LUDLAWINI',
      },
      {
        id: 10165,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'MBABANE',
      },
      {
        id: 10166,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'SWAZI PLAZA',
      },
      {
        id: 10167,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'MPHALWINI MALL',
      },
      {
        id: 10168,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'NEW MALL',
      },
      {
        id: 10169,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'MBABANE INDUSTRIAL SITE',
      },
      {
        id: 10170,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'MSUNDUZA',
      },
      {
        id: 10171,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'COPERATION',
      },
      {
        id: 10172,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'FONTEYN',
      },
      {
        id: 10173,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'VENI',
      },
      {
        id: 10174,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'SIDVWASHINI LOCATION',
      },
      {
        id: 10175,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'SIDVWASHINI INDUSTRIAL SITE',
      },
      {
        id: 10176,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'QOBONGA',
      },
      {
        id: 10177,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'MANGWANENI',
      },
      {
        id: 10178,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'HILLTOP',
      },
      {
        id: 10179,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'MVAKWELITJE',
      },
      {
        id: 10180,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'NKOYOYO',
      },
      {
        id: 10181,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'MAKHOLOKHOLO',
      },
      {
        id: 10182,
        inkhundla_code: '009',
        business_property: 'R',
        long_desc: 'MPOLONJENI',
      },
      {
        id: 10183,
        inkhundla_code: '009',
        business_property: 'U',
        long_desc: 'NGWENYA VILLAGE',
      },
      {
        id: 10184,
        inkhundla_code: '009',
        business_property: 'U',
        long_desc: 'NGWENYA INDUSTRIAL SITE',
      },
      {
        id: 10185,
        inkhundla_code: '009',
        business_property: 'U',
        long_desc: 'NGWENYA DROXORD FARM',
      },
      {
        id: 10187,
        inkhundla_code: '009',
        business_property: 'U',
        long_desc: 'NGWENYA BORDER',
      },
      {
        id: 10188,
        inkhundla_code: '009',
        business_property: 'U',
        long_desc: 'NKHUNGU',
      },
      {
        id: 10189,
        inkhundla_code: '013',
        business_property: '',
        long_desc: 'PIGGS PEAK',
      },
      {
        id: 10190,
        inkhundla_code: '013',
        business_property: '',
        long_desc: 'MALANDALAHLE',
      },
      {
        id: 10192,
        inkhundla_code: '013',
        business_property: '',
        long_desc: 'BULEMBU',
      },
      {
        id: 10193,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'NKWALINI',
      },
      {
        id: 10194,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'BAYABONGA COMPLEX',
      },
      {
        id: 10195,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'DALRIACH',
      },
      {
        id: 10196,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'MALUNGE',
      },
      {
        id: 10197,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'THEMBELIHLE',
      },
      {
        id: 10198,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'COOPERCENTRE',
      },
      {
        id: 10199,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'SEDCO SIDVWASHINI',
      },
      {
        id: 10200,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'SEDCO MBABANE',
      },
      {
        id: 10201,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'LANSDOWN BLDG',
      },
      {
        id: 10202,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'CHECKERS',
      },
      {
        id: 10203,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'SANDLA',
      },
      {
        id: 10204,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'DLAN`BEKA BLDG',
      },
      {
        id: 10205,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'MACHOBOLWANE',
      },
      {
        id: 10206,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'MANZANA MBA',
      },
      {
        id: 10207,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'EMBASSY HOUSE',
      },
      {
        id: 10208,
        inkhundla_code: '002',
        business_property: 'P',
        long_desc: 'ZULWINI',
      },
      {
        id: 10209,
        inkhundla_code: '002',
        business_property: 'P',
        long_desc: 'MANTEGA',
      },
      {
        id: 10210,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'SHISELWENI HOUSE',
      },
      {
        id: 10211,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'APEXHOUSE',
      },
      {
        id: 10212,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'CAMPUS CRUSADE',
      },
      {
        id: 10213,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'EMPIRE BLDG',
      },
      {
        id: 10214,
        inkhundla_code: '002',
        business_property: 'U',
        long_desc: 'THE GABLES',
      },
      {
        id: 10215,
        inkhundla_code: '026',
        business_property: 'P',
        long_desc: 'MHLAMBANYATSI',
      },
      {
        id: 10216,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'EAGLE HOUSE',
      },
      {
        id: 10217,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'FIAT BUILDING',
      },
      {
        id: 10218,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'SIGWACA HOUSE',
      },
      {
        id: 10219,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'MBABANE MARKET',
      },
      {
        id: 10220,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'ASAKHE BUILDING',
      },
      {
        id: 10221,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'MBABANE HOUSE',
      },
      {
        id: 10222,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'MSIZI HOUSE',
      },
      {
        id: 10223,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'FEARTHERSTONE',
      },
      {
        id: 10224,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: "RICHARD'S HOUSE",
      },
      {
        id: 10225,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'MULTICHOICE BLDG',
      },
      {
        id: 10226,
        inkhundla_code: '007',
        business_property: 'P',
        long_desc: 'EMAFINI  BUSSINES CENTRE',
      },
      {
        id: 10227,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'JENDENBLDG',
      },
      {
        id: 10228,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'PRINT PARK BLDG',
      },
      {
        id: 10229,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'PRKLANE BLDG',
      },
      {
        id: 10230,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'HARDROCK INVESTMENTS',
      },
      {
        id: 10231,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'THE NEW MALL',
      },
      {
        id: 10232,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'PINE VALLEY',
      },
      {
        id: 10233,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'SOKHAMLILO BLDG',
      },
      {
        id: 10234,
        inkhundla_code: '000',
        business_property: 'T',
        long_desc: 'SWAZILAND',
      },
      {
        id: 10235,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'MBANDZENI HOUSE',
      },
      {
        id: 10236,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'KARLYN CENTRE',
      },
      {
        id: 10237,
        inkhundla_code: '009',
        business_property: 'P',
        long_desc: 'NGWENYA MINE',
      },
      {
        id: 10238,
        inkhundla_code: '000',
        business_property: 'U',
        long_desc: 'PACIFIC BUILDING',
      },
      {
        id: 10239,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'BAHAI',
      },
      {
        id: 10240,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'L M BUILDING',
      },
      {
        id: 10241,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'IMPALA BUILDING',
      },
      {
        id: 10242,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'PENROSE HSE',
      },
      {
        id: 10243,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'COMMERCIAL CENTRE',
      },
      {
        id: 10244,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'KIOSK BUILDING',
      },
      {
        id: 10245,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'MNTSHAWE MSUNDUZA',
      },
      {
        id: 10246,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'MNTSHAWE EXT 3',
      },
      {
        id: 10247,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'PENSION FUND BUILDING',
      },
      {
        id: 10248,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'STOXCO BUILDING',
      },
      {
        id: 10249,
        inkhundla_code: '006',
        business_property: 'P',
        long_desc: 'MALAGWANE HILL',
      },
      {
        id: 10250,
        inkhundla_code: '002',
        business_property: 'P',
        long_desc: 'MANTENGA',
      },
      {
        id: 10251,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'ARCADE BUILDING',
      },
      {
        id: 10252,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'SELECTION PARK',
      },
      {
        id: 10253,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'MILLERS_MENSION BUILDING',
      },
      {
        id: 10254,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'AFRICAN CITY BUILDING',
      },
      {
        id: 10255,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'PARK VIEW HEIGHTS_BLDG',
      },
      {
        id: 10256,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'MOUNTAIN VIEW',
      },
      {
        id: 10257,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'UMFULA CENTRE',
      },
      {
        id: 10258,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'INYATSI COMPLEX.TEA ROAD...',
      },
      {
        id: 10259,
        inkhundla_code: '000',
        business_property: 'T',
        long_desc: 'HHOHHO REGION',
      },
      {
        id: 10260,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'ST.MARKS AREA (MBABANE)',
      },
      {
        id: 10261,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'CITY INN (MBABANE)',
      },
      {
        id: 10262,
        inkhundla_code: '002',
        business_property: 'P',
        long_desc: "ZULWINI (ZEEMAN'S)",
      },
      {
        id: 10263,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'OMNI CENTRE (MBABANE)',
      },
      {
        id: 10264,
        inkhundla_code: '014',
        business_property: 'R',
        long_desc: 'ELUHLENDLWENI',
      },
      {
        id: 10265,
        inkhundla_code: '002',
        business_property: 'U',
        long_desc: 'ZULWINI URBAN',
      },
      {
        id: 10266,
        inkhundla_code: '001',
        business_property: 'R',
        long_desc: 'LAMGABHI ETIYENI',
      },
      {
        id: 10267,
        inkhundla_code: '002',
        business_property: 'U',
        long_desc: 'MLALATINI',
      },
      {
        id: 10268,
        inkhundla_code: '009',
        business_property: 'R',
        long_desc: 'MOTSHANE',
      },
      {
        id: 10269,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'MANGWANENI (MBABANE)',
      },
      {
        id: 10270,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'THOKOZA CHURCH CENTRE',
      },
      {
        id: 10271,
        inkhundla_code: '009',
        business_property: 'P',
        long_desc: 'DROXFORD FARM (NGWENYA)',
      },
      {
        id: 10272,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'NEDBANK CENTRE (MBABANE)',
      },
      {
        id: 10273,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'INDEPEDENCE CENTRE (MBABANE)',
      },
      {
        id: 10274,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'MONA BUILDING (MBABANE)',
      },
      {
        id: 10275,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'THE OLD MALL (MBABANE)',
      },
      {
        id: 10276,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'MAHWALALA',
      },
      {
        id: 10277,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'BEVERLY HILLS (MBABANE)',
      },
      {
        id: 10278,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'FOURWAYS BUILDING',
      },
      {
        id: 10279,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'TICKBRO BUILDING',
      },
      {
        id: 10280,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'EDMA HOUSE',
      },
      {
        id: 10281,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'LILUNGA HOUSE',
      },
      {
        id: 10300,
        inkhundla_code: '011',
        business_property: 'R',
        long_desc: 'EKUVINJELWENI',
      },
      {
        id: 10745,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'FOURSQUARE BUILDING',
      },
      {
        id: 10746,
        inkhundla_code: '014',
        business_property: 'P',
        long_desc: 'LUPHOHLO FARM',
      },
      {
        id: 10747,
        inkhundla_code: '006',
        business_property: 'R',
        long_desc: 'NKOYOYO',
      },
      {
        id: 10748,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'THE MALL (OLD)',
      },
      {
        id: 10749,
        inkhundla_code: '004',
        business_property: 'R',
        long_desc: 'MAPHALALENI',
      },
      {
        id: 10755,
        inkhundla_code: '006',
        business_property: 'P',
        long_desc: 'NKOYOYO',
      },
      {
        id: 10756,
        inkhundla_code: '014',
        business_property: 'U',
        long_desc: 'TONKWANE',
      },
      {
        id: 10757,
        inkhundla_code: '002',
        business_property: 'R',
        long_desc: 'GELEKECENI, ZULWINI',
      },
      {
        id: 10758,
        inkhundla_code: '002',
        business_property: 'R',
        long_desc: 'MVANA EZULWINI',
      },
      {
        id: 10759,
        inkhundla_code: '002',
        business_property: 'R',
        long_desc: 'ZULWINI RURAL',
      },
      {
        id: 10760,
        inkhundla_code: '024',
        business_property: 'R',
        long_desc: 'MPULUZI',
      },
      {
        id: 10761,
        inkhundla_code: '006',
        business_property: 'P',
        long_desc: 'EVENI',
      },
      {
        id: 10762,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'MBABANE OLD MOBENI FLATES',
      },
      {
        id: 10763,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'MBABANE SINGAPORE BUILDING',
      },
      {
        id: 10764,
        inkhundla_code: '014',
        business_property: 'P',
        long_desc: 'SIPHOCOSINI FARM',
      },
      {
        id: 10765,
        inkhundla_code: '002',
        business_property: 'R',
        long_desc: 'LOBAMBA EMSAMO',
      },
      {
        id: 10766,
        inkhundla_code: '002',
        business_property: 'R',
        long_desc: 'MDZIMBA',
      },
      {
        id: 10767,
        inkhundla_code: '001',
        business_property: 'R',
        long_desc: 'SITSENI(ENCOKISENI)',
      },
      {
        id: 10768,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'MBANGWENI MBABANE',
      },
      {
        id: 10769,
        inkhundla_code: '004',
        business_property: 'R',
        long_desc: 'NKALANGENI',
      },
      {
        id: 10770,
        inkhundla_code: '004',
        business_property: 'P',
        long_desc: 'MBULUZI',
      },
      {
        id: 10771,
        inkhundla_code: '011',
        business_property: 'P',
        long_desc: 'FORBES REEF',
      },
      {
        id: 10772,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'LM BUILDING MBABANE',
      },
      {
        id: 10773,
        inkhundla_code: '009',
        business_property: 'R',
        long_desc: 'EKUPHELENI',
      },
      {
        id: 10774,
        inkhundla_code: '009',
        business_property: 'R',
        long_desc: 'NGWENYA',
      },
      {
        id: 10775,
        inkhundla_code: '002',
        business_property: 'P',
        long_desc: 'GOJE TOWNSHIP',
      },
      {
        id: 10776,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'KENTROCK',
      },
      {
        id: 10777,
        inkhundla_code: '008',
        business_property: 'R',
        long_desc: 'NDVWABANGENI',
      },
      {
        id: 10778,
        inkhundla_code: '011',
        business_property: 'P',
        long_desc: 'HAWANE PARK',
      },
      {
        id: 10779,
        inkhundla_code: '007',
        business_property: 'P',
        long_desc: 'DALRIACH FARM 188',
      },
      {
        id: 10780,
        inkhundla_code: '009',
        business_property: 'P',
        long_desc: 'MOTSHANE FARM',
      },
      {
        id: 10781,
        inkhundla_code: '013',
        business_property: 'P',
        long_desc: 'PEAK TIMBERS',
      },
      {
        id: 10782,
        inkhundla_code: '002',
        business_property: 'P',
        long_desc: 'MLILWANE GAME SANCTUARY',
      },
      {
        id: 10783,
        inkhundla_code: '011',
        business_property: 'P',
        long_desc: 'MNYOKANE',
      },
      {
        id: 10784,
        inkhundla_code: '002',
        business_property: 'P',
        long_desc: 'LOBAMBA FARM',
      },
      {
        id: 10785,
        inkhundla_code: '007',
        business_property: 'P',
        long_desc: 'MPOLONJENI FARM',
      },
      {
        id: 10786,
        inkhundla_code: '007',
        business_property: 'P',
        long_desc: 'MNYAMATSINI  FARM',
      },
      {
        id: 10787,
        inkhundla_code: '006',
        business_property: 'R',
        long_desc: 'HHOLOSHINI',
      },
      {
        id: 10788,
        inkhundla_code: '011',
        business_property: 'P',
        long_desc: 'HAWANE',
      },
      {
        id: 10789,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'MBABANE MAPHIKO COMPLEX',
      },
      {
        id: 10790,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'MVAKWELITSHE',
      },
      {
        id: 10791,
        inkhundla_code: '011',
        business_property: 'R',
        long_desc: 'MALOLOTJA',
      },
      {
        id: 10792,
        inkhundla_code: '009',
        business_property: 'P',
        long_desc: 'NGWENYA DROXFORD FARM',
      },
      {
        id: 10793,
        inkhundla_code: '011',
        business_property: 'P',
        long_desc: 'KANCESI FARM',
      },
      {
        id: 10794,
        inkhundla_code: '013',
        business_property: 'P',
        long_desc: 'MAVULA FARM',
      },
      {
        id: 10795,
        inkhundla_code: '007',
        business_property: 'U',
        long_desc: 'MALAGWANE',
      },
      {
        id: 10796,
        inkhundla_code: '026',
        business_property: 'U',
        long_desc: 'MHLAMBANYATSI',
      },
      {
        id: 10797,
        inkhundla_code: '026',
        business_property: 'R',
        long_desc: 'MHLAMBANYATSI',
      },
      {
        id: 10798,
        inkhundla_code: '011',
        business_property: 'R',
        long_desc: 'KANCESI',
      },
      {
        id: 10799,
        inkhundla_code: '011',
        business_property: 'R',
        long_desc: 'MAHEBEDLA',
      },
      {
        id: 10800,
        inkhundla_code: '006',
        business_property: 'R',
        long_desc: 'MNCITSINI',
      },
      {
        id: 10801,
        inkhundla_code: '010',
        business_property: 'U',
        long_desc: 'BUHLENI',
      },
      {
        id: 10802,
        inkhundla_code: '014',
        business_property: 'R',
        long_desc: 'LUHLENDLWENI',
      },
      {
        id: 10803,
        inkhundla_code: '004',
        business_property: 'R',
        long_desc: 'NSINGWENI',
      },
      {
        id: 10804,
        inkhundla_code: '001',
        business_property: 'R',
        long_desc: 'KASIKO',
      },
      {
        id: 10805,
        inkhundla_code: '006',
        business_property: 'R',
        long_desc: 'MANTJOLO',
      },
      {
        id: 10806,
        inkhundla_code: '002',
        business_property: 'R',
        long_desc: 'SITJENI',
      },
      {
        id: 10807,
        inkhundla_code: '006',
        business_property: 'R',
        long_desc: 'PINE VALLEY',
      },
      {
        id: 10808,
        inkhundla_code: '014',
        business_property: 'P',
        long_desc: 'MAKHWANE',
      },
      {
        id: 10810,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'GUCUKA MADLANGEMPISI',
      },
      {
        id: 10811,
        inkhundla_code: '002',
        business_property: 'R',
        long_desc: 'SOMNJALOSE',
      },
      {
        id: 10812,
        inkhundla_code: '011',
        business_property: 'P',
        long_desc: 'LUVINJELWENI',
      },
      {
        id: 20000,
        inkhundla_code: '000',
        business_property: 'T',
        long_desc: 'MANZINI REGION',
      },
      {
        id: 20001,
        inkhundla_code: '024',
        business_property: 'U',
        long_desc: 'MANZINI TOWN CENTRE',
      },
      {
        id: 20002,
        inkhundla_code: '024',
        business_property: 'U',
        long_desc: 'ZAKHELE',
      },
      {
        id: 20003,
        inkhundla_code: '024',
        business_property: 'U',
        long_desc: 'TWO STICKS',
      },
      {
        id: 20004,
        inkhundla_code: '024',
        business_property: 'U',
        long_desc: 'NGWANE PARK',
      },
      {
        id: 20005,
        inkhundla_code: '025',
        business_property: 'U',
        long_desc: 'FAIRVIEW',
      },
      {
        id: 20006,
        inkhundla_code: '020',
        business_property: 'R',
        long_desc: 'LOZITHA',
      },
      {
        id: 20007,
        inkhundla_code: '020',
        business_property: 'R',
        long_desc: 'MFABANTFU',
      },
      {
        id: 20008,
        inkhundla_code: '020',
        business_property: 'R',
        long_desc: 'LUDZELUDZE',
      },
      {
        id: 20009,
        inkhundla_code: '017',
        business_property: 'R',
        long_desc: 'KWALUSENI',
      },
      {
        id: 20010,
        inkhundla_code: '020',
        business_property: 'R',
        long_desc: 'MBEKELWENI',
      },
      {
        id: 20011,
        inkhundla_code: '020',
        business_property: 'R',
        long_desc: 'NKAMANZI',
      },
      {
        id: 20012,
        inkhundla_code: '020',
        business_property: 'R',
        long_desc: 'ZOMBODZE',
      },
      {
        id: 20013,
        inkhundla_code: '020',
        business_property: 'R',
        long_desc: 'EKUDZENI',
      },
      {
        id: 20014,
        inkhundla_code: '031',
        business_property: 'R',
        long_desc: 'NSENGA',
      },
      {
        id: 20015,
        inkhundla_code: '031',
        business_property: 'R',
        long_desc: 'NTUNJA',
      },
      {
        id: 20016,
        inkhundla_code: '016',
        business_property: 'R',
        long_desc: 'BHEKINKOSI',
      },
      {
        id: 20017,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'LUVE',
      },
      {
        id: 20018,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'ZANDONDO',
      },
      {
        id: 20019,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'EKUTSIMLENI',
      },
      {
        id: 20020,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'DVOKOLWAKO',
      },
      {
        id: 20021,
        inkhundla_code: '021',
        business_property: 'R',
        long_desc: 'TIMBUTINI',
      },
      {
        id: 20022,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'CROYDON',
      },
      {
        id: 20023,
        inkhundla_code: '021',
        business_property: 'P',
        long_desc: 'MAFUTSENI',
      },
      {
        id: 20024,
        inkhundla_code: '021',
        business_property: 'R',
        long_desc: 'BHUDLA',
      },
      {
        id: 20025,
        inkhundla_code: '028',
        business_property: 'R',
        long_desc: 'GUNDVWINI',
      },
      {
        id: 20026,
        inkhundla_code: '021',
        business_property: 'P',
        long_desc: 'MZIMPOFU',
      },
      {
        id: 20027,
        inkhundla_code: '021',
        business_property: 'P',
        long_desc: 'HHELEHHELE',
      },
      {
        id: 20028,
        inkhundla_code: '021',
        business_property: 'R',
        long_desc: 'ENGCULWINI',
      },
      {
        id: 20029,
        inkhundla_code: '021',
        business_property: 'R',
        long_desc: 'NTABAMHLOSHANA',
      },
      {
        id: 20030,
        inkhundla_code: '016',
        business_property: 'R',
        long_desc: 'MKHULAMINI',
      },
      {
        id: 20031,
        inkhundla_code: '016',
        business_property: 'R',
        long_desc: 'NYAKENI',
      },
      {
        id: 20032,
        inkhundla_code: '017',
        business_property: 'P',
        long_desc: 'TUBUNGU FARMS',
      },
      {
        id: 20033,
        inkhundla_code: '018',
        business_property: 'R',
        long_desc: 'LUHLEKO',
      },
      {
        id: 20034,
        inkhundla_code: '018',
        business_property: 'R',
        long_desc: 'LAMGABHI',
      },
      {
        id: 20035,
        inkhundla_code: '018',
        business_property: 'R',
        long_desc: 'MHLABUBOVU',
      },
      {
        id: 20036,
        inkhundla_code: '019',
        business_property: 'R',
        long_desc: 'LUYENGO',
      },
      {
        id: 20037,
        inkhundla_code: '019',
        business_property: 'R',
        long_desc: 'ENGWENYAMENI',
      },
      {
        id: 20038,
        inkhundla_code: '019',
        business_property: 'R',
        long_desc: 'MDUTJANE',
      },
      {
        id: 20039,
        inkhundla_code: '019',
        business_property: 'R',
        long_desc: 'MDONJANE',
      },
      {
        id: 20040,
        inkhundla_code: '019',
        business_property: 'P',
        long_desc: 'MALKERNS',
      },
      {
        id: 20046,
        inkhundla_code: '019',
        business_property: 'R',
        long_desc: 'MAHLANYA',
      },
      {
        id: 20047,
        inkhundla_code: '017',
        business_property: 'U',
        long_desc: 'MATSAPHA',
      },
      {
        id: 20048,
        inkhundla_code: '017',
        business_property: 'R',
        long_desc: 'KWALUSENI',
      },
      {
        id: 20049,
        inkhundla_code: '017',
        business_property: 'R',
        long_desc: 'LOGOBA',
      },
      {
        id: 20050,
        inkhundla_code: '017',
        business_property: 'R',
        long_desc: 'ESIGODVWENI',
      },
      {
        id: 20051,
        inkhundla_code: '017',
        business_property: 'R',
        long_desc: 'MHLANE',
      },
      {
        id: 20052,
        inkhundla_code: '017',
        business_property: 'R',
        long_desc: 'MBIKWAKHE',
      },
      {
        id: 20053,
        inkhundla_code: '017',
        business_property: 'R',
        long_desc: 'MHLALENI',
      },
      {
        id: 20054,
        inkhundla_code: '025',
        business_property: 'U',
        long_desc: 'NEW VILLAGE',
      },
      {
        id: 20055,
        inkhundla_code: '025',
        business_property: 'U',
        long_desc: 'MHOBODLENI',
      },
      {
        id: 20056,
        inkhundla_code: '016',
        business_property: 'R',
        long_desc: 'MPEMBEKATI',
      },
      {
        id: 20057,
        inkhundla_code: '024',
        business_property: 'R',
        long_desc: 'MAKHOLWENI',
      },
      {
        id: 20058,
        inkhundla_code: '024',
        business_property: 'R',
        long_desc: 'MZIMNENE',
      },
      {
        id: 20059,
        inkhundla_code: '024',
        business_property: 'U',
        long_desc: 'GRAND VALLEY',
      },
      {
        id: 20060,
        inkhundla_code: '024',
        business_property: 'R',
        long_desc: 'KAKHOZA',
      },
      {
        id: 20061,
        inkhundla_code: '024',
        business_property: 'R',
        long_desc: 'MADODA',
      },
      {
        id: 20062,
        inkhundla_code: '024',
        business_property: 'R',
        long_desc: 'MADONSA',
      },
      {
        id: 20063,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'KAKHUPHUKA',
      },
      {
        id: 20064,
        inkhundla_code: '021',
        business_property: 'R',
        long_desc: 'NGOGOLA',
      },
      {
        id: 20065,
        inkhundla_code: '019',
        business_property: 'P',
        long_desc: 'BETHANY',
      },
      {
        id: 20066,
        inkhundla_code: '030',
        business_property: 'R',
        long_desc: 'DWALENI',
      },
      {
        id: 20067,
        inkhundla_code: '016',
        business_property: 'R',
        long_desc: 'ENGWAZINI',
      },
      {
        id: 20068,
        inkhundla_code: '025',
        business_property: 'U',
        long_desc: 'MONENI',
      },
      {
        id: 20069,
        inkhundla_code: '025',
        business_property: 'U',
        long_desc: 'COATES VALLEY',
      },
      {
        id: 20070,
        inkhundla_code: '032',
        business_property: 'R',
        long_desc: 'ENGONINI',
      },
      {
        id: 20071,
        inkhundla_code: '028',
        business_property: 'R',
        long_desc: 'LWANDLE',
      },
      {
        id: 20072,
        inkhundla_code: '030',
        business_property: 'R',
        long_desc: 'ENHLAMBENI',
      },
      {
        id: 20073,
        inkhundla_code: '030',
        business_property: 'P',
        long_desc: 'KASHALI',
      },
      {
        id: 20074,
        inkhundla_code: '030',
        business_property: 'P',
        long_desc: 'SIDVOKODVO',
      },
      {
        id: 20075,
        inkhundla_code: '022',
        business_property: 'P',
        long_desc: 'SIGCINENI',
      },
      {
        id: 20076,
        inkhundla_code: '030',
        business_property: 'R',
        long_desc: 'MASUNDVWINI',
      },
      {
        id: 20077,
        inkhundla_code: '028',
        business_property: 'R',
        long_desc: 'BULUNGA',
      },
      {
        id: 20078,
        inkhundla_code: '021',
        business_property: 'R',
        long_desc: 'KHAMATHO-MAFUTSENI',
      },
      {
        id: 20079,
        inkhundla_code: '026',
        business_property: 'R',
        long_desc: 'MPULUZI',
      },
      {
        id: 20080,
        inkhundla_code: '016',
        business_property: 'R',
        long_desc: 'NKILIJI',
      },
      {
        id: 20081,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'MLIBA',
      },
      {
        id: 20082,
        inkhundla_code: '024',
        business_property: 'R',
        long_desc: 'MALIYADUMA',
      },
      {
        id: 20083,
        inkhundla_code: '030',
        business_property: 'P',
        long_desc: 'PHOCWENI',
      },
      {
        id: 20084,
        inkhundla_code: '031',
        business_property: 'R',
        long_desc: 'VUSWENI',
      },
      {
        id: 20085,
        inkhundla_code: '021',
        business_property: 'R',
        long_desc: 'LUHLOKOHLA',
      },
      {
        id: 20086,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'KATSAKASILE',
      },
      {
        id: 20087,
        inkhundla_code: '031',
        business_property: 'R',
        long_desc: 'SIGOMBENI',
      },
      {
        id: 20088,
        inkhundla_code: '024',
        business_property: 'R',
        long_desc: 'SICELWINI',
      },
      {
        id: 20089,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'SITFWETFWENI',
      },
      {
        id: 20090,
        inkhundla_code: '017',
        business_property: 'R',
        long_desc: 'ESIBAYENI',
      },
      {
        id: 20091,
        inkhundla_code: '028',
        business_property: 'R',
        long_desc: 'MKHUZWENI',
      },
      {
        id: 20092,
        inkhundla_code: '016',
        business_property: 'R',
        long_desc: 'MLONYENI',
      },
      {
        id: 20093,
        inkhundla_code: '030',
        business_property: 'R',
        long_desc: 'ESIKHALENI SELISEKWANE',
      },
      {
        id: 20094,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'NDODENI',
      },
      {
        id: 20095,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'MBULUZANA',
      },
      {
        id: 20096,
        inkhundla_code: '021',
        business_property: 'R',
        long_desc: 'ELUNDZINDZANENI',
      },
      {
        id: 20097,
        inkhundla_code: '026',
        business_property: 'R',
        long_desc: 'LUNDZI',
      },
      {
        id: 20098,
        inkhundla_code: '026',
        business_property: 'R',
        long_desc: 'ZONDWAKO',
      },
      {
        id: 20099,
        inkhundla_code: '016',
        business_property: 'R',
        long_desc: 'MAHHONWANE',
      },
      {
        id: 20100,
        inkhundla_code: '016',
        business_property: 'R',
        long_desc: 'EMALAYININI',
      },
      {
        id: 20101,
        inkhundla_code: '016',
        business_property: 'R',
        long_desc: 'ELUGANDVULWENI',
      },
      {
        id: 20102,
        inkhundla_code: '016',
        business_property: 'R',
        long_desc: 'SIBOZINI',
      },
      {
        id: 20103,
        inkhundla_code: '016',
        business_property: 'R',
        long_desc: 'EMSHANELWENI',
      },
      {
        id: 20104,
        inkhundla_code: '016',
        business_property: 'R',
        long_desc: 'MAGIDA',
      },
      {
        id: 20105,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'EMNJOLI',
      },
      {
        id: 20106,
        inkhundla_code: '026',
        business_property: 'R',
        long_desc: 'EMGOTJANE',
      },
      {
        id: 20107,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'TONGONGO',
      },
      {
        id: 20108,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'ENHLANGANO',
      },
      {
        id: 20109,
        inkhundla_code: '031',
        business_property: 'R',
        long_desc: 'ESIBUYENI',
      },
      {
        id: 20110,
        inkhundla_code: '028',
        business_property: 'R',
        long_desc: 'ESIDZAKENI',
      },
      {
        id: 20111,
        inkhundla_code: '028',
        business_property: 'R',
        long_desc: 'SIPHULA',
      },
      {
        id: 20112,
        inkhundla_code: '021',
        business_property: 'R',
        long_desc: 'MAHLABANE',
      },
      {
        id: 20113,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'JABULANI',
      },
      {
        id: 20114,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'EMCOZINI',
      },
      {
        id: 20115,
        inkhundla_code: '028',
        business_property: 'R',
        long_desc: 'ENDLANDLAMENI',
      },
      {
        id: 20116,
        inkhundla_code: '031',
        business_property: 'R',
        long_desc: 'EMPHINI',
      },
      {
        id: 20117,
        inkhundla_code: '017',
        business_property: 'R',
        long_desc: 'NDLUNGANYE',
      },
      {
        id: 20118,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MVUNDLELA',
      },
      {
        id: 20119,
        inkhundla_code: '031',
        business_property: 'R',
        long_desc: 'ENDLINILEMBI',
      },
      {
        id: 20120,
        inkhundla_code: '016',
        business_property: 'R',
        long_desc: 'EMGANWINI',
      },
      {
        id: 20121,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'KHOKHWANENI',
      },
      {
        id: 20122,
        inkhundla_code: '031',
        business_property: 'R',
        long_desc: 'NSINGWENI',
      },
      {
        id: 20123,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'SIBOVU/LUVE',
      },
      {
        id: 20124,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'SHIYAMPHAHLA',
      },
      {
        id: 20125,
        inkhundla_code: '026',
        business_property: 'R',
        long_desc: 'EMBANGWENI',
      },
      {
        id: 20126,
        inkhundla_code: '020',
        business_property: 'R',
        long_desc: 'BOYANE',
      },
      {
        id: 20127,
        inkhundla_code: '023',
        business_property: 'R',
        long_desc: 'SANDLANE',
      },
      {
        id: 20128,
        inkhundla_code: '024',
        business_property: 'R',
        long_desc: 'ETICANTFWINI',
      },
      {
        id: 20129,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'ENDLWANENI',
      },
      {
        id: 20130,
        inkhundla_code: '031',
        business_property: 'R',
        long_desc: 'SIHHOHHWENI',
      },
      {
        id: 20131,
        inkhundla_code: '026',
        business_property: 'R',
        long_desc: 'MLINDAZWE',
      },
      {
        id: 20132,
        inkhundla_code: '030',
        business_property: 'R',
        long_desc: 'NJELU',
      },
      {
        id: 20133,
        inkhundla_code: '032',
        business_property: 'R',
        long_desc: 'EGEBENI',
      },
      {
        id: 20134,
        inkhundla_code: '018',
        business_property: 'R',
        long_desc: 'LOMUDZE',
      },
      {
        id: 20135,
        inkhundla_code: '018',
        business_property: 'R',
        long_desc: 'NYATSI',
      },
      {
        id: 20136,
        inkhundla_code: '018',
        business_property: 'R',
        long_desc: 'MUDLANI',
      },
      {
        id: 20137,
        inkhundla_code: '020',
        business_property: 'R',
        long_desc: 'MTILANE',
      },
      {
        id: 20138,
        inkhundla_code: '018',
        business_property: 'R',
        long_desc: 'MAGODVOTJENI',
      },
      {
        id: 20139,
        inkhundla_code: '018',
        business_property: 'R',
        long_desc: 'MANGCOVOZA',
      },
      {
        id: 20140,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'MAHLABATSINI/LUVE',
      },
      {
        id: 20141,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'EMABOVINI',
      },
      {
        id: 20142,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'EKUPHUMLENI',
      },
      {
        id: 20143,
        inkhundla_code: '023',
        business_property: 'R',
        long_desc: 'MABHUKWINI',
      },
      {
        id: 20144,
        inkhundla_code: '032',
        business_property: 'R',
        long_desc: 'KANDINDA',
      },
      {
        id: 20145,
        inkhundla_code: '017',
        business_property: 'U',
        long_desc: 'MATSAPHA INDUSTRIAL SITES',
      },
      {
        id: 20146,
        inkhundla_code: '024',
        business_property: 'U',
        long_desc: 'FAIRVIEW SOUTH',
      },
      {
        id: 20147,
        inkhundla_code: '017',
        business_property: 'R',
        long_desc: 'MBHULENI/MATSAPA',
      },
      {
        id: 20148,
        inkhundla_code: '024',
        business_property: 'U',
        long_desc: 'FAIRVIEW NORTH',
      },
      {
        id: 20149,
        inkhundla_code: '032',
        business_property: 'R',
        long_desc: 'NTONDOZI',
      },
      {
        id: 20150,
        inkhundla_code: '026',
        business_property: 'U',
        long_desc: 'BHUNYA',
      },
      {
        id: 20151,
        inkhundla_code: '028',
        business_property: 'R',
        long_desc: 'SIDZAKENI',
      },
      {
        id: 20152,
        inkhundla_code: '032',
        business_property: 'R',
        long_desc: 'NGONINI COMMUNITY',
      },
      {
        id: 20153,
        inkhundla_code: '000',
        business_property: 'T',
        long_desc: 'SWAZILAND',
      },
      {
        id: 20154,
        inkhundla_code: '026',
        business_property: 'U',
        long_desc: 'MHLAMBANYATSI',
      },
      {
        id: 20155,
        inkhundla_code: '017',
        business_property: 'U',
        long_desc: 'MAHLABATSINI/NEW VILLAGE',
      },
      {
        id: 20156,
        inkhundla_code: '024',
        business_property: 'U',
        long_desc: 'MANGWANENI/MANZINI',
      },
      {
        id: 20157,
        inkhundla_code: '017',
        business_property: 'R',
        long_desc: 'ETENI/KWALUSENI',
      },
      {
        id: 20158,
        inkhundla_code: '028',
        business_property: 'R',
        long_desc: 'NGULUTJANE',
      },
      {
        id: 20159,
        inkhundla_code: '019',
        business_property: 'P',
        long_desc: 'NOKWANE',
      },
      {
        id: 20160,
        inkhundla_code: '017',
        business_property: 'R',
        long_desc: 'MATHANGENI',
      },
      {
        id: 20161,
        inkhundla_code: '032',
        business_property: 'R',
        long_desc: 'KHALANGILILE',
      },
      {
        id: 20162,
        inkhundla_code: '033',
        business_property: 'R',
        long_desc: 'BHADZENI 11',
      },
      {
        id: 20163,
        inkhundla_code: '023',
        business_property: 'R',
        long_desc: 'MANGCONGCO',
      },
      {
        id: 20164,
        inkhundla_code: '018',
        business_property: 'R',
        long_desc: 'EMHLANGENI',
      },
      {
        id: 20165,
        inkhundla_code: '021',
        business_property: 'R',
        long_desc: 'EJUBELA/NGCULWINI',
      },
      {
        id: 20170,
        inkhundla_code: '019',
        business_property: 'R',
        long_desc: 'LUYENGWENI',
      },
      {
        id: 20171,
        inkhundla_code: '017',
        business_property: 'R',
        long_desc: 'ETETENI',
      },
      {
        id: 20172,
        inkhundla_code: '021',
        business_property: 'R',
        long_desc: 'LUNDZINDZANENI',
      },
      {
        id: 20173,
        inkhundla_code: '021',
        business_property: 'R',
        long_desc: 'ENZOBENI/MAFUTSENI',
      },
      {
        id: 20174,
        inkhundla_code: '031',
        business_property: 'P',
        long_desc: 'VUSWENI',
      },
      {
        id: 20175,
        inkhundla_code: '017',
        business_property: 'R',
        long_desc: 'MAHLABATSINI',
      },
      {
        id: 20176,
        inkhundla_code: '019',
        business_property: 'R',
        long_desc: 'BETHANY',
      },
      {
        id: 20178,
        inkhundla_code: '021',
        business_property: 'R',
        long_desc: 'MAFUTSENI',
      },
      {
        id: 20180,
        inkhundla_code: '021',
        business_property: 'R',
        long_desc: 'THULWANE',
      },
      {
        id: 20182,
        inkhundla_code: '019',
        business_property: 'R',
        long_desc: 'NGWENYAMENI',
      },
      {
        id: 20183,
        inkhundla_code: '022',
        business_property: 'R',
        long_desc: 'SIGCINENI',
      },
      {
        id: 20186,
        inkhundla_code: '030',
        business_property: 'P',
        long_desc: 'EDWALENI',
      },
      {
        id: 20190,
        inkhundla_code: '047',
        business_property: 'R',
        long_desc: 'EDUMBE',
      },
      {
        id: 20200,
        inkhundla_code: '025',
        business_property: 'R',
        long_desc: 'MONENI',
      },
      {
        id: 20201,
        inkhundla_code: '025',
        business_property: 'U',
        long_desc: 'MANZINI THE HUB',
      },
      {
        id: 20218,
        inkhundla_code: '004',
        business_property: 'R',
        long_desc: 'MALANDZELA',
      },
      {
        id: 20250,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'KASIKO',
      },
      {
        id: 20300,
        inkhundla_code: '025',
        business_property: 'P',
        long_desc: 'MONENI',
      },
      {
        id: 20301,
        inkhundla_code: '024',
        business_property: 'P',
        long_desc: 'LUGAGANENI',
      },
      {
        id: 20302,
        inkhundla_code: '017',
        business_property: 'R',
        long_desc: 'EKUKHANYENI',
      },
      {
        id: 20306,
        inkhundla_code: '022',
        business_property: 'R',
        long_desc: 'EKUKHULUMENI',
      },
      {
        id: 20307,
        inkhundla_code: '027',
        business_property: 'P',
        long_desc: 'CROYDON',
      },
      {
        id: 20308,
        inkhundla_code: '021',
        business_property: 'R',
        long_desc: 'HHELEHHELE',
      },
      {
        id: 20309,
        inkhundla_code: '025',
        business_property: 'U',
        long_desc: 'TRELAWNEY PARK',
      },
      {
        id: 20310,
        inkhundla_code: '032',
        business_property: 'P',
        long_desc: 'GEBENI FARM',
      },
      {
        id: 20311,
        inkhundla_code: '024',
        business_property: 'P',
        long_desc: 'ST MICHAELS',
      },
      {
        id: 20312,
        inkhundla_code: '000',
        business_property: 'P',
        long_desc: 'MIDLANDS FARM',
      },
      {
        id: 20313,
        inkhundla_code: '019',
        business_property: 'U',
        long_desc: 'MALKERNS',
      },
      {
        id: 20314,
        inkhundla_code: '025',
        business_property: 'U',
        long_desc: 'WILMER PARK',
      },
      {
        id: 20855,
        inkhundla_code: '025',
        business_property: 'U',
        long_desc: 'SACRO MANZINI',
      },
      {
        id: 20856,
        inkhundla_code: '024',
        business_property: 'U',
        long_desc: 'MAVUSO TRADE CENTRE',
      },
      {
        id: 20857,
        inkhundla_code: '026',
        business_property: 'R',
        long_desc: 'MBANGAVE',
      },
      {
        id: 20858,
        inkhundla_code: '023',
        business_property: 'R',
        long_desc: 'DWALILE',
      },
      {
        id: 20859,
        inkhundla_code: '024',
        business_property: 'U',
        long_desc: 'SIKHUNYANE TOWNSHIP',
      },
      {
        id: 28087,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'MBELEBELENI',
      },
      {
        id: 28088,
        inkhundla_code: '025',
        business_property: 'U',
        long_desc: 'MANZINI SEDCO',
      },
      {
        id: 28089,
        inkhundla_code: '021',
        business_property: 'P',
        long_desc: 'MAFUTSENI RANCH',
      },
      {
        id: 28090,
        inkhundla_code: '027',
        business_property: 'P',
        long_desc: 'CROYDON',
      },
      {
        id: 28091,
        inkhundla_code: '033',
        business_property: 'P',
        long_desc: 'SICUNISA',
      },
      {
        id: 28092,
        inkhundla_code: '030',
        business_property: 'P',
        long_desc: 'MASUNDWINI',
      },
      {
        id: 28093,
        inkhundla_code: '028',
        business_property: 'P',
        long_desc: 'LWANDLE',
      },
      {
        id: 28094,
        inkhundla_code: '027',
        business_property: 'P',
        long_desc: 'EKUTSIMULENI',
      },
      {
        id: 28095,
        inkhundla_code: '025',
        business_property: 'P',
        long_desc: 'NAZARENE MISSION',
      },
      {
        id: 28096,
        inkhundla_code: '032',
        business_property: 'P',
        long_desc: 'NCABANENI',
      },
      {
        id: 28097,
        inkhundla_code: '024',
        business_property: 'U',
        long_desc: 'MADONSA',
      },
      {
        id: 28098,
        inkhundla_code: '027',
        business_property: 'P',
        long_desc: 'MBELEBELENI',
      },
      {
        id: 28099,
        inkhundla_code: '032',
        business_property: 'R',
        long_desc: 'NCABANENI',
      },
      {
        id: 28100,
        inkhundla_code: '032',
        business_property: 'R',
        long_desc: 'GEBENI',
      },
      {
        id: 28101,
        inkhundla_code: '024',
        business_property: 'U',
        long_desc: 'MAVUSO TRADE FAIR',
      },
      {
        id: 28102,
        inkhundla_code: '017',
        business_property: 'P',
        long_desc: 'MATSAPHA',
      },
      {
        id: 28103,
        inkhundla_code: '019',
        business_property: 'P',
        long_desc: 'LUYENGO FARM',
      },
      {
        id: 28104,
        inkhundla_code: '021',
        business_property: 'P',
        long_desc: 'NGOGOLA',
      },
      {
        id: 28105,
        inkhundla_code: '024',
        business_property: 'P',
        long_desc: 'MALIYADUMA',
      },
      {
        id: 28106,
        inkhundla_code: '033',
        business_property: 'R',
        long_desc: 'EMALANGENI',
      },
      {
        id: 28107,
        inkhundla_code: '033',
        business_property: 'P',
        long_desc: 'MGAZINI',
      },
      {
        id: 28108,
        inkhundla_code: '030',
        business_property: 'P',
        long_desc: 'DWALENI',
      },
      {
        id: 28109,
        inkhundla_code: '032',
        business_property: 'P',
        long_desc: 'NTONDOZI',
      },
      {
        id: 28110,
        inkhundla_code: '031',
        business_property: 'R',
        long_desc: 'ENI',
      },
      {
        id: 28111,
        inkhundla_code: '031',
        business_property: 'P',
        long_desc: 'SIGOMBENI',
      },
      {
        id: 28112,
        inkhundla_code: '018',
        business_property: 'R',
        long_desc: 'DAMBATHI',
      },
      {
        id: 28113,
        inkhundla_code: '027',
        business_property: 'P',
        long_desc: 'KHUPHUKA',
      },
      {
        id: 28114,
        inkhundla_code: '023',
        business_property: 'P',
        long_desc: 'SANDLANE',
      },
      {
        id: 28115,
        inkhundla_code: '017',
        business_property: 'R',
        long_desc: 'MAGEVINI',
      },
      {
        id: 28116,
        inkhundla_code: '024',
        business_property: 'U',
        long_desc: 'MADODA',
      },
      {
        id: 28117,
        inkhundla_code: '017',
        business_property: 'R',
        long_desc: 'MATSAPA INDUSTRIAL SITE',
      },
      {
        id: 28118,
        inkhundla_code: '017',
        business_property: 'P',
        long_desc: 'KWALUSENI FARM',
      },
      {
        id: 28119,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'MNJOLI',
      },
      {
        id: 28120,
        inkhundla_code: '027',
        business_property: 'P',
        long_desc: 'DVOKOLWAKO',
      },
      {
        id: 28121,
        inkhundla_code: '018',
        business_property: 'R',
        long_desc: 'MABHOKO LAMGABHI',
      },
      {
        id: 28122,
        inkhundla_code: '024',
        business_property: 'U',
        long_desc: 'NTUNJA TOWNSHIP',
      },
      {
        id: 28123,
        inkhundla_code: '029',
        business_property: 'P',
        long_desc: 'MANKAYANE FARM',
      },
      {
        id: 28124,
        inkhundla_code: '024',
        business_property: 'U',
        long_desc: 'SICELWINI',
      },
      {
        id: 28125,
        inkhundla_code: '030',
        business_property: 'R',
        long_desc: 'EMASINI',
      },
      {
        id: 28126,
        inkhundla_code: '025',
        business_property: 'U',
        long_desc: 'MHOBODLENI',
      },
      {
        id: 28127,
        inkhundla_code: '004',
        business_property: 'R',
        long_desc: 'MELETI',
      },
      {
        id: 28128,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'KHUPHUKA',
      },
      {
        id: 28129,
        inkhundla_code: '024',
        business_property: 'U',
        long_desc: 'MANZINI TOWNSHIP',
      },
      {
        id: 28130,
        inkhundla_code: '019',
        business_property: 'S',
        long_desc: 'NOKWANE',
      },
      {
        id: 28131,
        inkhundla_code: '030',
        business_property: 'S',
        long_desc: 'PHOCWENI',
      },
      {
        id: 28132,
        inkhundla_code: '031',
        business_property: 'R',
        long_desc: 'NGCAYINI',
      },
      {
        id: 28133,
        inkhundla_code: '025',
        business_property: 'U',
        long_desc: 'NDUMBU',
      },
      {
        id: 28134,
        inkhundla_code: '021',
        business_property: 'P',
        long_desc: 'NGCULWINI',
      },
      {
        id: 28135,
        inkhundla_code: '022',
        business_property: 'R',
        long_desc: 'NDZELENI',
      },
      {
        id: 28136,
        inkhundla_code: '030',
        business_property: 'S',
        long_desc: 'DWALENI SPECIAL ECONOMIC ZONE',
      },
      {
        id: 30000,
        inkhundla_code: '056',
        business_property: 'T',
        long_desc: 'SHISELWENI REGION',
      },
      {
        id: 30001,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO MARKET',
      },
      {
        id: 30002,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO MALL',
      },
      {
        id: 30003,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO TOBACCO PREMISES',
      },
      {
        id: 30004,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO ZAM ZAM COMPLEX',
      },
      {
        id: 30005,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO JABULANI COMPLEX',
      },
      {
        id: 30006,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO VILANE COMPLEX',
      },
      {
        id: 30007,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO MATHENDELE LOCATION',
      },
      {
        id: 30008,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO KHAYALAMI TOWNSHIP',
      },
      {
        id: 30009,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO BUILDERS COMPLEX',
      },
      {
        id: 30010,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO RED CROSS BUILDING',
      },
      {
        id: 30011,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO BUS RANK',
      },
      {
        id: 30012,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO INDUSTRIAL SITE',
      },
      {
        id: 30013,
        inkhundla_code: '056',
        business_property: 'P',
        long_desc: 'NHLANGANO T JUNCTION',
      },
      {
        id: 30014,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO SKONKWANE COMPLEX',
      },
      {
        id: 30015,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO OPPOSITE LAVUMISA AGENCIES',
      },
      {
        id: 30016,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO SHOWGROUND',
      },
      {
        id: 30017,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO KUKHANYA BUILDING',
      },
      {
        id: 30018,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO HERBST',
      },
      {
        id: 30019,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO SEDCO ESTATE',
      },
      {
        id: 30020,
        inkhundla_code: '056',
        business_property: 'T',
        long_desc: 'NHLANGANO',
      },
      {
        id: 30021,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'MAHAMBA MISSION',
      },
      {
        id: 30022,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'MAHAMBA BORDER',
      },
      {
        id: 30023,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'MAHAMBA HILLTOP',
      },
      {
        id: 30024,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'MAHLABATHINI',
      },
      {
        id: 30025,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'MBUKWANE',
      },
      {
        id: 30026,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'JOJO',
      },
      {
        id: 30027,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'JOUBERT',
      },
      {
        id: 30028,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'MAZOMBIZWE',
      },
      {
        id: 30029,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'MAHLANDLE',
      },
      {
        id: 30030,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'KADLOVUNGA',
      },
      {
        id: 30031,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'NTANDWENI',
      },
      {
        id: 30032,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'SALINA',
      },
      {
        id: 30033,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'JOPPA',
      },
      {
        id: 30034,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'MASEYISINI',
      },
      {
        id: 30035,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'HOLNECK',
      },
      {
        id: 30036,
        inkhundla_code: '050',
        business_property: 'R',
        long_desc: 'ESIPHAMBANWENI',
      },
      {
        id: 30037,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'KASHALI',
      },
      {
        id: 30038,
        inkhundla_code: '056',
        business_property: 'R',
        long_desc: 'MAKHUKHULA',
      },
      {
        id: 30039,
        inkhundla_code: '056',
        business_property: 'R',
        long_desc: 'NSONGWENI',
      },
      {
        id: 30040,
        inkhundla_code: '056',
        business_property: 'R',
        long_desc: 'MBANGWENI',
      },
      {
        id: 30041,
        inkhundla_code: '056',
        business_property: 'R',
        long_desc: 'NGWABI',
      },
      {
        id: 30042,
        inkhundla_code: '056',
        business_property: 'R',
        long_desc: 'MAHLALINI',
      },
      {
        id: 30043,
        inkhundla_code: '055',
        business_property: 'R',
        long_desc: 'MANTAMBE',
      },
      {
        id: 30044,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'NDUBAZI',
      },
      {
        id: 30045,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'NYAMANE',
      },
      {
        id: 30046,
        inkhundla_code: '059',
        business_property: 'R',
        long_desc: 'ZOMBODZE',
      },
      {
        id: 30047,
        inkhundla_code: '059',
        business_property: 'R',
        long_desc: 'ZOMBODZE EMUVA',
      },
      {
        id: 30048,
        inkhundla_code: '056',
        business_property: 'R',
        long_desc: 'MKHITSINI',
      },
      {
        id: 30049,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'JABULANE',
      },
      {
        id: 30050,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'MLAMBO',
      },
      {
        id: 30051,
        inkhundla_code: '049',
        business_property: 'U',
        long_desc: 'INTERBOARD',
      },
      {
        id: 30052,
        inkhundla_code: '045',
        business_property: 'R',
        long_desc: 'GEGE BORDER',
      },
      {
        id: 30053,
        inkhundla_code: '045',
        business_property: 'R',
        long_desc: 'GEGE BUS RANK',
      },
      {
        id: 30054,
        inkhundla_code: '045',
        business_property: 'R',
        long_desc: 'GEGE MARKERT',
      },
      {
        id: 30055,
        inkhundla_code: '045',
        business_property: 'R',
        long_desc: 'MAGUBHELENI',
      },
      {
        id: 30056,
        inkhundla_code: '050',
        business_property: 'R',
        long_desc: 'NSALITJE',
      },
      {
        id: 30057,
        inkhundla_code: '059',
        business_property: 'R',
        long_desc: 'ENGWENYAMENI',
      },
      {
        id: 30058,
        inkhundla_code: '050',
        business_property: 'R',
        long_desc: 'ESKHALENI',
      },
      {
        id: 30059,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'MHLWENI',
      },
      {
        id: 30060,
        inkhundla_code: '059',
        business_property: 'R',
        long_desc: 'EQINISWENI',
      },
      {
        id: 30061,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'MAKHOSINI',
      },
      {
        id: 30062,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'MACHOBENI',
      },
      {
        id: 30063,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'MAKHONZA',
      },
      {
        id: 30064,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'UPPER NYAMANE',
      },
      {
        id: 30065,
        inkhundla_code: '058',
        business_property: 'R',
        long_desc: 'MLINDAZWE',
      },
      {
        id: 30066,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'SITA NGWANE',
      },
      {
        id: 30067,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'SIMEMENI',
      },
      {
        id: 30068,
        inkhundla_code: '050',
        business_property: 'R',
        long_desc: 'BAMBITJE',
      },
      {
        id: 30069,
        inkhundla_code: '059',
        business_property: 'R',
        long_desc: 'MSHOLOLO',
      },
      {
        id: 30070,
        inkhundla_code: '045',
        business_property: 'R',
        long_desc: 'MASHOBENI',
      },
      {
        id: 30071,
        inkhundla_code: '045',
        business_property: 'R',
        long_desc: 'MASHOBENI SOUTH',
      },
      {
        id: 30072,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'MNCITSINI',
      },
      {
        id: 30073,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'MAKHOLWENI',
      },
      {
        id: 30074,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'NGUYE',
      },
      {
        id: 30075,
        inkhundla_code: '055',
        business_property: 'R',
        long_desc: 'EZIKHOTHENI',
      },
      {
        id: 30076,
        inkhundla_code: '059',
        business_property: 'R',
        long_desc: 'MHLOSHENI',
      },
      {
        id: 30077,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'NKONENI',
      },
      {
        id: 30078,
        inkhundla_code: '059',
        business_property: 'R',
        long_desc: 'KAMAHLAMBI',
      },
      {
        id: 30079,
        inkhundla_code: '045',
        business_property: 'R',
        long_desc: 'DUDUSINI',
      },
      {
        id: 30080,
        inkhundla_code: '059',
        business_property: 'R',
        long_desc: 'THUNZINI',
      },
      {
        id: 30081,
        inkhundla_code: '059',
        business_property: 'R',
        long_desc: 'HHOTELA',
      },
      {
        id: 30082,
        inkhundla_code: '059',
        business_property: 'R',
        long_desc: 'QIBIDE',
      },
      {
        id: 30083,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'PHAKAMANI',
      },
      {
        id: 30084,
        inkhundla_code: '056',
        business_property: 'R',
        long_desc: 'KHIZA',
      },
      {
        id: 30085,
        inkhundla_code: '058',
        business_property: 'R',
        long_desc: 'MAPOLODINI',
      },
      {
        id: 30086,
        inkhundla_code: '059',
        business_property: 'R',
        long_desc: 'FOSINI',
      },
      {
        id: 30087,
        inkhundla_code: '051',
        business_property: 'R',
        long_desc: 'KAMJIZA',
      },
      {
        id: 30089,
        inkhundla_code: '059',
        business_property: 'R',
        long_desc: 'EKUTHULENI',
      },
      {
        id: 30090,
        inkhundla_code: '056',
        business_property: 'R',
        long_desc: 'MASHEKESHENI',
      },
      {
        id: 30091,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'MATIMATIMA',
      },
      {
        id: 30092,
        inkhundla_code: '056',
        business_property: 'R',
        long_desc: 'BHAWINI',
      },
      {
        id: 30093,
        inkhundla_code: '045',
        business_property: 'R',
        long_desc: 'SISINGENI',
      },
      {
        id: 30094,
        inkhundla_code: '056',
        business_property: 'R',
        long_desc: 'MADULINI',
      },
      {
        id: 30095,
        inkhundla_code: '056',
        business_property: 'R',
        long_desc: 'MTHOMBE',
      },
      {
        id: 30096,
        inkhundla_code: '045',
        business_property: 'R',
        long_desc: 'SIYENDLE',
      },
      {
        id: 30097,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'LEBOMVU',
      },
      {
        id: 30098,
        inkhundla_code: '059',
        business_property: 'R',
        long_desc: 'FOSINI',
      },
      {
        id: 30099,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO TEMMEMO BUILDING',
      },
      {
        id: 30100,
        inkhundla_code: '056',
        business_property: 'R',
        long_desc: 'MAKHWELELA',
      },
      {
        id: 30101,
        inkhundla_code: '056',
        business_property: 'R',
        long_desc: 'BHAHWINI',
      },
      {
        id: 30102,
        inkhundla_code: '051',
        business_property: 'R',
        long_desc: 'KAMHLABA',
      },
      {
        id: 30103,
        inkhundla_code: '046',
        business_property: 'P',
        long_desc: 'HLUTHI',
      },
      {
        id: 30104,
        inkhundla_code: '057',
        business_property: 'R',
        long_desc: 'MANGENA',
      },
      {
        id: 30105,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'THEMBELIHLE',
      },
      {
        id: 30106,
        inkhundla_code: '058',
        business_property: 'R',
        long_desc: 'ETJENI',
      },
      {
        id: 30107,
        inkhundla_code: '050',
        business_property: 'R',
        long_desc: 'QOMINTABA',
      },
      {
        id: 30108,
        inkhundla_code: '057',
        business_property: 'R',
        long_desc: 'LOMVOVO',
      },
      {
        id: 30109,
        inkhundla_code: '050',
        business_property: 'R',
        long_desc: 'SIGAGANYANENI',
      },
      {
        id: 30110,
        inkhundla_code: '058',
        business_property: 'R',
        long_desc: 'MHLUSHWA',
      },
      {
        id: 30111,
        inkhundla_code: '046',
        business_property: 'R',
        long_desc: 'KAMZIKI',
      },
      {
        id: 30112,
        inkhundla_code: '057',
        business_property: 'R',
        long_desc: 'MPAKENI',
      },
      {
        id: 30113,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'EMATJENI',
      },
      {
        id: 30114,
        inkhundla_code: '050',
        business_property: 'R',
        long_desc: 'MATSANJENI',
      },
      {
        id: 30115,
        inkhundla_code: '057',
        business_property: 'R',
        long_desc: 'THUMBELA',
      },
      {
        id: 30116,
        inkhundla_code: '050',
        business_property: 'R',
        long_desc: 'MABANDE',
      },
      {
        id: 30117,
        inkhundla_code: '057',
        business_property: 'R',
        long_desc: 'DICARPOL',
      },
      {
        id: 30118,
        inkhundla_code: '050',
        business_property: 'R',
        long_desc: 'HLUSHWANA',
      },
      {
        id: 30119,
        inkhundla_code: '050',
        business_property: 'R',
        long_desc: 'SIPHAMBANWENI',
      },
      {
        id: 30120,
        inkhundla_code: '057',
        business_property: 'R',
        long_desc: 'NYATSINI',
      },
      {
        id: 30121,
        inkhundla_code: '046',
        business_property: 'R',
        long_desc: 'SHISIZWE',
      },
      {
        id: 30122,
        inkhundla_code: '050',
        business_property: 'R',
        long_desc: 'A1',
      },
      {
        id: 30123,
        inkhundla_code: '050',
        business_property: 'R',
        long_desc: 'MGAMUDZE',
      },
      {
        id: 30124,
        inkhundla_code: '057',
        business_property: 'R',
        long_desc: 'LULAKENI/MPHAKATSINI',
      },
      {
        id: 30125,
        inkhundla_code: '050',
        business_property: 'R',
        long_desc: 'GONCO',
      },
      {
        id: 30126,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MDIKHONI',
      },
      {
        id: 30127,
        inkhundla_code: '057',
        business_property: 'R',
        long_desc: 'NDUNAZITHINI',
      },
      {
        id: 30128,
        inkhundla_code: '057',
        business_property: 'R',
        long_desc: 'KUTIBANE',
      },
      {
        id: 30129,
        inkhundla_code: '050',
        business_property: 'R',
        long_desc: 'NTINTINTI',
      },
      {
        id: 30130,
        inkhundla_code: '057',
        business_property: 'R',
        long_desc: 'EMBAVA(BHODWE ENDLALENI)',
      },
      {
        id: 30131,
        inkhundla_code: '046',
        business_property: 'R',
        long_desc: 'PHALAMENDE',
      },
      {
        id: 30132,
        inkhundla_code: '046',
        business_property: 'R',
        long_desc: 'CHAPOTINI',
      },
      {
        id: 30133,
        inkhundla_code: '059',
        business_property: 'R',
        long_desc: 'ZIBONDENI',
      },
      {
        id: 30134,
        inkhundla_code: '057',
        business_property: 'R',
        long_desc: 'SIGWE',
      },
      {
        id: 30135,
        inkhundla_code: '046',
        business_property: 'R',
        long_desc: 'HOSIA',
      },
      {
        id: 30136,
        inkhundla_code: '058',
        business_property: 'R',
        long_desc: 'ESIKHALENI',
      },
      {
        id: 30137,
        inkhundla_code: '055',
        business_property: 'R',
        long_desc: 'SIVULA',
      },
      {
        id: 30138,
        inkhundla_code: '046',
        business_property: 'R',
        long_desc: 'NSINGIZINI',
      },
      {
        id: 30139,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'KASANTABA',
      },
      {
        id: 30140,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'LUKHETSENI',
      },
      {
        id: 30141,
        inkhundla_code: '057',
        business_property: 'R',
        long_desc: 'KAMNGAYI',
      },
      {
        id: 30142,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MACUNWANE',
      },
      {
        id: 30143,
        inkhundla_code: '058',
        business_property: 'R',
        long_desc: 'SOMNTONGO',
      },
      {
        id: 30144,
        inkhundla_code: '058',
        business_property: 'R',
        long_desc: 'SOMNTONGO ENKUNDLENI',
      },
      {
        id: 30145,
        inkhundla_code: '058',
        business_property: 'R',
        long_desc: 'KABULAWAKO',
      },
      {
        id: 30146,
        inkhundla_code: '046',
        business_property: 'R',
        long_desc: 'NDIYANENI',
      },
      {
        id: 30147,
        inkhundla_code: '057',
        business_property: 'R',
        long_desc: 'EMBAVA',
      },
      {
        id: 30148,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'TIDULINI',
      },
      {
        id: 30149,
        inkhundla_code: '055',
        business_property: 'R',
        long_desc: 'MANTAMBE',
      },
      {
        id: 30150,
        inkhundla_code: '056',
        business_property: 'R',
        long_desc: 'MAKHWEKHWENI',
      },
      {
        id: 30151,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MAGEDLAKATANA',
      },
      {
        id: 30152,
        inkhundla_code: '046',
        business_property: 'R',
        long_desc: 'EMVUNDLENI',
      },
      {
        id: 30153,
        inkhundla_code: '050',
        business_property: 'R',
        long_desc: 'HLUSHWANA',
      },
      {
        id: 30154,
        inkhundla_code: '050',
        business_property: 'R',
        long_desc: 'ELUBIVANENI',
      },
      {
        id: 30155,
        inkhundla_code: '050',
        business_property: 'R',
        long_desc: 'MGUDWINI',
      },
      {
        id: 30156,
        inkhundla_code: '046',
        business_property: 'R',
        long_desc: 'NCIMFI',
      },
      {
        id: 30157,
        inkhundla_code: '055',
        business_property: 'R',
        long_desc: 'ZIKHOTHENI',
      },
      {
        id: 30158,
        inkhundla_code: '046',
        business_property: 'R',
        long_desc: 'ELUSHINI',
      },
      {
        id: 30159,
        inkhundla_code: '046',
        business_property: 'R',
        long_desc: 'MANYANDZENI',
      },
      {
        id: 30160,
        inkhundla_code: '046',
        business_property: 'R',
        long_desc: 'BHEJISA',
      },
      {
        id: 30161,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'NGEDLENI',
      },
      {
        id: 30162,
        inkhundla_code: '058',
        business_property: 'R',
        long_desc: 'MSENGENI',
      },
      {
        id: 30163,
        inkhundla_code: '058',
        business_property: 'R',
        long_desc: 'KULEMHLOPHE',
      },
      {
        id: 30164,
        inkhundla_code: '055',
        business_property: 'R',
        long_desc: 'HLATANE',
      },
      {
        id: 30165,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'KWAHLENGELA',
      },
      {
        id: 30166,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'NOMGWINJI',
      },
      {
        id: 30167,
        inkhundla_code: '050',
        business_property: 'R',
        long_desc: 'PHUMELELA',
      },
      {
        id: 30168,
        inkhundla_code: '055',
        business_property: 'R',
        long_desc: 'EKUJABULENI',
      },
      {
        id: 30169,
        inkhundla_code: '058',
        business_property: 'R',
        long_desc: 'ZINDWENDWENI',
      },
      {
        id: 30170,
        inkhundla_code: '057',
        business_property: 'R',
        long_desc: 'MBAVA',
      },
      {
        id: 30171,
        inkhundla_code: '057',
        business_property: 'R',
        long_desc: 'MCWOCWANE',
      },
      {
        id: 30172,
        inkhundla_code: '057',
        business_property: 'R',
        long_desc: 'MAKHAVA',
      },
      {
        id: 30173,
        inkhundla_code: '046',
        business_property: 'R',
        long_desc: 'KAFINCI',
      },
      {
        id: 30174,
        inkhundla_code: '055',
        business_property: 'R',
        long_desc: 'EGALILE',
      },
      {
        id: 30175,
        inkhundla_code: '046',
        business_property: 'R',
        long_desc: 'MAGUNWANE',
      },
      {
        id: 30176,
        inkhundla_code: '046',
        business_property: 'R',
        long_desc: 'EMPHAKATSINI',
      },
      {
        id: 30177,
        inkhundla_code: '046',
        business_property: 'R',
        long_desc: 'EMDLEBE',
      },
      {
        id: 30178,
        inkhundla_code: '055',
        business_property: 'R',
        long_desc: 'NCOTSHANE',
      },
      {
        id: 30179,
        inkhundla_code: '046',
        business_property: 'R',
        long_desc: 'KWAHLENGELA',
      },
      {
        id: 30180,
        inkhundla_code: '055',
        business_property: 'R',
        long_desc: 'MIZPER',
      },
      {
        id: 30181,
        inkhundla_code: '046',
        business_property: 'R',
        long_desc: 'MAVUNDLUTHI',
      },
      {
        id: 30182,
        inkhundla_code: '046',
        business_property: 'R',
        long_desc: 'MABONA',
      },
      {
        id: 30183,
        inkhundla_code: '046',
        business_property: 'R',
        long_desc: 'JERICHO',
      },
      {
        id: 30184,
        inkhundla_code: '055',
        business_property: 'R',
        long_desc: 'HHUHHUMA',
      },
      {
        id: 30185,
        inkhundla_code: '053',
        business_property: 'R',
        long_desc: 'MANYEVENI',
      },
      {
        id: 30186,
        inkhundla_code: '058',
        business_property: 'R',
        long_desc: 'NDABAZEZWE',
      },
      {
        id: 30187,
        inkhundla_code: '058',
        business_property: 'U',
        long_desc: 'LAVUMISA',
      },
      {
        id: 30188,
        inkhundla_code: '058',
        business_property: 'R',
        long_desc: 'MHLWENI',
      },
      {
        id: 30189,
        inkhundla_code: '057',
        business_property: 'R',
        long_desc: 'NTSANGENI',
      },
      {
        id: 30190,
        inkhundla_code: '054',
        business_property: 'R',
        long_desc: 'NKUNGWINI',
      },
      {
        id: 30191,
        inkhundla_code: '052',
        business_property: 'R',
        long_desc: 'GASA',
      },
      {
        id: 30192,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'NDLOTANE',
      },
      {
        id: 30193,
        inkhundla_code: '045',
        business_property: 'R',
        long_desc: 'GEGE',
      },
      {
        id: 30194,
        inkhundla_code: '054',
        business_property: 'R',
        long_desc: 'NGOLOLWENI',
      },
      {
        id: 30195,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'VUSWENI',
      },
      {
        id: 30196,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'GULUVA',
      },
      {
        id: 30197,
        inkhundla_code: '055',
        business_property: 'R',
        long_desc: 'MKHWAKHWENI',
      },
      {
        id: 30198,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO NEXT TO ECEMBENI',
      },
      {
        id: 30199,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO MOURDANT BUILDING',
      },
      {
        id: 30200,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO PHOENIX BUILDING',
      },
      {
        id: 30201,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO NEXT TO EKUPHOLENI',
      },
      {
        id: 30202,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO EMANGWANENI',
      },
      {
        id: 30203,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHL OPPOSITE SOS CHILDREN VILLAGE',
      },
      {
        id: 30204,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO OPPOSITE METRO',
      },
      {
        id: 30205,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MPUNGANDLA',
      },
      {
        id: 30206,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO BENGUNI COMPLEX',
      },
      {
        id: 30207,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO OPPOSITE POLICE STATION',
      },
      {
        id: 30208,
        inkhundla_code: '055',
        business_property: 'R',
        long_desc: 'MASIPHULA',
      },
      {
        id: 30209,
        inkhundla_code: '051',
        business_property: 'P',
        long_desc: 'MOOIHOEK',
      },
      {
        id: 30210,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: "NHLANGANO OPPOSITE OLD D.C'S BUILDINGS",
      },
      {
        id: 30211,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO-GEGE ROAD',
      },
      {
        id: 30212,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO NEXT TO TARGET',
      },
      {
        id: 30213,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO CLINIC PHARMACY BUILDING',
      },
      {
        id: 30214,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO BLUE WATERS',
      },
      {
        id: 30215,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MATJENI',
      },
      {
        id: 30216,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO CARMICHAEL BUILDING',
      },
      {
        id: 30217,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'NGELANE',
      },
      {
        id: 30218,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHO OPP.MICA HARDWARE',
      },
      {
        id: 30219,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO SHISELWENI FORESTRY COMPANY',
      },
      {
        id: 30220,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO OPPOSITE SEB',
      },
      {
        id: 30221,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO SWAZI BANK LINE',
      },
      {
        id: 30222,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO BEHIND MATATAZELA',
      },
      {
        id: 30223,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO NCCE PREMISES',
      },
      {
        id: 30224,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO RUDOLPH BUILDING',
      },
      {
        id: 30225,
        inkhundla_code: '055',
        business_property: 'R',
        long_desc: 'EZIHOSHENI',
      },
      {
        id: 30226,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO AYITEKWA PENNY BUILDING',
      },
      {
        id: 30228,
        inkhundla_code: '046',
        business_property: 'R',
        long_desc: 'ESIGANGANYANENI',
      },
      {
        id: 30229,
        inkhundla_code: '059',
        business_property: 'P',
        long_desc: 'DWALENI',
      },
      {
        id: 30230,
        inkhundla_code: '057',
        business_property: 'P',
        long_desc: 'MAKHAVA FARM',
      },
      {
        id: 30231,
        inkhundla_code: '057',
        business_property: 'R',
        long_desc: 'LUSABENI',
      },
      {
        id: 30232,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'MAHAMBA',
      },
      {
        id: 30233,
        inkhundla_code: '059',
        business_property: 'R',
        long_desc: 'MAMPONDWENI',
      },
      {
        id: 30234,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO OPPOSITE SWAZIOX',
      },
      {
        id: 30235,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO BEHIND SHAMROCK',
      },
      {
        id: 30236,
        inkhundla_code: '050',
        business_property: 'R',
        long_desc: 'NKUTJINI',
      },
      {
        id: 30237,
        inkhundla_code: '050',
        business_property: 'R',
        long_desc: 'NKUTJINI',
      },
      {
        id: 30238,
        inkhundla_code: '049',
        business_property: 'P',
        long_desc: 'MAHAMBA',
      },
      {
        id: 30239,
        inkhundla_code: '049',
        business_property: 'P',
        long_desc: 'MAHAMBA FARM',
      },
      {
        id: 30326,
        inkhundla_code: '059',
        business_property: 'R',
        long_desc: 'CIBIDE',
      },
      {
        id: 30327,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO SWAZI PROPERTY MARKET',
      },
      {
        id: 30328,
        inkhundla_code: '046',
        business_property: 'R',
        long_desc: 'SITHWETHWENI',
      },
      {
        id: 30329,
        inkhundla_code: '046',
        business_property: 'U',
        long_desc: 'SICUNUSA',
      },
      {
        id: 30330,
        inkhundla_code: '000',
        business_property: 'U',
        long_desc: 'MKHUHLWINI',
      },
      {
        id: 30331,
        inkhundla_code: '051',
        business_property: 'R',
        long_desc: 'MADZAKENI',
      },
      {
        id: 30332,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO POST OFFICE BUILDING',
      },
      {
        id: 30333,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO RICHFIELD PREMISES',
      },
      {
        id: 30334,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO FORMER FIVE EMALANGENI',
      },
      {
        id: 30335,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO THEKWINI',
      },
      {
        id: 30336,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO MASABASABA',
      },
      {
        id: 30337,
        inkhundla_code: '050',
        business_property: 'R',
        long_desc: 'LOMBIWAKO',
      },
      {
        id: 30338,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO OPPOSITE TEMMEMO',
      },
      {
        id: 30339,
        inkhundla_code: '056',
        business_property: 'P',
        long_desc: 'MHLOSHENI FARM',
      },
      {
        id: 30340,
        inkhundla_code: '059',
        business_property: 'R',
        long_desc: 'DWALENI',
      },
      {
        id: 30341,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO RSG ESTATE AGENTS',
      },
      {
        id: 30342,
        inkhundla_code: '058',
        business_property: 'U',
        long_desc: 'LAVUMISA BUS RANK',
      },
      {
        id: 30343,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO MICA',
      },
      {
        id: 30344,
        inkhundla_code: '058',
        business_property: 'R',
        long_desc: 'LUBIVANE',
      },
      {
        id: 30345,
        inkhundla_code: '057',
        business_property: 'R',
        long_desc: 'LULAKENI',
      },
      {
        id: 30346,
        inkhundla_code: '057',
        business_property: 'R',
        long_desc: 'TIBANE',
      },
      {
        id: 30347,
        inkhundla_code: '056',
        business_property: 'P',
        long_desc: 'NHLANGANO FARM',
      },
      {
        id: 30348,
        inkhundla_code: '055',
        business_property: 'R',
        long_desc: 'KAMSIZI',
      },
      {
        id: 30349,
        inkhundla_code: '055',
        business_property: 'R',
        long_desc: 'EMZIMVUBU',
      },
      {
        id: 30350,
        inkhundla_code: '045',
        business_property: 'R',
        long_desc: 'SICUNUSA',
      },
      {
        id: 30351,
        inkhundla_code: '045',
        business_property: 'P',
        long_desc: 'SICUNUSA',
      },
      {
        id: 30352,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO SHELL',
      },
      {
        id: 30353,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO NEXT TO NATICC',
      },
      {
        id: 30354,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'NZONGOMANE',
      },
      {
        id: 30355,
        inkhundla_code: '050',
        business_property: 'R',
        long_desc: 'MGAMPONDO',
      },
      {
        id: 30356,
        inkhundla_code: '056',
        business_property: 'P',
        long_desc: 'NHLANGANO FARM A.V',
      },
      {
        id: 30357,
        inkhundla_code: '056',
        business_property: 'P',
        long_desc: 'MONTINGY FARM',
      },
      {
        id: 30358,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO URBAN',
      },
      {
        id: 30359,
        inkhundla_code: '050',
        business_property: 'P',
        long_desc: 'MATSANJENI',
      },
      {
        id: 30360,
        inkhundla_code: '055',
        business_property: 'P',
        long_desc: 'MANTAMBE',
      },
      {
        id: 33343,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO BAMBISANA',
      },
      {
        id: 35001,
        inkhundla_code: '051',
        business_property: 'U',
        long_desc: 'HLATHIKHULU BUS RANK',
      },
      {
        id: 35002,
        inkhundla_code: '051',
        business_property: 'U',
        long_desc: 'HLATHIKHULU MARKET',
      },
      {
        id: 35003,
        inkhundla_code: '052',
        business_property: 'R',
        long_desc: 'NTJANINI',
      },
      {
        id: 35004,
        inkhundla_code: '047',
        business_property: 'R',
        long_desc: 'KAKHOLWANE',
      },
      {
        id: 35005,
        inkhundla_code: '047',
        business_property: 'R',
        long_desc: 'MKHONDVO',
      },
      {
        id: 35006,
        inkhundla_code: '053',
        business_property: 'R',
        long_desc: 'NKWENE',
      },
      {
        id: 35007,
        inkhundla_code: '046',
        business_property: 'R',
        long_desc: 'KALIBA',
      },
      {
        id: 35008,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'NTABENI',
      },
      {
        id: 35009,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: '35010NE',
      },
      {
        id: 35010,
        inkhundla_code: '054',
        business_property: 'R',
        long_desc: 'RLATHIKHULU R.D.A.',
      },
      {
        id: 35011,
        inkhundla_code: '053',
        business_property: 'R',
        long_desc: 'NYANYALI',
      },
      {
        id: 35012,
        inkhundla_code: '054',
        business_property: 'R',
        long_desc: 'EMBELEBELENI',
      },
      {
        id: 35013,
        inkhundla_code: '051',
        business_property: 'R',
        long_desc: 'MHLABUYADUMA',
      },
      {
        id: 35014,
        inkhundla_code: '052',
        business_property: 'R',
        long_desc: 'MHAWU',
      },
      {
        id: 35015,
        inkhundla_code: '054',
        business_property: 'R',
        long_desc: 'ESINDANDLALA',
      },
      {
        id: 35016,
        inkhundla_code: '051',
        business_property: 'R',
        long_desc: 'SIBETSAMOYA',
      },
      {
        id: 35017,
        inkhundla_code: '059',
        business_property: 'R',
        long_desc: 'ZIBONDENI',
      },
      {
        id: 35018,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'RAMZILA',
      },
      {
        id: 35019,
        inkhundla_code: '051',
        business_property: 'R',
        long_desc: 'SALEM',
      },
      {
        id: 35020,
        inkhundla_code: '047',
        business_property: 'R',
        long_desc: 'KAPHUNGA',
      },
      {
        id: 35021,
        inkhundla_code: '051',
        business_property: 'R',
        long_desc: 'HLATHIKHULU(NEW NAZARETH SCHOOL)',
      },
      {
        id: 35022,
        inkhundla_code: '051',
        business_property: 'R',
        long_desc: 'MTSAMBAMA',
      },
      {
        id: 35023,
        inkhundla_code: '047',
        business_property: 'R',
        long_desc: 'KUBUTA',
      },
      {
        id: 35024,
        inkhundla_code: '051',
        business_property: 'R',
        long_desc: 'KONTSHINGILA',
      },
      {
        id: 35025,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MHLONTSE',
      },
      {
        id: 35026,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'NKOMANE',
      },
      {
        id: 35027,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MAHHAHHAMA',
      },
      {
        id: 35028,
        inkhundla_code: '052',
        business_property: 'R',
        long_desc: 'HHOLOMI',
      },
      {
        id: 35029,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'RANYONYANE',
      },
      {
        id: 35030,
        inkhundla_code: '046',
        business_property: 'R',
        long_desc: 'MGOZI',
      },
      {
        id: 35031,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MAVIYOGO',
      },
      {
        id: 35032,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'DOLOMBA',
      },
      {
        id: 35033,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'RALOMA',
      },
      {
        id: 35034,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'ELUSINTSINI',
      },
      {
        id: 35035,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'ESIKHEHLENI',
      },
      {
        id: 35036,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'ENTABENI',
      },
      {
        id: 35037,
        inkhundla_code: '048',
        business_property: 'R',
        long_desc: 'MASHAYEKHATSI',
      },
      {
        id: 35038,
        inkhundla_code: '057',
        business_property: 'R',
        long_desc: 'SIGWE',
      },
      {
        id: 35039,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'ETIDULINI',
      },
      {
        id: 35040,
        inkhundla_code: '057',
        business_property: 'R',
        long_desc: 'LULAKENI',
      },
      {
        id: 35041,
        inkhundla_code: '051',
        business_property: 'R',
        long_desc: 'MANGETSE',
      },
      {
        id: 35042,
        inkhundla_code: '051',
        business_property: 'R',
        long_desc: 'MATIMETI',
      },
      {
        id: 35043,
        inkhundla_code: '054',
        business_property: 'R',
        long_desc: 'MBULUNGWANE',
      },
      {
        id: 35044,
        inkhundla_code: '051',
        business_property: 'R',
        long_desc: 'EMWENI',
      },
      {
        id: 35045,
        inkhundla_code: '054',
        business_property: 'R',
        long_desc: 'GODLOZA',
      },
      {
        id: 35046,
        inkhundla_code: '054',
        business_property: 'R',
        long_desc: 'ESANDLENI',
      },
      {
        id: 35047,
        inkhundla_code: '056',
        business_property: 'R',
        long_desc: 'EMBOWANE',
      },
      {
        id: 35048,
        inkhundla_code: '056',
        business_property: 'R',
        long_desc: 'MADALAZA',
      },
      {
        id: 35049,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'ENDZINGENI',
      },
      {
        id: 35050,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'EKUHLALENI',
      },
      {
        id: 35051,
        inkhundla_code: '051',
        business_property: 'R',
        long_desc: 'EBENEZER',
      },
      {
        id: 35052,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'PHUNGWAYO',
      },
      {
        id: 35053,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'ESIMBOKWENI',
      },
      {
        id: 35054,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'KATIDUZA',
      },
      {
        id: 35055,
        inkhundla_code: '056',
        business_property: 'R',
        long_desc: 'EMBEKA',
      },
      {
        id: 35056,
        inkhundla_code: '051',
        business_property: 'R',
        long_desc: 'MAVUKUTFU',
      },
      {
        id: 35057,
        inkhundla_code: '051',
        business_property: 'R',
        long_desc: 'MPHATHENI',
      },
      {
        id: 35058,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'KAPHANGWAYO',
      },
      {
        id: 35059,
        inkhundla_code: '053',
        business_property: 'R',
        long_desc: 'MEHLWABOVU',
      },
      {
        id: 35060,
        inkhundla_code: '048',
        business_property: 'R',
        long_desc: 'NEW HEAVEN',
      },
      {
        id: 35061,
        inkhundla_code: '051',
        business_property: 'R',
        long_desc: 'NEW WARM',
      },
      {
        id: 35062,
        inkhundla_code: '048',
        business_property: 'P',
        long_desc: 'MOOIHOEK',
      },
      {
        id: 35063,
        inkhundla_code: '047',
        business_property: 'R',
        long_desc: 'KAMFISHANE',
      },
      {
        id: 35064,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'HLATANE',
      },
      {
        id: 35065,
        inkhundla_code: '051',
        business_property: 'R',
        long_desc: 'KAZENZILE',
      },
      {
        id: 35066,
        inkhundla_code: '053',
        business_property: 'R',
        long_desc: 'NKWENI SCHOOL 1',
      },
      {
        id: 35067,
        inkhundla_code: '048',
        business_property: 'R',
        long_desc: 'DUMAKO',
      },
      {
        id: 35068,
        inkhundla_code: '048',
        business_property: 'R',
        long_desc: 'KAKHAMSILE',
      },
      {
        id: 35070,
        inkhundla_code: '056',
        business_property: 'R',
        long_desc: 'NEDSO',
      },
      {
        id: 35071,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'VEZI',
      },
      {
        id: 35072,
        inkhundla_code: '046',
        business_property: 'R',
        long_desc: 'NDABANKULU',
      },
      {
        id: 35073,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'HLATANE',
      },
      {
        id: 35074,
        inkhundla_code: '054',
        business_property: 'R',
        long_desc: 'EKWALIWENI',
      },
      {
        id: 35075,
        inkhundla_code: '049',
        business_property: 'R',
        long_desc: 'EMASIMINI',
      },
      {
        id: 35076,
        inkhundla_code: '051',
        business_property: 'R',
        long_desc: 'SALEM T JUNCTION',
      },
      {
        id: 35077,
        inkhundla_code: '054',
        business_property: 'R',
        long_desc: 'KALUKHELE',
      },
      {
        id: 35079,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MQHABINI',
      },
      {
        id: 35080,
        inkhundla_code: '051',
        business_property: 'R',
        long_desc: 'ESAKENI',
      },
      {
        id: 35081,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'SOBHAWE',
      },
      {
        id: 35082,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'KULEMHLOPHE',
      },
      {
        id: 35083,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'EMKHUHLWINI',
      },
      {
        id: 35085,
        inkhundla_code: '045',
        business_property: 'R',
        long_desc: 'ESIGENGENI',
      },
      {
        id: 35086,
        inkhundla_code: '051',
        business_property: 'R',
        long_desc: 'MDABULA',
      },
      {
        id: 35087,
        inkhundla_code: '051',
        business_property: 'R',
        long_desc: 'SIBHOTELENI',
      },
      {
        id: 35088,
        inkhundla_code: '047',
        business_property: 'R',
        long_desc: 'KAMFISHANE',
      },
      {
        id: 35089,
        inkhundla_code: '054',
        business_property: 'R',
        long_desc: 'NHLENTJENI',
      },
      {
        id: 35090,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'ENDENI',
      },
      {
        id: 35091,
        inkhundla_code: '051',
        business_property: 'R',
        long_desc: 'KWENDZENI',
      },
      {
        id: 35092,
        inkhundla_code: '054',
        business_property: 'R',
        long_desc: 'EZIBONDENI',
      },
      {
        id: 35093,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MHLONTSE',
      },
      {
        id: 35094,
        inkhundla_code: '048',
        business_property: 'R',
        long_desc: 'NKOMONYE',
      },
      {
        id: 35095,
        inkhundla_code: '053',
        business_property: 'R',
        long_desc: 'BUSELENI',
      },
      {
        id: 35096,
        inkhundla_code: '054',
        business_property: 'P',
        long_desc: 'MPOMPOTHA',
      },
      {
        id: 35097,
        inkhundla_code: '056',
        business_property: 'R',
        long_desc: 'MBOWANE',
      },
      {
        id: 35098,
        inkhundla_code: '054',
        business_property: 'R',
        long_desc: 'MAGAGANE',
      },
      {
        id: 35099,
        inkhundla_code: '051',
        business_property: 'R',
        long_desc: 'MAKHALATSINI',
      },
      {
        id: 35100,
        inkhundla_code: '054',
        business_property: 'R',
        long_desc: 'NGOLOLWENI',
      },
      {
        id: 35101,
        inkhundla_code: '052',
        business_property: 'R',
        long_desc: 'NTSHANINI',
      },
      {
        id: 35102,
        inkhundla_code: '051',
        business_property: 'U',
        long_desc: 'HLATHIKHULU',
      },
      {
        id: 35103,
        inkhundla_code: '053',
        business_property: 'R',
        long_desc: 'MAWELAWELA',
      },
      {
        id: 35104,
        inkhundla_code: '051',
        business_property: 'R',
        long_desc: 'EKWENDZENI',
      },
      {
        id: 35105,
        inkhundla_code: '052',
        business_property: 'R',
        long_desc: 'NGUDZENI',
      },
      {
        id: 35106,
        inkhundla_code: '053',
        business_property: 'R',
        long_desc: 'MANYEVENI',
      },
      {
        id: 35107,
        inkhundla_code: '051',
        business_property: 'U',
        long_desc: 'HLATHIKULU POLICE STATION',
      },
      {
        id: 35108,
        inkhundla_code: '048',
        business_property: 'R',
        long_desc: 'LAWUBA',
      },
      {
        id: 35109,
        inkhundla_code: '054',
        business_property: 'R',
        long_desc: 'SINDZANDLALA',
      },
      {
        id: 35110,
        inkhundla_code: '057',
        business_property: 'R',
        long_desc: 'KAMANGANA',
      },
      {
        id: 35111,
        inkhundla_code: '047',
        business_property: 'R',
        long_desc: 'MOYENI',
      },
      {
        id: 35112,
        inkhundla_code: '051',
        business_property: 'U',
        long_desc: 'HLATHIKHULU SEDCO',
      },
      {
        id: 35113,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MAGULUTSHANE',
      },
      {
        id: 35114,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MOOIPLAAS',
      },
      {
        id: 35115,
        inkhundla_code: '047',
        business_property: 'R',
        long_desc: 'HHOMETINI(KAPHUNGA)',
      },
      {
        id: 35116,
        inkhundla_code: '051',
        business_property: 'R',
        long_desc: 'MANGWANENI (HLATHIKULU)',
      },
      {
        id: 35117,
        inkhundla_code: '048',
        business_property: 'R',
        long_desc: 'LUCOLWENI',
      },
      {
        id: 35118,
        inkhundla_code: '053',
        business_property: 'R',
        long_desc: 'HLATHIKULU(TEBETEBE)',
      },
      {
        id: 35119,
        inkhundla_code: '046',
        business_property: 'U',
        long_desc: 'LUSHINI',
      },
      {
        id: 35120,
        inkhundla_code: '052',
        business_property: 'R',
        long_desc: 'NSINDZATSHE',
      },
      {
        id: 35121,
        inkhundla_code: '046',
        business_property: 'R',
        long_desc: 'LUSHINI',
      },
      {
        id: 35122,
        inkhundla_code: '047',
        business_property: 'R',
        long_desc: 'NGOBOLWENI',
      },
      {
        id: 35123,
        inkhundla_code: '054',
        business_property: 'R',
        long_desc: 'TIBONDZENI',
      },
      {
        id: 35124,
        inkhundla_code: '052',
        business_property: 'R',
        long_desc: 'KANZAMEYA',
      },
      {
        id: 35125,
        inkhundla_code: '056',
        business_property: 'R',
        long_desc: 'DULINI',
      },
      {
        id: 35126,
        inkhundla_code: '054',
        business_property: 'R',
        long_desc: 'MPAMBANI',
      },
      {
        id: 35127,
        inkhundla_code: '054',
        business_property: 'R',
        long_desc: 'KAGWEGWE',
      },
      {
        id: 35128,
        inkhundla_code: '048',
        business_property: 'R',
        long_desc: 'MBELEBELENI',
      },
      {
        id: 35129,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'KALOKHOKHOMA',
      },
      {
        id: 35130,
        inkhundla_code: '054',
        business_property: 'R',
        long_desc: 'MOOIHOCK',
      },
      {
        id: 35131,
        inkhundla_code: '051',
        business_property: 'R',
        long_desc: 'MAHLASHANENI',
      },
      {
        id: 35132,
        inkhundla_code: '051',
        business_property: 'R',
        long_desc: 'MAGELE',
      },
      {
        id: 35133,
        inkhundla_code: '047',
        business_property: 'R',
        long_desc: 'NHLALABANTFU',
      },
      {
        id: 35134,
        inkhundla_code: '048',
        business_property: 'R',
        long_desc: 'PONGOLWANE',
      },
      {
        id: 35135,
        inkhundla_code: '054',
        business_property: 'R',
        long_desc: 'KALANGALINGENI',
      },
      {
        id: 35145,
        inkhundla_code: '051',
        business_property: 'R',
        long_desc: 'BHANGANOMA',
      },
      {
        id: 35199,
        inkhundla_code: '051',
        business_property: 'P',
        long_desc: 'HLATHIKHULU FARM',
      },
      {
        id: 35227,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO NEXT TO OLD KHARAFA BUILDING',
      },
      {
        id: 35343,
        inkhundla_code: '059',
        business_property: 'R',
        long_desc: 'MAHOSHA',
      },
      {
        id: 35351,
        inkhundla_code: '054',
        business_property: 'R',
        long_desc: 'DEBEDEBE',
      },
      {
        id: 35352,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO INDUSTRIAL SITE',
      },
      {
        id: 35353,
        inkhundla_code: '059',
        business_property: 'R',
        long_desc: 'NGWENYAMENI',
      },
      {
        id: 35354,
        inkhundla_code: '055',
        business_property: 'R',
        long_desc: 'JERUSALEM',
      },
      {
        id: 35355,
        inkhundla_code: '000',
        business_property: 'P',
        long_desc: 'GLENVUMA',
      },
      {
        id: 35356,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'NHLANGANO MAIN STREET',
      },
      {
        id: 35357,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'EMAPHETSELWENI FARM',
      },
      {
        id: 35358,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'MATHENDELE LOCATION',
      },
      {
        id: 35359,
        inkhundla_code: '056',
        business_property: 'U',
        long_desc: 'INTERBOARD',
      },
      {
        id: 35360,
        inkhundla_code: '045',
        business_property: 'U',
        long_desc: 'GEGE ROAD',
      },
      {
        id: 35361,
        inkhundla_code: '051',
        business_property: 'R',
        long_desc: 'KAMBHOKE',
      },
      {
        id: 35362,
        inkhundla_code: '051',
        business_property: 'U',
        long_desc: 'SIBETSAMOYA',
      },
      {
        id: 35363,
        inkhundla_code: '057',
        business_property: 'R',
        long_desc: 'DIKAPHOLI',
      },
      {
        id: 35364,
        inkhundla_code: '059',
        business_property: 'P',
        long_desc: 'THUNZINI',
      },
      {
        id: 35365,
        inkhundla_code: '049',
        business_property: 'P',
        long_desc: 'HOLNECK',
      },
      {
        id: 35366,
        inkhundla_code: '047',
        business_property: 'P',
        long_desc: 'KUBUTA FARM',
      },
      {
        id: 35367,
        inkhundla_code: '059',
        business_property: 'P',
        long_desc: 'THUNZINI FARM',
      },
      {
        id: 35368,
        inkhundla_code: '049',
        business_property: 'P',
        long_desc: 'HOLNECK',
      },
      {
        id: 35369,
        inkhundla_code: '044',
        business_property: 'P',
        long_desc: 'MKHAYA GAME RESERVE',
      },
      {
        id: 35370,
        inkhundla_code: '045',
        business_property: 'P',
        long_desc: 'GEGE',
      },
      {
        id: 35371,
        inkhundla_code: '049',
        business_property: 'P',
        long_desc: 'MAHAMBA BORDER',
      },
      {
        id: 35372,
        inkhundla_code: '050',
        business_property: 'P',
        long_desc: 'NSALITJE',
      },
      {
        id: 35373,
        inkhundla_code: '058',
        business_property: 'P',
        long_desc: 'JOZINI',
      },
      {
        id: 35374,
        inkhundla_code: '049',
        business_property: 'P',
        long_desc: 'UPPER NYAMANE',
      },
      {
        id: 35375,
        inkhundla_code: '057',
        business_property: 'P',
        long_desc: 'MALOMA FARM',
      },
      {
        id: 35376,
        inkhundla_code: '049',
        business_property: 'P',
        long_desc: 'NZONGOMANE',
      },
      {
        id: 35377,
        inkhundla_code: '054',
        business_property: 'R',
        long_desc: 'MPOMPOTHA',
      },
      {
        id: 38137,
        inkhundla_code: '019',
        business_property: 'R',
        long_desc: 'EKUFINYENI',
      },
      {
        id: 40000,
        inkhundla_code: '000',
        business_property: 'T',
        long_desc: 'LUBOMBO REGION',
      },
      {
        id: 40001,
        inkhundla_code: '039',
        business_property: 'U',
        long_desc: 'SITEKI TOWN CENTRE',
      },
      {
        id: 40002,
        inkhundla_code: '039',
        business_property: 'U',
        long_desc: 'SITEKI MARKET',
      },
      {
        id: 40003,
        inkhundla_code: '039',
        business_property: 'U',
        long_desc: 'SITEKI INDUSTRIAL',
      },
      {
        id: 40004,
        inkhundla_code: '039',
        business_property: 'U',
        long_desc: 'SITEKI TOWNSHIP',
      },
      {
        id: 40005,
        inkhundla_code: '039',
        business_property: 'U',
        long_desc: 'SITEKI IMVELO TOWNSHIP',
      },
      {
        id: 40006,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'SITSATSAWENI',
      },
      {
        id: 40007,
        inkhundla_code: '039',
        business_property: 'U',
        long_desc: 'SITEKI MPUMALANGA TOWNSHIP',
      },
      {
        id: 40008,
        inkhundla_code: '039',
        business_property: 'U',
        long_desc: 'SITEKI FLAME TREE PARK',
      },
      {
        id: 40009,
        inkhundla_code: '039',
        business_property: 'P',
        long_desc: 'GOODSHEPHERD MISSION',
      },
      {
        id: 40010,
        inkhundla_code: '039',
        business_property: 'P',
        long_desc: 'SITEKI NAZARINE MISSION',
      },
      {
        id: 40011,
        inkhundla_code: '035',
        business_property: 'U',
        long_desc: 'BIG BEND',
      },
      {
        id: 40012,
        inkhundla_code: '035',
        business_property: 'U',
        long_desc: 'BIG BEND BUS RANK',
      },
      {
        id: 40013,
        inkhundla_code: '035',
        business_property: 'U',
        long_desc: 'BIG BEND MILLSITE',
      },
      {
        id: 40014,
        inkhundla_code: '035',
        business_property: 'U',
        long_desc: 'BIG BEND MAYALUKA',
      },
      {
        id: 40015,
        inkhundla_code: '035',
        business_property: 'U',
        long_desc: 'BIG BEND MAIN VILLAGE',
      },
      {
        id: 40016,
        inkhundla_code: '035',
        business_property: 'U',
        long_desc: 'SIVUNGA',
      },
      {
        id: 40017,
        inkhundla_code: '035',
        business_property: 'U',
        long_desc: 'MABLOKWENI',
      },
      {
        id: 40018,
        inkhundla_code: '035',
        business_property: 'U',
        long_desc: 'LOGO POOLS',
      },
      {
        id: 40019,
        inkhundla_code: '035',
        business_property: 'U',
        long_desc: 'BIG BEND TJELANE VILLAGE',
      },
      {
        id: 40020,
        inkhundla_code: '035',
        business_property: 'U',
        long_desc: 'BIG BEND MAJOMBE VILLAGE',
      },
      {
        id: 40021,
        inkhundla_code: '035',
        business_property: 'U',
        long_desc: 'BIG BEND SANGWALUMA VILLAGE',
      },
      {
        id: 40022,
        inkhundla_code: '035',
        business_property: 'U',
        long_desc: 'BIG BEND TRACTOR POOL',
      },
      {
        id: 40023,
        inkhundla_code: '035',
        business_property: 'U',
        long_desc: 'BIG BEND CANE TRANSPORT',
      },
      {
        id: 40024,
        inkhundla_code: '035',
        business_property: 'U',
        long_desc: 'BIG BEND MAPAYPINI',
      },
      {
        id: 40025,
        inkhundla_code: '035',
        business_property: 'U',
        long_desc: 'BIG BEND SHONALANGA VILLAGE',
      },
      {
        id: 40026,
        inkhundla_code: '035',
        business_property: 'U',
        long_desc: 'BIG BEND NYETANE',
      },
      {
        id: 40027,
        inkhundla_code: '035',
        business_property: 'U',
        long_desc: 'BIG BEND USUTHU',
      },
      {
        id: 40028,
        inkhundla_code: '035',
        business_property: 'U',
        long_desc: 'BIG BEND NGOGO',
      },
      {
        id: 40029,
        inkhundla_code: '035',
        business_property: 'U',
        long_desc: 'BIG BEND COKWANE VILLAGE',
      },
      {
        id: 40030,
        inkhundla_code: '035',
        business_property: 'U',
        long_desc: 'BIG BEND SECTION',
      },
      {
        id: 40031,
        inkhundla_code: '041',
        business_property: 'U',
        long_desc: 'SIMUNYE',
      },
      {
        id: 40032,
        inkhundla_code: '041',
        business_property: 'U',
        long_desc: 'SIMUNYE PLAZA',
      },
      {
        id: 40033,
        inkhundla_code: '041',
        business_property: 'U',
        long_desc: 'SIMUNYE MARKET',
      },
      {
        id: 40034,
        inkhundla_code: '041',
        business_property: 'U',
        long_desc: 'SIMUNYE MILLSITE',
      },
      {
        id: 40035,
        inkhundla_code: '041',
        business_property: 'U',
        long_desc: 'SIMUNYE LUSOTI',
      },
      {
        id: 40036,
        inkhundla_code: '041',
        business_property: 'P',
        long_desc: 'NGOMANE',
      },
      {
        id: 40037,
        inkhundla_code: '041',
        business_property: 'U',
        long_desc: 'MHLUME',
      },
      {
        id: 40038,
        inkhundla_code: '041',
        business_property: 'U',
        long_desc: 'MHLUME HAMBANATHI',
      },
      {
        id: 40039,
        inkhundla_code: '041',
        business_property: 'U',
        long_desc: 'MHLUME LOHHEYA VILLAGE',
      },
      {
        id: 40040,
        inkhundla_code: '041',
        business_property: 'U',
        long_desc: 'MHLUME LOMKHEHLE VILLAGE',
      },
      {
        id: 40041,
        inkhundla_code: '041',
        business_property: 'U',
        long_desc: 'MHLUME NDLAVELA VILLAGE',
      },
      {
        id: 40042,
        inkhundla_code: '041',
        business_property: 'U',
        long_desc: 'TSHANENI',
      },
      {
        id: 40043,
        inkhundla_code: '041',
        business_property: 'U',
        long_desc: 'MHLUME SECTION 1',
      },
      {
        id: 40044,
        inkhundla_code: '041',
        business_property: 'U',
        long_desc: 'MHLUME SECTION 13',
      },
      {
        id: 40045,
        inkhundla_code: '041',
        business_property: 'U',
        long_desc: 'MHLUME SECTION 14',
      },
      {
        id: 40046,
        inkhundla_code: '041',
        business_property: 'U',
        long_desc: 'MHLUME SECTION 15',
      },
      {
        id: 40047,
        inkhundla_code: '041',
        business_property: 'U',
        long_desc: 'MHLUME SECTION 16',
      },
      {
        id: 40048,
        inkhundla_code: '041',
        business_property: 'U',
        long_desc: 'MHLUME SECTION 17',
      },
      {
        id: 40049,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'KALANGA',
      },
      {
        id: 40050,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'LOKHAYIZA',
      },
      {
        id: 40051,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'LUKHULA',
      },
      {
        id: 40052,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'MAWELELA',
      },
      {
        id: 40053,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'EMALONTWENI',
      },
      {
        id: 40054,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'MVUNTJINI',
      },
      {
        id: 40055,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'KAMADLIZA',
      },
      {
        id: 40056,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'ZAKASA',
      },
      {
        id: 40057,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'MAGUGU',
      },
      {
        id: 40058,
        inkhundla_code: '041',
        business_property: 'U',
        long_desc: 'MHLUME POST OFFICE',
      },
      {
        id: 40059,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'MAPHATSINDVUKU',
      },
      {
        id: 40061,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'LUGONGOLWENI',
      },
      {
        id: 40062,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'MOYENI',
      },
      {
        id: 40063,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'MZILIKAZI',
      },
      {
        id: 40064,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'MHLUMENI',
      },
      {
        id: 40065,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'MLINDAZWE',
      },
      {
        id: 40066,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'MAKHEWU',
      },
      {
        id: 40067,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'NTAMAKUPHILA',
      },
      {
        id: 40068,
        inkhundla_code: '042',
        business_property: 'R',
        long_desc: 'MPOLONJENI',
      },
      {
        id: 40069,
        inkhundla_code: '042',
        business_property: 'R',
        long_desc: 'HLANGOTI',
      },
      {
        id: 40070,
        inkhundla_code: '042',
        business_property: 'R',
        long_desc: 'MKHUTSHANE',
      },
      {
        id: 40071,
        inkhundla_code: '042',
        business_property: 'R',
        long_desc: 'BHUMANENI',
      },
      {
        id: 40072,
        inkhundla_code: '042',
        business_property: 'R',
        long_desc: 'NYETANE',
      },
      {
        id: 40073,
        inkhundla_code: '042',
        business_property: 'R',
        long_desc: 'KASHOBA',
      },
      {
        id: 40074,
        inkhundla_code: '042',
        business_property: 'R',
        long_desc: 'MFOBOBO',
      },
      {
        id: 40075,
        inkhundla_code: '042',
        business_property: 'R',
        long_desc: 'BHELEBANE',
      },
      {
        id: 40076,
        inkhundla_code: '042',
        business_property: 'R',
        long_desc: 'JABULA',
      },
      {
        id: 40077,
        inkhundla_code: '036',
        business_property: 'R',
        long_desc: 'MALINDZA',
      },
      {
        id: 40078,
        inkhundla_code: '036',
        business_property: 'R',
        long_desc: 'HIGHWAY',
      },
      {
        id: 40079,
        inkhundla_code: '036',
        business_property: 'R',
        long_desc: 'SIWENI',
      },
      {
        id: 40080,
        inkhundla_code: '036',
        business_property: 'R',
        long_desc: 'NTANDWENI',
      },
      {
        id: 40081,
        inkhundla_code: '036',
        business_property: 'R',
        long_desc: 'EKHUSHWENI',
      },
      {
        id: 40082,
        inkhundla_code: '036',
        business_property: 'R',
        long_desc: 'ELAWENI',
      },
      {
        id: 40083,
        inkhundla_code: '036',
        business_property: 'R',
        long_desc: 'MBADLANE',
      },
      {
        id: 40084,
        inkhundla_code: '036',
        business_property: 'R',
        long_desc: 'MFELAFUTSI',
      },
      {
        id: 40085,
        inkhundla_code: '036',
        business_property: 'R',
        long_desc: 'MPAKA',
      },
      {
        id: 40086,
        inkhundla_code: '036',
        business_property: 'R',
        long_desc: 'ESIKOLWENI',
      },
      {
        id: 40087,
        inkhundla_code: '036',
        business_property: 'R',
        long_desc: 'EMANYEVENI',
      },
      {
        id: 40088,
        inkhundla_code: '036',
        business_property: 'R',
        long_desc: 'COAL MINE',
      },
      {
        id: 40089,
        inkhundla_code: '036',
        business_property: 'R',
        long_desc: 'LUBHUKU',
      },
      {
        id: 40090,
        inkhundla_code: '036',
        business_property: 'R',
        long_desc: 'RAILWAY',
      },
      {
        id: 40091,
        inkhundla_code: '036',
        business_property: 'R',
        long_desc: 'SIKHUPHE',
      },
      {
        id: 40092,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'SHEWULA',
      },
      {
        id: 40093,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'EMNYAMATSINI',
      },
      {
        id: 40094,
        inkhundla_code: '044',
        business_property: 'R',
        long_desc: 'EKUTHULENI',
      },
      {
        id: 40095,
        inkhundla_code: '044',
        business_property: 'R',
        long_desc: 'MHLATUZE',
      },
      {
        id: 40096,
        inkhundla_code: '044',
        business_property: 'R',
        long_desc: 'ST. PHILLIPS',
      },
      {
        id: 40097,
        inkhundla_code: '035',
        business_property: 'R',
        long_desc: 'ENGONI',
      },
      {
        id: 40098,
        inkhundla_code: '035',
        business_property: 'R',
        long_desc: 'MNDOBANDOBA',
      },
      {
        id: 40099,
        inkhundla_code: '035',
        business_property: 'R',
        long_desc: 'MAKHULUSIHLENGE',
      },
      {
        id: 40100,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'KAKHUPHUKA',
      },
      {
        id: 40101,
        inkhundla_code: '035',
        business_property: 'R',
        long_desc: 'MAHLABANENI',
      },
      {
        id: 40102,
        inkhundla_code: '035',
        business_property: 'R',
        long_desc: 'MAHLABATSINI',
      },
      {
        id: 40103,
        inkhundla_code: '044',
        business_property: 'R',
        long_desc: 'PHUZAMOYA',
      },
      {
        id: 40104,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'MAMBANE',
      },
      {
        id: 40105,
        inkhundla_code: '036',
        business_property: 'R',
        long_desc: 'MDUMEZULU',
      },
      {
        id: 40106,
        inkhundla_code: '036',
        business_property: 'R',
        long_desc: 'THULWANE',
      },
      {
        id: 40107,
        inkhundla_code: '041',
        business_property: 'R',
        long_desc: 'MAFUCULA',
      },
      {
        id: 40108,
        inkhundla_code: '042',
        business_property: 'R',
        long_desc: 'NGCINA',
      },
      {
        id: 40109,
        inkhundla_code: '037',
        business_property: 'R',
        long_desc: 'DUZE',
      },
      {
        id: 40110,
        inkhundla_code: '044',
        business_property: 'R',
        long_desc: 'MKHAYA',
      },
      {
        id: 40111,
        inkhundla_code: '043',
        business_property: 'R',
        long_desc: 'KAMAMBA',
      },
      {
        id: 40112,
        inkhundla_code: '043',
        business_property: 'R',
        long_desc: 'EMBABALA',
      },
      {
        id: 40113,
        inkhundla_code: '043',
        business_property: 'R',
        long_desc: 'KAMAMISA',
      },
      {
        id: 40114,
        inkhundla_code: '044',
        business_property: 'R',
        long_desc: 'MAPHILINGO',
      },
      {
        id: 40115,
        inkhundla_code: '034',
        business_property: 'R',
        long_desc: 'MAKHWEKHWETI',
      },
      {
        id: 40116,
        inkhundla_code: '043',
        business_property: 'R',
        long_desc: 'SITHOBELA',
      },
      {
        id: 40117,
        inkhundla_code: '043',
        business_property: 'R',
        long_desc: 'GUCUKA',
      },
      {
        id: 40118,
        inkhundla_code: '043',
        business_property: 'R',
        long_desc: 'MGANGADVU',
      },
      {
        id: 40119,
        inkhundla_code: '037',
        business_property: 'R',
        long_desc: 'HLUTSE',
      },
      {
        id: 40120,
        inkhundla_code: '035',
        business_property: 'R',
        long_desc: 'NDZEVANE',
      },
      {
        id: 40121,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'HHUNWANE',
      },
      {
        id: 40122,
        inkhundla_code: '034',
        business_property: 'R',
        long_desc: 'MALOMA',
      },
      {
        id: 40123,
        inkhundla_code: '035',
        business_property: 'R',
        long_desc: 'NKILONGO',
      },
      {
        id: 40124,
        inkhundla_code: '037',
        business_property: 'R',
        long_desc: 'MKHUZWENI',
      },
      {
        id: 40125,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'MAYENJANE',
      },
      {
        id: 40126,
        inkhundla_code: '042',
        business_property: 'R',
        long_desc: 'KANZANGU',
      },
      {
        id: 40127,
        inkhundla_code: '037',
        business_property: 'R',
        long_desc: 'PHONJWANE',
      },
      {
        id: 40128,
        inkhundla_code: '037',
        business_property: 'R',
        long_desc: 'MPHOSI',
      },
      {
        id: 40129,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'MAHHUKU',
      },
      {
        id: 40130,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MAHEMBE',
      },
      {
        id: 40131,
        inkhundla_code: '044',
        business_property: 'R',
        long_desc: 'MANDLOVU',
      },
      {
        id: 40132,
        inkhundla_code: '037',
        business_property: 'R',
        long_desc: 'MFUNTANENI',
      },
      {
        id: 40133,
        inkhundla_code: '037',
        business_property: 'R',
        long_desc: 'MACETJENI',
      },
      {
        id: 40134,
        inkhundla_code: '044',
        business_property: 'R',
        long_desc: 'LAVUNDLAMANTI',
      },
      {
        id: 40135,
        inkhundla_code: '043',
        business_property: 'R',
        long_desc: 'SIHLANGWINI',
      },
      {
        id: 40136,
        inkhundla_code: '043',
        business_property: 'R',
        long_desc: 'MNCONCWANE',
      },
      {
        id: 40137,
        inkhundla_code: '037',
        business_property: 'R',
        long_desc: 'GILGAL',
      },
      {
        id: 40138,
        inkhundla_code: '041',
        business_property: 'R',
        long_desc: 'THUNZINI',
      },
      {
        id: 40139,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'LUKHETSENI',
      },
      {
        id: 40140,
        inkhundla_code: '043',
        business_property: 'R',
        long_desc: 'PHOPHONYANE',
      },
      {
        id: 40141,
        inkhundla_code: '043',
        business_property: 'R',
        long_desc: 'MADVUBENI',
      },
      {
        id: 40142,
        inkhundla_code: '036',
        business_property: 'R',
        long_desc: 'HLANE',
      },
      {
        id: 40143,
        inkhundla_code: '036',
        business_property: 'R',
        long_desc: 'NJOTJANE',
      },
      {
        id: 40144,
        inkhundla_code: '036',
        business_property: 'R',
        long_desc: 'SIPHAMBANWENI',
      },
      {
        id: 40145,
        inkhundla_code: '044',
        business_property: 'R',
        long_desc: 'MKHWELI',
      },
      {
        id: 40146,
        inkhundla_code: '043',
        business_property: 'R',
        long_desc: 'NKONJWA',
      },
      {
        id: 40147,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'ESIGODZINI',
      },
      {
        id: 40148,
        inkhundla_code: '042',
        business_property: 'R',
        long_desc: 'MAGWANYANE',
      },
      {
        id: 40149,
        inkhundla_code: '044',
        business_property: 'P',
        long_desc: 'TAMBUTI ESTATE',
      },
      {
        id: 40150,
        inkhundla_code: '044',
        business_property: 'R',
        long_desc: 'MANYOVU',
      },
      {
        id: 40151,
        inkhundla_code: '044',
        business_property: 'R',
        long_desc: 'EMABHUDLWENI',
      },
      {
        id: 40152,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'EMAHAWINI',
      },
      {
        id: 40153,
        inkhundla_code: '034',
        business_property: 'R',
        long_desc: 'EMACHIBINI',
      },
      {
        id: 40154,
        inkhundla_code: '034',
        business_property: 'R',
        long_desc: 'EKHWEZI',
      },
      {
        id: 40155,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'EHHUKWINI',
      },
      {
        id: 40156,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MTIMBILI',
      },
      {
        id: 40157,
        inkhundla_code: '044',
        business_property: 'R',
        long_desc: 'MPHAPHATI',
      },
      {
        id: 40158,
        inkhundla_code: '043',
        business_property: 'R',
        long_desc: 'SITSENGETSENGENI',
      },
      {
        id: 40159,
        inkhundla_code: '034',
        business_property: 'R',
        long_desc: 'MBUTFU',
      },
      {
        id: 40160,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'NYAMAZANE',
      },
      {
        id: 40161,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'EMHLABENI',
      },
      {
        id: 40162,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'ESIHLANJENI',
      },
      {
        id: 40163,
        inkhundla_code: '037',
        business_property: 'R',
        long_desc: 'EMHLOSHENI',
      },
      {
        id: 40164,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'MATSANJENI',
      },
      {
        id: 40165,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'TIKHUBA',
      },
      {
        id: 40166,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'MAVUBETSE',
      },
      {
        id: 40167,
        inkhundla_code: '000',
        business_property: 'P',
        long_desc: 'MATJENTIMA FRM 161',
      },
      {
        id: 40168,
        inkhundla_code: '047',
        business_property: 'R',
        long_desc: 'KHUBUTHA',
      },
      {
        id: 40169,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'MBASHENI',
      },
      {
        id: 40170,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MABUBULA',
      },
      {
        id: 40171,
        inkhundla_code: '037',
        business_property: 'R',
        long_desc: 'SIGCAWENI',
      },
      {
        id: 40172,
        inkhundla_code: '044',
        business_property: 'R',
        long_desc: 'KANGCAMPHALALA',
      },
      {
        id: 40173,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'MNJOLI',
      },
      {
        id: 40174,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'ELUHWAHWENI',
      },
      {
        id: 40175,
        inkhundla_code: '035',
        business_property: 'R',
        long_desc: 'GAMULA',
      },
      {
        id: 40176,
        inkhundla_code: '021',
        business_property: 'P',
        long_desc: 'NGOGOLA FARM',
      },
      {
        id: 40177,
        inkhundla_code: '034',
        business_property: 'R',
        long_desc: 'ENSUBANE',
      },
      {
        id: 40178,
        inkhundla_code: '043',
        business_property: 'R',
        long_desc: 'MPOPHOTHA',
      },
      {
        id: 40179,
        inkhundla_code: '044',
        business_property: 'R',
        long_desc: 'NCEKA',
      },
      {
        id: 40180,
        inkhundla_code: '036',
        business_property: 'R',
        long_desc: 'EMAMPEMPENI',
      },
      {
        id: 40181,
        inkhundla_code: '034',
        business_property: 'R',
        long_desc: 'NTUTHWAKAZI',
      },
      {
        id: 40182,
        inkhundla_code: '042',
        business_property: 'U',
        long_desc: 'VUVULANE',
      },
      {
        id: 40183,
        inkhundla_code: '041',
        business_property: 'U',
        long_desc: 'MHLUME NEW NKOPOLO',
      },
      {
        id: 40184,
        inkhundla_code: '041',
        business_property: 'U',
        long_desc: 'MHLUME NPONDWENI VILLAGE',
      },
      {
        id: 40185,
        inkhundla_code: '041',
        business_property: 'U',
        long_desc: 'MHLUME THEMBISA VILLAGE',
      },
      {
        id: 40186,
        inkhundla_code: '041',
        business_property: 'U',
        long_desc: 'MHLUME NEW VILLAGE',
      },
      {
        id: 40187,
        inkhundla_code: '041',
        business_property: 'U',
        long_desc: 'MHLUME THUNZINI VILLAGE',
      },
      {
        id: 40188,
        inkhundla_code: '041',
        business_property: 'U',
        long_desc: 'MHLUME LIVESTOCK VILLAGE',
      },
      {
        id: 40189,
        inkhundla_code: '041',
        business_property: 'P',
        long_desc: 'TAMBANKULU ESTATE',
      },
      {
        id: 40190,
        inkhundla_code: '041',
        business_property: 'P',
        long_desc: 'TAMBANKULU ESTATE MAPHIVENI',
      },
      {
        id: 40191,
        inkhundla_code: '041',
        business_property: 'R',
        long_desc: 'MAPHIVENI',
      },
      {
        id: 40192,
        inkhundla_code: '041',
        business_property: 'R',
        long_desc: 'MLAWULA',
      },
      {
        id: 40193,
        inkhundla_code: '041',
        business_property: 'P',
        long_desc: 'MLAWULA ESTATE',
      },
      {
        id: 40194,
        inkhundla_code: '054',
        business_property: 'P',
        long_desc: 'MATATA',
      },
      {
        id: 40195,
        inkhundla_code: '034',
        business_property: 'R',
        long_desc: 'NSOKO',
      },
      {
        id: 40196,
        inkhundla_code: '035',
        business_property: 'P',
        long_desc: 'RIVERSIDE',
      },
      {
        id: 40197,
        inkhundla_code: '035',
        business_property: 'P',
        long_desc: 'LISMORE FARM',
      },
      {
        id: 40198,
        inkhundla_code: '041',
        business_property: 'R',
        long_desc: 'MANANGA',
      },
      {
        id: 40199,
        inkhundla_code: '041',
        business_property: 'R',
        long_desc: 'MANANGA BORDER',
      },
      {
        id: 40200,
        inkhundla_code: '034',
        business_property: 'R',
        long_desc: 'LUBULI',
      },
      {
        id: 40201,
        inkhundla_code: '034',
        business_property: 'R',
        long_desc: 'LUBULI ENKHUNDLENI',
      },
      {
        id: 40202,
        inkhundla_code: '034',
        business_property: 'R',
        long_desc: 'LUBULI LUDLOLWENI',
      },
      {
        id: 40203,
        inkhundla_code: '034',
        business_property: 'R',
        long_desc: 'LUBULI NGONINI',
      },
      {
        id: 40204,
        inkhundla_code: '034',
        business_property: 'R',
        long_desc: 'LUNKUNTFU',
      },
      {
        id: 40205,
        inkhundla_code: '034',
        business_property: 'R',
        long_desc: 'CROOKS',
      },
      {
        id: 40206,
        inkhundla_code: '043',
        business_property: 'R',
        long_desc: 'ELUHLANYENI',
      },
      {
        id: 40207,
        inkhundla_code: '035',
        business_property: 'R',
        long_desc: 'NKHANINI',
      },
      {
        id: 40208,
        inkhundla_code: '035',
        business_property: 'R',
        long_desc: 'CANTERBURY',
      },
      {
        id: 40209,
        inkhundla_code: '043',
        business_property: 'R',
        long_desc: 'ENJABULWENI',
      },
      {
        id: 40210,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MHLANGATANE',
      },
      {
        id: 40211,
        inkhundla_code: '037',
        business_property: 'R',
        long_desc: 'VIKIZIJULA',
      },
      {
        id: 40212,
        inkhundla_code: '044',
        business_property: 'R',
        long_desc: 'MADLENYA',
      },
      {
        id: 40213,
        inkhundla_code: '044',
        business_property: 'R',
        long_desc: 'MPHUMAKUDZE',
      },
      {
        id: 40214,
        inkhundla_code: '044',
        business_property: 'R',
        long_desc: 'ENGEVINI',
      },
      {
        id: 40215,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'EMANCUMANENI',
      },
      {
        id: 40216,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'EMANZIMNYAMA',
      },
      {
        id: 40217,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'SIKHONKHWANENI',
      },
      {
        id: 40218,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'MGANWINI',
      },
      {
        id: 40219,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'ESIKHONYANENI',
      },
      {
        id: 40220,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'ENGAZINI',
      },
      {
        id: 40221,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'MADZALANE',
      },
      {
        id: 40222,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'NDZAWENI',
      },
      {
        id: 40223,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'SHEWULA BUSINESS CENTRE',
      },
      {
        id: 40224,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'KUMNISI',
      },
      {
        id: 40225,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'KHUTSALA',
      },
      {
        id: 40226,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'MONGOLENI',
      },
      {
        id: 40227,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'MAGONGOLWENI',
      },
      {
        id: 40228,
        inkhundla_code: '044',
        business_property: 'R',
        long_desc: 'PHULAMCASHI',
      },
      {
        id: 40229,
        inkhundla_code: '037',
        business_property: 'R',
        long_desc: 'BULUNGA',
      },
      {
        id: 40230,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'TIMPHISINI',
      },
      {
        id: 40231,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'EMALAYININI',
      },
      {
        id: 40232,
        inkhundla_code: '044',
        business_property: 'R',
        long_desc: 'SIBETSAPHI',
      },
      {
        id: 40233,
        inkhundla_code: '044',
        business_property: 'R',
        long_desc: 'LOGOBA',
      },
      {
        id: 40234,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MATFUNTINI',
      },
      {
        id: 40235,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'DVUMANENI',
      },
      {
        id: 40236,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'HHALANE',
      },
      {
        id: 40237,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'MSOKWENI',
      },
      {
        id: 40238,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'MBOKOJWENI',
      },
      {
        id: 40239,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'LOMAHASHA BORDER',
      },
      {
        id: 40240,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'MAKHONDVOLWANE',
      },
      {
        id: 40241,
        inkhundla_code: '035',
        business_property: 'R',
        long_desc: 'EMADABUKENI',
      },
      {
        id: 40242,
        inkhundla_code: '044',
        business_property: 'R',
        long_desc: 'LUSUTFU',
      },
      {
        id: 40243,
        inkhundla_code: '035',
        business_property: 'R',
        long_desc: 'GAME 5',
      },
      {
        id: 40244,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'KAZULU',
      },
      {
        id: 40245,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'MNYANGOMBILI',
      },
      {
        id: 40246,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'KULEMHLOPHE',
      },
      {
        id: 40247,
        inkhundla_code: '043',
        business_property: 'R',
        long_desc: 'SICILWENI',
      },
      {
        id: 40248,
        inkhundla_code: '043',
        business_property: 'R',
        long_desc: 'ETHEMBA',
      },
      {
        id: 40249,
        inkhundla_code: '043',
        business_property: 'R',
        long_desc: 'SINCENI',
      },
      {
        id: 40250,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'KHALAMBAZO',
      },
      {
        id: 40251,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'EKUKHANYENI',
      },
      {
        id: 40252,
        inkhundla_code: '043',
        business_property: 'R',
        long_desc: 'LUBALANE',
      },
      {
        id: 40253,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'MKOBENI',
      },
      {
        id: 40254,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'BHULWAKO',
      },
      {
        id: 40255,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'LUCACENI',
      },
      {
        id: 40256,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'ESIHLAHLENI',
      },
      {
        id: 40257,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'SIBOVINI',
      },
      {
        id: 40258,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'MANHLEKE',
      },
      {
        id: 40259,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'MVUDLANE',
      },
      {
        id: 40260,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MBONDVWENI',
      },
      {
        id: 40261,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'EMLANGANE',
      },
      {
        id: 40262,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'SIJAKWENI',
      },
      {
        id: 40263,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'TSHOKO',
      },
      {
        id: 40264,
        inkhundla_code: '037',
        business_property: 'R',
        long_desc: 'ETIMBUTINI',
      },
      {
        id: 40265,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'NGCAMINI',
      },
      {
        id: 40266,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'NKOLONGWANE',
      },
      {
        id: 40267,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'KABENI',
      },
      {
        id: 40268,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'KAKHUPHUKA ESIKOLWENI',
      },
      {
        id: 40269,
        inkhundla_code: '042',
        business_property: 'R',
        long_desc: 'KATHOKOZA',
      },
      {
        id: 40270,
        inkhundla_code: '042',
        business_property: 'R',
        long_desc: 'KASHOBA MANTJOLINI',
      },
      {
        id: 40271,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'ENGULUBENI',
      },
      {
        id: 40272,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'SINEYINI',
      },
      {
        id: 40273,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'MANGWENYA',
      },
      {
        id: 40274,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'NCUMATSINI',
      },
      {
        id: 40275,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'KHALAKAHLE',
      },
      {
        id: 40276,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'LOMAHASHA MISSION',
      },
      {
        id: 40277,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'EMAKHUNDLU',
      },
      {
        id: 40278,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'MBUZANA',
      },
      {
        id: 40279,
        inkhundla_code: '037',
        business_property: 'R',
        long_desc: 'EMZIMNENE',
      },
      {
        id: 40280,
        inkhundla_code: '036',
        business_property: 'R',
        long_desc: 'MAPHAKANE',
      },
      {
        id: 40281,
        inkhundla_code: '036',
        business_property: 'R',
        long_desc: 'MBUBE',
      },
      {
        id: 40282,
        inkhundla_code: '036',
        business_property: 'R',
        long_desc: 'NSULUTANE',
      },
      {
        id: 40283,
        inkhundla_code: '036',
        business_property: 'R',
        long_desc: 'ELUBHIJANENI',
      },
      {
        id: 40284,
        inkhundla_code: '042',
        business_property: 'R',
        long_desc: 'MPOLONJENI ENKHUNDLENI',
      },
      {
        id: 40285,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'KAMATFOLE',
      },
      {
        id: 40286,
        inkhundla_code: '044',
        business_property: 'R',
        long_desc: 'MKHOKHI',
      },
      {
        id: 40287,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MGWENYABONGA',
      },
      {
        id: 40288,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'EMGAMBENI',
      },
      {
        id: 40289,
        inkhundla_code: '043',
        business_property: 'R',
        long_desc: 'EMAHHOSHE',
      },
      {
        id: 40290,
        inkhundla_code: '043',
        business_property: 'R',
        long_desc: 'EMANTINI',
      },
      {
        id: 40291,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'EGEBENI',
      },
      {
        id: 40292,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'EBHANDENI',
      },
      {
        id: 40293,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'NOKWANE',
      },
      {
        id: 40294,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'SIBHOTELENI',
      },
      {
        id: 40295,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'MATSANJENI ST JOHNS',
      },
      {
        id: 40296,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'ESIKHALENI',
      },
      {
        id: 40297,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'KUMANDZE',
      },
      {
        id: 40298,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'ETITFUTFWANENI',
      },
      {
        id: 40299,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MAHLANGWINI',
      },
      {
        id: 40300,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'EMGUNGUNDLOVU',
      },
      {
        id: 40301,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'KWAXWENI',
      },
      {
        id: 40302,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'EGEGEBINI',
      },
      {
        id: 40303,
        inkhundla_code: '009',
        business_property: 'R',
        long_desc: 'MADZALENI',
      },
      {
        id: 40304,
        inkhundla_code: '042',
        business_property: 'R',
        long_desc: 'EMADADENI',
      },
      {
        id: 40305,
        inkhundla_code: '009',
        business_property: 'R',
        long_desc: 'WELANJANI',
      },
      {
        id: 40306,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'KASHEWULA ECLINIC',
      },
      {
        id: 40307,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'KASHEWULA DIPTANK',
      },
      {
        id: 40308,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'NDUMA RDA',
      },
      {
        id: 40309,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'HWAHWALALA',
      },
      {
        id: 40310,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'EHHUKWANE',
      },
      {
        id: 40311,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'MTFONJENI',
      },
      {
        id: 40312,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'KUMENI',
      },
      {
        id: 40313,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'MPHUNDLE',
      },
      {
        id: 40314,
        inkhundla_code: '009',
        business_property: 'R',
        long_desc: 'MAHLAGWANE',
      },
      {
        id: 40315,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'EZIYONI',
      },
      {
        id: 40316,
        inkhundla_code: '009',
        business_property: 'R',
        long_desc: 'EKUPHELENI',
      },
      {
        id: 40317,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'KUMSHATANE',
      },
      {
        id: 40318,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'NUMBER 6',
      },
      {
        id: 40319,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MGAMBENI',
      },
      {
        id: 40320,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MTHONGA',
      },
      {
        id: 40321,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MBUZANA',
      },
      {
        id: 40322,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MAQHOBENI',
      },
      {
        id: 40323,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'DZINANDVUNA',
      },
      {
        id: 40324,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'ENDONYA',
      },
      {
        id: 40325,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'ENGWALENI',
      },
      {
        id: 40326,
        inkhundla_code: '034',
        business_property: 'R',
        long_desc: 'MABANTANENI',
      },
      {
        id: 40327,
        inkhundla_code: '035',
        business_property: 'R',
        long_desc: 'NCANDVWENI',
      },
      {
        id: 40328,
        inkhundla_code: '044',
        business_property: 'R',
        long_desc: 'SIPHOFANENI',
      },
      {
        id: 40329,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'MAPHUNGWANE',
      },
      {
        id: 40330,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'TINGODVWENI',
      },
      {
        id: 40331,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'SHOLOLO',
      },
      {
        id: 40332,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'KANTULI',
      },
      {
        id: 40333,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'PHALATA',
      },
      {
        id: 40334,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'MHLABUBOVU',
      },
      {
        id: 40335,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'NDLALANE',
      },
      {
        id: 40336,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'ST, PETERS',
      },
      {
        id: 40337,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'MSENGENI',
      },
      {
        id: 40338,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'KAKHUPHUKA LUB',
      },
      {
        id: 40339,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'LOMAHASHA',
      },
      {
        id: 40340,
        inkhundla_code: '042',
        business_property: 'R',
        long_desc: 'KASHOBA FOBOBO',
      },
      {
        id: 40341,
        inkhundla_code: '044',
        business_property: 'R',
        long_desc: 'LUKHETSENI SIPHOFANENI',
      },
      {
        id: 40342,
        inkhundla_code: '044',
        business_property: 'R',
        long_desc: 'SIPHOFANENI ENKHUNDLENI',
      },
      {
        id: 40343,
        inkhundla_code: '044',
        business_property: 'R',
        long_desc: 'MANZANA SIPHOFANENI',
      },
      {
        id: 40344,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'MZILIKAZI CORNER OPP ASSEBLIES',
      },
      {
        id: 40345,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'MATSETSA',
      },
      {
        id: 40346,
        inkhundla_code: '044',
        business_property: 'R',
        long_desc: 'ST.PHILLIPS',
      },
      {
        id: 40347,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'LUBILWENI (KALANGA)',
      },
      {
        id: 40348,
        inkhundla_code: '044',
        business_property: 'R',
        long_desc: 'LANJANE (SIPHOFANENI)',
      },
      {
        id: 40349,
        inkhundla_code: '044',
        business_property: 'R',
        long_desc: 'OTHANDWENI',
      },
      {
        id: 40350,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'MCATHUVANE',
      },
      {
        id: 40351,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'TSAMBOKHULU',
      },
      {
        id: 40352,
        inkhundla_code: '041',
        business_property: 'U',
        long_desc: 'MHLUME MILLSITE',
      },
      {
        id: 40353,
        inkhundla_code: '044',
        business_property: 'R',
        long_desc: 'MAPHOBENI',
      },
      {
        id: 40354,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'NDUMA (SHEWULA)',
      },
      {
        id: 40355,
        inkhundla_code: '037',
        business_property: 'R',
        long_desc: 'MALINDZA,ELAWINI',
      },
      {
        id: 40356,
        inkhundla_code: '039',
        business_property: 'U',
        long_desc: 'LUBOMBO S.G REGIONAL OFFICES',
      },
      {
        id: 40357,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'MKHANGALA (LOMAHASHA)',
      },
      {
        id: 40358,
        inkhundla_code: '036',
        business_property: 'R',
        long_desc: 'KANJOBO (MALINDZA)',
      },
      {
        id: 40359,
        inkhundla_code: '039',
        business_property: 'P',
        long_desc: 'NORTHCLIFF FARM (SITEKI)',
      },
      {
        id: 40360,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'MVUTJINI (KALANGA)',
      },
      {
        id: 40361,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'EMATFUNTINI (LOMAHASHA)',
      },
      {
        id: 40362,
        inkhundla_code: '035',
        business_property: 'U',
        long_desc: 'BIG-BEND BARCIRCLE',
      },
      {
        id: 40363,
        inkhundla_code: '039',
        business_property: 'U',
        long_desc: 'SITEKI (KAMABHODWENI AREA)',
      },
      {
        id: 40364,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'DVUMANE',
      },
      {
        id: 40365,
        inkhundla_code: '041',
        business_property: 'U',
        long_desc: 'VUVULANE MARKET',
      },
      {
        id: 40366,
        inkhundla_code: '039',
        business_property: 'U',
        long_desc: 'SITEKI NEW MARKET',
      },
      {
        id: 40367,
        inkhundla_code: '035',
        business_property: 'P',
        long_desc: 'BIG BEND LISMORE FARM',
      },
      {
        id: 40368,
        inkhundla_code: '041',
        business_property: 'R',
        long_desc: 'SIHHOYE (LUBOMBO PART)',
      },
      {
        id: 40369,
        inkhundla_code: '035',
        business_property: 'P',
        long_desc: 'BUSHLANDS FARM',
      },
      {
        id: 40370,
        inkhundla_code: '036',
        business_property: 'R',
        long_desc: 'MPAKA FACTORYSHELL',
      },
      {
        id: 40371,
        inkhundla_code: '034',
        business_property: 'R',
        long_desc: 'SINYAMANTULWA',
      },
      {
        id: 40372,
        inkhundla_code: '041',
        business_property: 'P',
        long_desc: 'IMPALA ARMS (TSHANENI)',
      },
      {
        id: 40373,
        inkhundla_code: '035',
        business_property: 'P',
        long_desc: 'BIG BEND (SCIOLLAS)',
      },
      {
        id: 40374,
        inkhundla_code: '036',
        business_property: 'R',
        long_desc: 'MPAKA RAILWAY',
      },
      {
        id: 40375,
        inkhundla_code: '042',
        business_property: 'R',
        long_desc: 'MPOLONJENI (NYETANE)',
      },
      {
        id: 40376,
        inkhundla_code: '034',
        business_property: 'P',
        long_desc: 'NISELA FARMS (NSOKO)',
      },
      {
        id: 40377,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'MANGOLENI (KALANGA)',
      },
      {
        id: 40378,
        inkhundla_code: '035',
        business_property: 'U',
        long_desc: 'BIG BEND LOGO POOLS',
      },
      {
        id: 40379,
        inkhundla_code: '035',
        business_property: 'U',
        long_desc: 'BIG BEND HLANDZE TOWNSHIP',
      },
      {
        id: 40380,
        inkhundla_code: '039',
        business_property: 'P',
        long_desc: 'SITEKI FARM 229 (KADANI)',
      },
      {
        id: 40381,
        inkhundla_code: '035',
        business_property: 'P',
        long_desc: 'NSOKO FARM',
      },
      {
        id: 40382,
        inkhundla_code: '036',
        business_property: 'R',
        long_desc: 'GEGEBINI(MALINDZA)',
      },
      {
        id: 40383,
        inkhundla_code: '039',
        business_property: 'P',
        long_desc: 'MABUDA FARM',
      },
      {
        id: 40384,
        inkhundla_code: '036',
        business_property: 'R',
        long_desc: 'DVOKODVWENI',
      },
      {
        id: 40385,
        inkhundla_code: '035',
        business_property: 'P',
        long_desc: 'MALOMA FARM',
      },
      {
        id: 40386,
        inkhundla_code: '040',
        business_property: 'R',
        long_desc: 'HHUKWANE',
      },
      {
        id: 40387,
        inkhundla_code: '039',
        business_property: 'P',
        long_desc: 'MHLUMENI FARM',
      },
      {
        id: 40388,
        inkhundla_code: '038',
        business_property: 'R',
        long_desc: 'MANGWENI',
      },
      {
        id: 40389,
        inkhundla_code: '035',
        business_property: 'P',
        long_desc: 'BIG BEND HLANZENI FARM',
      },
      {
        id: 40390,
        inkhundla_code: '041',
        business_property: 'P',
        long_desc: 'MANANGA',
      },
      {
        id: 40391,
        inkhundla_code: '035',
        business_property: 'U',
        long_desc: 'MAYALUKA LOCATION',
      },
      {
        id: 40392,
        inkhundla_code: '041',
        business_property: 'P',
        long_desc: 'TSHANENI',
      },
      {
        id: 40393,
        inkhundla_code: '041',
        business_property: 'P',
        long_desc: 'CANTERBURY',
      },
      {
        id: 40394,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'EMATJENI',
      },
      {
        id: 40395,
        inkhundla_code: '035',
        business_property: 'P',
        long_desc: 'ENTSABENI PROPERTIES',
      },
      {
        id: 40396,
        inkhundla_code: '039',
        business_property: 'P',
        long_desc: 'MZILIKAZI',
      },
      {
        id: 40397,
        inkhundla_code: '039',
        business_property: 'P',
        long_desc: 'MZILIKAZI',
      },
      {
        id: 40398,
        inkhundla_code: '044',
        business_property: 'P',
        long_desc: 'PHUZUMOYA',
      },
      {
        id: 40399,
        inkhundla_code: '044',
        business_property: 'P',
        long_desc: 'MKHAYA GAME RESERVE',
      },
      {
        id: 40400,
        inkhundla_code: '039',
        business_property: 'P',
        long_desc: 'MNJOLI',
      },
      {
        id: 40401,
        inkhundla_code: '044',
        business_property: 'P',
        long_desc: 'ST. PHILLIPS',
      },
      {
        id: 40402,
        inkhundla_code: '035',
        business_property: 'P',
        long_desc: 'BIG BEND FARM PICARDRE',
      },
      {
        id: 40403,
        inkhundla_code: '039',
        business_property: 'P',
        long_desc: 'NOTCLIFFE FARM',
      },
      {
        id: 40404,
        inkhundla_code: '036',
        business_property: 'S',
        long_desc: 'SIKHUPHE',
      },
      {
        id: 40405,
        inkhundla_code: '034',
        business_property: 'R',
        long_desc: 'LUBULINI',
      },
      {
        id: 40406,
        inkhundla_code: '044',
        business_property: 'P',
        long_desc: 'ST PHILIPS',
      },
      {
        id: 53069,
        inkhundla_code: '052',
        business_property: 'R',
        long_desc: 'NTSHANINI',
      },
      {
        id: 53078,
        inkhundla_code: '051',
        business_property: 'R',
        long_desc: 'MAGELE',
      },
      {
        id: 53084,
        inkhundla_code: '052',
        business_property: 'R',
        long_desc: 'NZAMEYA',
      },
      {
        id: 53085,
        inkhundla_code: '054',
        business_property: 'P',
        long_desc: 'SANDLENI (FARM 1094)',
      },
      {
        id: 53086,
        inkhundla_code: '039',
        business_property: 'R',
        long_desc: 'LONHLUPHEKO',
      },
      {
        id: 60000,
        inkhundla_code: '029',
        business_property: 'R',
        long_desc: 'VELEZIZWENI/ETHANGENI',
      },
      {
        id: 60001,
        inkhundla_code: '033',
        business_property: 'R',
        long_desc: 'LUSHIKISHINI',
      },
      {
        id: 60002,
        inkhundla_code: '029',
        business_property: 'R',
        long_desc: 'HOLY ROSARY COMMUNITY/MANKAYANE',
      },
      {
        id: 60003,
        inkhundla_code: '029',
        business_property: 'R',
        long_desc: 'EDLADLENI/MANKAYANE',
      },
      {
        id: 60004,
        inkhundla_code: '029',
        business_property: 'T',
        long_desc: 'MANKAYANE SUB-REGION',
      },
      {
        id: 60005,
        inkhundla_code: '029',
        business_property: 'R',
        long_desc: 'EFAKUDZE/MANKAYANE',
      },
      {
        id: 60006,
        inkhundla_code: '029',
        business_property: 'R',
        long_desc: 'MANKAYANE MARKET',
      },
      {
        id: 60007,
        inkhundla_code: '029',
        business_property: 'R',
        long_desc: 'KHABONINA/MANKAYANE',
      },
      {
        id: 60008,
        inkhundla_code: '029',
        business_property: 'U',
        long_desc: 'MANKAYANE TOWN CENTRE',
      },
      {
        id: 60009,
        inkhundla_code: '029',
        business_property: 'R',
        long_desc: 'MABOVINI',
      },
      {
        id: 60010,
        inkhundla_code: '033',
        business_property: 'R',
        long_desc: 'MGAZINI',
      },
      {
        id: 60011,
        inkhundla_code: '026',
        business_property: 'R',
        long_desc: 'MGOTSHANE',
      },
      {
        id: 60012,
        inkhundla_code: '029',
        business_property: 'R',
        long_desc: 'NGWEMPISI',
      },
      {
        id: 60013,
        inkhundla_code: '028',
        business_property: 'R',
        long_desc: 'EMTFONGWANENI',
      },
      {
        id: 60014,
        inkhundla_code: '029',
        business_property: 'R',
        long_desc: 'VELEZIZWENI',
      },
      {
        id: 60015,
        inkhundla_code: '023',
        business_property: 'R',
        long_desc: 'DWALILE',
      },
      {
        id: 60017,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'EMSENI',
      },
      {
        id: 60018,
        inkhundla_code: '033',
        business_property: 'R',
        long_desc: 'TSAWELA',
      },
      {
        id: 60019,
        inkhundla_code: '022',
        business_property: 'R',
        long_desc: 'SIBOVU',
      },
      {
        id: 60020,
        inkhundla_code: '033',
        business_property: 'R',
        long_desc: 'BHADZENI 11',
      },
      {
        id: 60021,
        inkhundla_code: '018',
        business_property: 'R',
        long_desc: 'DUDUSINI',
      },
      {
        id: 60022,
        inkhundla_code: '022',
        business_property: 'R',
        long_desc: 'BHAHWINI',
      },
      {
        id: 60023,
        inkhundla_code: '029',
        business_property: 'R',
        long_desc: 'KADEDA',
      },
      {
        id: 60024,
        inkhundla_code: '022',
        business_property: 'R',
        long_desc: 'ENCINISELENI',
      },
      {
        id: 60025,
        inkhundla_code: '029',
        business_property: 'R',
        long_desc: 'MANGWANENI/MANKAYANE',
      },
      {
        id: 60026,
        inkhundla_code: '029',
        business_property: 'R',
        long_desc: 'SIDZAKENI/MANKAYANE',
      },
      {
        id: 60027,
        inkhundla_code: '022',
        business_property: 'R',
        long_desc: 'MAHLANGATSHA',
      },
      {
        id: 60030,
        inkhundla_code: '029',
        business_property: 'R',
        long_desc: 'MAFUTSENI/MANKAYANE',
      },
      {
        id: 60035,
        inkhundla_code: '033',
        business_property: 'R',
        long_desc: 'MALANGENI',
      },
      {
        id: 60050,
        inkhundla_code: '033',
        business_property: 'R',
        long_desc: 'EDILINI',
      },
      {
        id: 60051,
        inkhundla_code: '022',
        business_property: 'P',
        long_desc: 'EKUKHULUMENI',
      },
      {
        id: 60081,
        inkhundla_code: '029',
        business_property: 'R',
        long_desc: 'NGWEMPISANA',
      },
      {
        id: 60090,
        inkhundla_code: '029',
        business_property: 'R',
        long_desc: 'NGCOSENI',
      },
      {
        id: 60200,
        inkhundla_code: '033',
        business_property: 'R',
        long_desc: 'MPONONO',
      },
      {
        id: 60201,
        inkhundla_code: '023',
        business_property: 'P',
        long_desc: 'MALUTHA',
      },
      {
        id: 60202,
        inkhundla_code: '029',
        business_property: 'R',
        long_desc: 'DZANYANE',
      },
      {
        id: 62001,
        inkhundla_code: '029',
        business_property: 'R',
        long_desc: 'BHADZENI 1',
      },
      {
        id: 62002,
        inkhundla_code: '033',
        business_property: 'R',
        long_desc: 'MUSI',
      },
      {
        id: 62003,
        inkhundla_code: '018',
        business_property: 'R',
        long_desc: 'DVUDVUSINI',
      },
      {
        id: 62004,
        inkhundla_code: '033',
        business_property: 'R',
        long_desc: 'MBANANA',
      },
      {
        id: 62005,
        inkhundla_code: '045',
        business_property: 'R',
        long_desc: 'MLINDZINI',
      },
      {
        id: 62006,
        inkhundla_code: '045',
        business_property: 'P',
        long_desc: 'DRIEFONTEIN FARM',
      },
      {
        id: 62007,
        inkhundla_code: '029',
        business_property: 'R',
        long_desc: 'MACUDVULWINI',
      },
      {
        id: 62008,
        inkhundla_code: '029',
        business_property: 'P',
        long_desc: 'NGWEMPISI',
      },
      {
        id: 62009,
        inkhundla_code: '018',
        business_property: 'R',
        long_desc: 'ENHLULWENI',
      },
      {
        id: 62010,
        inkhundla_code: '022',
        business_property: 'R',
        long_desc: 'NSHAMANTI',
      },
      {
        id: 62011,
        inkhundla_code: '029',
        business_property: 'P',
        long_desc: 'VELEZIZWENI',
      },
      {
        id: 62012,
        inkhundla_code: '029',
        business_property: 'R',
        long_desc: 'MHLATANE MANKAYANE',
      },
      {
        id: 62013,
        inkhundla_code: '023',
        business_property: 'R',
        long_desc: 'ETINYONINI MALUTHA',
      },
      {
        id: 70001,
        inkhundla_code: '013',
        business_property: 'U',
        long_desc: 'PIGGS PEAK',
      },
      {
        id: 70002,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'BETSAMOYA',
      },
      {
        id: 70003,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'BHUDLWENI',
      },
      {
        id: 70004,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'BUHLEBUYEZA',
      },
      {
        id: 70005,
        inkhundla_code: '008',
        business_property: 'R',
        long_desc: 'BUHLENI',
      },
      {
        id: 70006,
        inkhundla_code: '010',
        business_property: 'R',
        long_desc: 'BULANDZENI',
      },
      {
        id: 70007,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: 'BULEMBU',
      },
      {
        id: 70008,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'COKISENI',
      },
      {
        id: 70009,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'DAMBANE',
      },
      {
        id: 70010,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: 'DRYHOEK',
      },
      {
        id: 70011,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'DVOKOLWAKO',
      },
      {
        id: 70012,
        inkhundla_code: '002',
        business_property: 'R',
        long_desc: 'EDLIZENI',
      },
      {
        id: 70013,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'EMALAYININI',
      },
      {
        id: 70014,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'GELEKECENI',
      },
      {
        id: 70015,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'GUCUKA',
      },
      {
        id: 70016,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'GUNGUNDLOVU',
      },
      {
        id: 70017,
        inkhundla_code: '005',
        business_property: 'R',
        long_desc: 'HEREFORDS',
      },
      {
        id: 70018,
        inkhundla_code: '012',
        business_property: 'R',
        long_desc: 'KALA ZULU',
      },
      {
        id: 70019,
        inkhundla_code: '012',
        business_property: 'R',
        long_desc: 'LOMSHIYO',
      },
      {
        id: 70020,
        inkhundla_code: '010',
        business_property: 'R',
        long_desc: 'LUDLAWINI',
      },
      {
        id: 70021,
        inkhundla_code: '010',
        business_property: 'R',
        long_desc: 'LUDZIBINI',
      },
      {
        id: 70022,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: 'LUHHUMANENI',
      },
      {
        id: 70023,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: 'LUHLANGOTSINI',
      },
      {
        id: 70024,
        inkhundla_code: '015',
        business_property: 'R',
        long_desc: 'LUHLEKWENI',
      },
      {
        id: 70025,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: 'LUPHALWANE',
      },
      {
        id: 70026,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: 'MABHOKO',
      },
      {
        id: 70027,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'MADLANGEMPISI',
      },
      {
        id: 70028,
        inkhundla_code: '008',
        business_property: 'R',
        long_desc: 'MAGENGENI',
      },
      {
        id: 70029,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: 'MAGUGA',
      },
      {
        id: 70030,
        inkhundla_code: '013',
        business_property: 'U',
        long_desc: 'MALANDALAHLE',
      },
      {
        id: 70031,
        inkhundla_code: '008',
        business_property: 'R',
        long_desc: 'MALIBENI',
      },
      {
        id: 70032,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MAMPONDWENI',
      },
      {
        id: 70033,
        inkhundla_code: '008',
        business_property: 'R',
        long_desc: 'MANANGA',
      },
      {
        id: 70034,
        inkhundla_code: '013',
        business_property: 'U',
        long_desc: 'MANGWANENI',
      },
      {
        id: 70035,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: 'MANGWENI',
      },
      {
        id: 70036,
        inkhundla_code: '009',
        business_property: 'R',
        long_desc: 'MANYISA',
      },
      {
        id: 70037,
        inkhundla_code: '012',
        business_property: 'R',
        long_desc: 'MASHOBENI',
      },
      {
        id: 70038,
        inkhundla_code: '005',
        business_property: 'R',
        long_desc: 'MAVULA',
      },
      {
        id: 70039,
        inkhundla_code: '005',
        business_property: 'R',
        long_desc: 'MAYIWANE',
      },
      {
        id: 70040,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'TIMBONDVWENI',
      },
      {
        id: 70041,
        inkhundla_code: '008',
        business_property: 'R',
        long_desc: 'MHLANGATANE',
      },
      {
        id: 70042,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MKHUNJINI',
      },
      {
        id: 70043,
        inkhundla_code: '005',
        business_property: 'R',
        long_desc: 'MKHUZWENI',
      },
      {
        id: 70044,
        inkhundla_code: '005',
        business_property: 'R',
        long_desc: 'MKHWENI',
      },
      {
        id: 70045,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: 'MNCINA',
      },
      {
        id: 70046,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'MNYOKANYOKA',
      },
      {
        id: 70047,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MOYENI',
      },
      {
        id: 70048,
        inkhundla_code: '012',
        business_property: 'R',
        long_desc: 'MSAHWENI',
      },
      {
        id: 70049,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MSHINANDE',
      },
      {
        id: 70050,
        inkhundla_code: '015',
        business_property: 'R',
        long_desc: 'MSILINGENI',
      },
      {
        id: 70051,
        inkhundla_code: '012',
        business_property: 'R',
        long_desc: 'MSUMPE',
      },
      {
        id: 70052,
        inkhundla_code: '004',
        business_property: 'R',
        long_desc: 'MTJODVWENI',
      },
      {
        id: 70053,
        inkhundla_code: '012',
        business_property: 'R',
        long_desc: 'MVEMBILI',
      },
      {
        id: 70054,
        inkhundla_code: '010',
        business_property: 'R',
        long_desc: 'MVUMA',
      },
      {
        id: 70055,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'MZACENI',
      },
      {
        id: 70056,
        inkhundla_code: '032',
        business_property: 'R',
        long_desc: 'NCABANENI',
      },
      {
        id: 70057,
        inkhundla_code: '010',
        business_property: 'R',
        long_desc: 'NDEVA',
      },
      {
        id: 70058,
        inkhundla_code: '010',
        business_property: 'R',
        long_desc: 'NDZINGENI',
      },
      {
        id: 70059,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: 'NGINAMADOLO',
      },
      {
        id: 70060,
        inkhundla_code: '015',
        business_property: 'R',
        long_desc: 'NGONINI',
      },
      {
        id: 70061,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: 'NGWEMBE',
      },
      {
        id: 70062,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: 'NHLALAKAHLE',
      },
      {
        id: 70063,
        inkhundla_code: '008',
        business_property: 'R',
        long_desc: 'NHLANGUYAVUKA',
      },
      {
        id: 70064,
        inkhundla_code: '012',
        business_property: 'R',
        long_desc: 'NJAKENI',
      },
      {
        id: 70065,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'NKABENI',
      },
      {
        id: 70066,
        inkhundla_code: '010',
        business_property: 'R',
        long_desc: 'NKAMAZI',
      },
      {
        id: 70067,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'NKHUBE',
      },
      {
        id: 70068,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'NKOKHOKHWENI',
      },
      {
        id: 70069,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'NKONYANENI',
      },
      {
        id: 70070,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: 'NSANGWINI',
      },
      {
        id: 70071,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'NTANDWENI',
      },
      {
        id: 70072,
        inkhundla_code: '008',
        business_property: 'R',
        long_desc: 'NYAKATFO',
      },
      {
        id: 70073,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: 'NYONYANE',
      },
      {
        id: 70074,
        inkhundla_code: '041',
        business_property: 'R',
        long_desc: 'PHUZWENI',
      },
      {
        id: 70075,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'BALEGANE',
      },
      {
        id: 70076,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'SIBAYA',
      },
      {
        id: 70077,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'SIBOVINI',
      },
      {
        id: 70078,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'SIBOVU',
      },
      {
        id: 70079,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'SIDVUNGE',
      },
      {
        id: 70080,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: "SIDVWASHINI PIGG'S PEAK",
      },
      {
        id: 70081,
        inkhundla_code: '008',
        business_property: 'R',
        long_desc: 'SIHHOYE',
      },
      {
        id: 70082,
        inkhundla_code: '015',
        business_property: 'R',
        long_desc: 'TIMPHISINI',
      },
      {
        id: 70083,
        inkhundla_code: '012',
        business_property: 'R',
        long_desc: 'TINGADZENI',
      },
      {
        id: 70084,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'TITININI',
      },
      {
        id: 70085,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'ZANDONDO',
      },
      {
        id: 70086,
        inkhundla_code: '012',
        business_property: 'R',
        long_desc: 'ZIBONELE',
      },
      {
        id: 70087,
        inkhundla_code: '008',
        business_property: 'R',
        long_desc: 'ZINYANE',
      },
      {
        id: 70088,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: 'ZIZWENI',
      },
      {
        id: 70089,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'ZONDANANI',
      },
      {
        id: 70090,
        inkhundla_code: '015',
        business_property: 'R',
        long_desc: 'MATSAMO',
      },
      {
        id: 70091,
        inkhundla_code: '015',
        business_property: 'R',
        long_desc: 'VUSWENI',
      },
      {
        id: 70092,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'NKAMBENI',
      },
      {
        id: 70093,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'MSINDZA',
      },
      {
        id: 70094,
        inkhundla_code: '013',
        business_property: 'P',
        long_desc: 'PHOPHONYANE',
      },
      {
        id: 70095,
        inkhundla_code: '013',
        business_property: 'U',
        long_desc: 'KK BUILDING',
      },
      {
        id: 70096,
        inkhundla_code: '010',
        business_property: 'R',
        long_desc: 'MAVULELA',
      },
      {
        id: 70097,
        inkhundla_code: '008',
        business_property: 'R',
        long_desc: 'MPOFU',
      },
      {
        id: 70098,
        inkhundla_code: '010',
        business_property: 'R',
        long_desc: 'MGUNGUNDLOVU',
      },
      {
        id: 70099,
        inkhundla_code: '012',
        business_property: 'R',
        long_desc: 'MLUMATI',
      },
      {
        id: 70100,
        inkhundla_code: '004',
        business_property: 'R',
        long_desc: 'EKUVINJELWENI',
      },
      {
        id: 70101,
        inkhundla_code: '004',
        business_property: 'R',
        long_desc: 'KUFIKENI',
      },
      {
        id: 70102,
        inkhundla_code: '004',
        business_property: 'R',
        long_desc: 'JAKIZA',
      },
      {
        id: 70103,
        inkhundla_code: '015',
        business_property: 'R',
        long_desc: 'NDLALAMBI',
      },
      {
        id: 70104,
        inkhundla_code: '012',
        business_property: 'R',
        long_desc: 'MLAMBOTJWALA',
      },
      {
        id: 70105,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'MLIBA',
      },
      {
        id: 70106,
        inkhundla_code: '006',
        business_property: 'U',
        long_desc: 'MBABANE LM BUILDING',
      },
      {
        id: 70107,
        inkhundla_code: '004',
        business_property: 'R',
        long_desc: 'MELETI',
      },
      {
        id: 70108,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'EMATFUNTINI (HHOHHO PART)',
      },
      {
        id: 70109,
        inkhundla_code: '008',
        business_property: 'R',
        long_desc: 'NTABINEZIMPISI',
      },
      {
        id: 70110,
        inkhundla_code: '004',
        business_property: 'R',
        long_desc: 'NTSININI',
      },
      {
        id: 70111,
        inkhundla_code: '004',
        business_property: 'R',
        long_desc: 'ENTSANJENI',
      },
      {
        id: 70112,
        inkhundla_code: '010',
        business_property: 'R',
        long_desc: 'MAWULWANE',
      },
      {
        id: 70113,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: 'PHOPHONYANE RURAL',
      },
      {
        id: 70114,
        inkhundla_code: '008',
        business_property: 'R',
        long_desc: 'EMZINTI',
      },
      {
        id: 70115,
        inkhundla_code: '010',
        business_property: 'R',
        long_desc: 'NGOWANE',
      },
      {
        id: 70116,
        inkhundla_code: '013',
        business_property: 'P',
        long_desc: 'ROCKLANDS FARM',
      },
      {
        id: 70117,
        inkhundla_code: '013',
        business_property: 'P',
        long_desc: 'BULEMBU FARM',
      },
      {
        id: 70118,
        inkhundla_code: '012',
        business_property: 'R',
        long_desc: 'EHLALA',
      },
      {
        id: 70120,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: '__MATFUNTINI',
      },
      {
        id: 70121,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'MATFUNTINI',
      },
      {
        id: 70122,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'MAGUMENI',
      },
      {
        id: 70123,
        inkhundla_code: '012',
        business_property: 'R',
        long_desc: 'MASHOBANE',
      },
      {
        id: 70124,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: 'MHLATANE',
      },
      {
        id: 70125,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: 'ROCKLANDS',
      },
      {
        id: 70126,
        inkhundla_code: '008',
        business_property: 'R',
        long_desc: 'MANJENGENI',
      },
      {
        id: 70140,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'EMPHUCUKWENI',
      },
      {
        id: 70155,
        inkhundla_code: '012',
        business_property: 'R',
        long_desc: 'KA-KESS',
      },
      {
        id: 70156,
        inkhundla_code: '012',
        business_property: 'R',
        long_desc: 'MSHINGISHINGINI',
      },
      {
        id: 70157,
        inkhundla_code: '012',
        business_property: 'R',
        long_desc: 'NTFONJENI',
      },
      {
        id: 70158,
        inkhundla_code: '008',
        business_property: 'R',
        long_desc: 'TINGONINI',
      },
      {
        id: 71001,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: 'GOBOLONDLO',
      },
      {
        id: 71005,
        inkhundla_code: '004',
        business_property: 'R',
        long_desc: 'EKUVINJELWENI',
      },
      {
        id: 71006,
        inkhundla_code: '012',
        business_property: 'R',
        long_desc: 'MGOBODZI',
      },
      {
        id: 71007,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'MVULENI',
      },
      {
        id: 71008,
        inkhundla_code: '012',
        business_property: 'R',
        long_desc: 'HHELEHHELE HHOHHO',
      },
      {
        id: 71009,
        inkhundla_code: '008',
        business_property: 'R',
        long_desc: 'SIDVWASHINI(NYAKATFO)',
      },
      {
        id: 71010,
        inkhundla_code: '013',
        business_property: 'U',
        long_desc: 'PIGGS PEAK MARKET',
      },
      {
        id: 71011,
        inkhundla_code: '012',
        business_property: 'R',
        long_desc: 'NKONJANENI',
      },
      {
        id: 71012,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'DVOKOLWAKO MANZANA',
      },
      {
        id: 71013,
        inkhundla_code: '013',
        business_property: 'U',
        long_desc: 'PIGGS PEAK(KASOLWAKO BLDG)',
      },
      {
        id: 71014,
        inkhundla_code: '013',
        business_property: 'U',
        long_desc: 'PIGGS PEAK (MAMBA BLDG)',
      },
      {
        id: 71015,
        inkhundla_code: '008',
        business_property: 'R',
        long_desc: 'MAPAMPINI',
      },
      {
        id: 71016,
        inkhundla_code: '003',
        business_property: 'R',
        long_desc: 'BLACK MBULUZI DVOKOLWAKO',
      },
      {
        id: 71017,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: 'NKOMAZI',
      },
      {
        id: 71018,
        inkhundla_code: '003',
        business_property: 'P',
        long_desc: 'BALEKANE PRISON',
      },
      {
        id: 71019,
        inkhundla_code: '015',
        business_property: 'R',
        long_desc: 'MBASHENI',
      },
      {
        id: 71020,
        inkhundla_code: '027',
        business_property: 'R',
        long_desc: 'CROYDONE',
      },
      {
        id: 71021,
        inkhundla_code: '010',
        business_property: 'R',
        long_desc: 'NTSANJENI',
      },
      {
        id: 71022,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: 'NGINAMADVOLO',
      },
      {
        id: 71023,
        inkhundla_code: '015',
        business_property: 'P',
        long_desc: 'NGONINI FARM',
      },
      {
        id: 71024,
        inkhundla_code: '013',
        business_property: 'P',
        long_desc: 'PIGGS PEAK',
      },
      {
        id: 71025,
        inkhundla_code: '003',
        business_property: 'P',
        long_desc: 'MADLANGEMPHISI',
      },
      {
        id: 71026,
        inkhundla_code: '008',
        business_property: 'P',
        long_desc: 'SIHHOYE FARM',
      },
      {
        id: 71027,
        inkhundla_code: '008',
        business_property: 'P',
        long_desc: 'SIHHOYE TIBIYO FARM',
      },
      {
        id: 71028,
        inkhundla_code: '008',
        business_property: 'U',
        long_desc: 'BUHLENI',
      },
      {
        id: 71029,
        inkhundla_code: '015',
        business_property: 'R',
        long_desc: 'FONTOTJE',
      },
      {
        id: 71030,
        inkhundla_code: '003',
        business_property: 'P',
        long_desc: 'MBELEBELENI',
      },
      {
        id: 71031,
        inkhundla_code: '005',
        business_property: 'R',
        long_desc: 'TINCATSAVANENI',
      },
      {
        id: 71032,
        inkhundla_code: '012',
        business_property: 'R',
        long_desc: 'MFELAFUTSI',
      },
      {
        id: 71033,
        inkhundla_code: '030',
        business_property: 'R',
        long_desc: 'NHLAMBENI',
      },
      {
        id: 71034,
        inkhundla_code: '008',
        business_property: 'R',
        long_desc: 'NDVWABANGENI',
      },
      {
        id: 71035,
        inkhundla_code: '000',
        business_property: 'R',
        long_desc: 'EMPHESHENI',
      },
      {
        id: 71036,
        inkhundla_code: '013',
        business_property: 'R',
        long_desc: 'MNYOKANE',
      },
      {
        id: 71037,
        inkhundla_code: '013',
        business_property: 'P',
        long_desc: 'MNYOKANE',
      },
      {
        id: 71038,
        inkhundla_code: '008',
        business_property: 'R',
        long_desc: 'MZIMNENE',
      },
      {
        id: 71039,
        inkhundla_code: '008',
        business_property: 'R',
        long_desc: 'MAWOMBE',
      },
    ],

    businessPropertys: [
      {
        id: 1,
        code: 'P',
        name: 'Private property/Farm',
      },
      {
        id: 3,
        code: 'R',
        name: 'Rural',
      },
      {
        id: 4,
        code: 'T',
        name: 'Travel',
      },
      {
        id: 2,
        code: 'U',
        name: 'Urban',
      },
    ],

    businessNatureTypes: [
      {
        id: 8,
        code: '008',
        name: '2ND HAND CAR SALES',
      },
      {
        id: 11,
        code: '011',
        name: '4 WHEEL DRIVE CLUB',
      },
      {
        id: 128,
        code: '128',
        name: 'ACQUIRE SHARES, BONDS, DEBENTURES',
      },
      {
        id: 293,
        code: '310',
        name: 'ADVANCE MONEY TO SHAREHOLDERS & PERSONS',
      },
      {
        id: 191,
        code: '191',
        name: 'ADVERTISING',
      },
      {
        id: 327,
        code: '373',
        name: 'ADVISORS',
      },
      {
        id: 66,
        code: '066',
        name: 'AGENTS',
      },
      {
        id: 55,
        code: '055',
        name: 'AGRICULTURAL',
      },
      {
        id: 371,
        code: '426',
        name: 'AIR-CONDITIONING (HVAC) SERVICES',
      },
      {
        id: 59,
        code: '059',
        name: 'AIRCRAFT OPERATORS',
      },
      {
        id: 195,
        code: '195',
        name: 'ALUMINIUM INSTALLATION',
      },
      {
        id: 161,
        code: '161',
        name: 'AMUSEMENT PARK',
      },
      {
        id: 131,
        code: '131',
        name: 'ARCHITECTURAL CONSULTANT',
      },
      {
        id: 406,
        code: '462',
        name: 'ASSIST ORPHANE & OVCS',
      },
      {
        id: 214,
        code: '214',
        name: 'AUCTIONEERS',
      },
      {
        id: 378,
        code: '433',
        name: 'AUTO SPARE PARTS AND ACCESSORIES',
      },
      {
        id: 157,
        code: '157',
        name: 'AUTOMOTIVE ACCESORIES',
      },
      {
        id: 209,
        code: '209',
        name: 'BABY SHOP',
      },
      {
        id: 139,
        code: '139',
        name: 'BAKERS & DEALERS IN BREAD',
      },
      {
        id: 115,
        code: '115',
        name: 'BANKING',
      },
      {
        id: 100,
        code: '100',
        name: 'BEAUTY PAGEANTS',
      },
      {
        id: 374,
        code: '429',
        name: 'BEUTY THERAPIST,SPECIALIST',
      },
      {
        id: 317,
        code: '353',
        name: 'BLADES',
      },
      {
        id: 335,
        code: '381',
        name: 'BLANKETS',
      },
      {
        id: 114,
        code: '114',
        name: 'BLOCK & CONCRETE PRODUCT',
      },
      {
        id: 202,
        code: '202',
        name: 'BLOCK MAKING',
      },
      {
        id: 76,
        code: '076',
        name: 'BOILER-MAKING',
      },
      {
        id: 396,
        code: '452',
        name: 'BOLT AND NUTS',
      },
      {
        id: 255,
        code: '262',
        name: 'BONE-SETTERS',
      },
      {
        id: 392,
        code: '448',
        name: 'BOOKEEPERS',
      },
      {
        id: 296,
        code: '314',
        name: 'BOTTLING STORE',
      },
      {
        id: 60,
        code: '060',
        name: 'BOUTIQUE',
      },
      {
        id: 213,
        code: '213',
        name: 'BREAST CANCER COUNSELLING',
      },
      {
        id: 99,
        code: '099',
        name: 'BREWING WINE & MALT',
      },
      {
        id: 3,
        code: '003',
        name: 'BROADCASTING',
      },
      {
        id: 65,
        code: '065',
        name: 'BUILDING CONSTRUCTION',
      },
      {
        id: 310,
        code: '346',
        name: 'BUILDOZER',
      },
      {
        id: 232,
        code: '233',
        name: 'BURIAL SERVICES',
      },
      {
        id: 18,
        code: '018',
        name: 'BUSH CLEARING',
      },
      {
        id: 127,
        code: '127',
        name: 'BUSINESS CONSULTANCY',
      },
      {
        id: 101,
        code: '101',
        name: 'BUSINESS OF CHEMISTS & DRUGGISTS',
      },
      {
        id: 14,
        code: '014',
        name: 'BUTCHERIES',
      },
      {
        id: 295,
        code: '312',
        name: 'BUTCHERY',
      },
      {
        id: 4,
        code: '004',
        name: 'CAFE',
      },
      {
        id: 324,
        code: '366',
        name: 'CANDLES',
      },
      {
        id: 194,
        code: '194',
        name: 'CAR WASH',
      },
      {
        id: 381,
        code: '436',
        name: 'CAR-HIRE',
      },
      {
        id: 289,
        code: '306',
        name: 'CARDS',
      },
      {
        id: 90,
        code: '090',
        name: 'CARE & MAINTENANCE OF WATERWORKS',
      },
      {
        id: 22,
        code: '022',
        name: 'CARPET CLEANING',
      },
      {
        id: 71,
        code: '071',
        name: 'CATERING',
      },
      {
        id: 164,
        code: '164',
        name: 'CELLPHONE ACCESORIES',
      },
      {
        id: 187,
        code: '187',
        name: 'CELLULAR SERVICES',
      },
      {
        id: 237,
        code: '240',
        name: 'CEMENTATION WORS',
      },
      {
        id: 181,
        code: '181',
        name: 'CEREAL MILLING',
      },
      {
        id: 248,
        code: '255',
        name: 'CHEMICAL PRODUCTS',
      },
      {
        id: 338,
        code: '385',
        name: 'CHEMIST',
      },
      {
        id: 257,
        code: '264',
        name: 'CHIROPRACTORS',
      },
      {
        id: 308,
        code: '343',
        name: 'CIGARETS',
      },
      {
        id: 45,
        code: '045',
        name: 'CIVIL WORKS',
      },
      {
        id: 24,
        code: '024',
        name: 'CLEANING CHEMICALS',
      },
      {
        id: 26,
        code: '026',
        name: 'CLEANING MACHINES',
      },
      {
        id: 25,
        code: '025',
        name: 'CLEANING MATERIALS',
      },
      {
        id: 170,
        code: '170',
        name: 'CLEANING SERVICES',
      },
      {
        id: 120,
        code: '120',
        name: 'CLEARING , FORWARDING,SHIPPING & TRAVEL',
      },
      {
        id: 314,
        code: '350',
        name: 'CLINIC',
      },
      {
        id: 305,
        code: '340',
        name: 'CLOTHING',
      },
      {
        id: 376,
        code: '431',
        name: 'COFFEE SHOP',
      },
      {
        id: 242,
        code: '248',
        name: 'COFFIN DISTRIBUTORS',
      },
      {
        id: 369,
        code: '424',
        name: 'COLLECTION',
      },
      {
        id: 370,
        code: '425',
        name: 'COLLECTION AND DISPOSAL OF WASTE PRODUCT',
      },
      {
        id: 154,
        code: '154',
        name: 'COMMUNICATION CONSULTANCY',
      },
      {
        id: 344,
        code: '392',
        name: 'COMMUNITY DEVELOPMENT ASSISTANCE',
      },
      {
        id: 146,
        code: '146',
        name: 'COMPUTER ACCESSORIES',
      },
      {
        id: 168,
        code: '168',
        name: 'COMPUTER EQUIPMENT',
      },
      {
        id: 238,
        code: '241',
        name: 'COMPUTER TECHNICIAN',
      },
      {
        id: 261,
        code: '269',
        name: 'COMPUTER TRAINING',
      },
      {
        id: 135,
        code: '135',
        name: 'CONCRETE MIXING',
      },
      {
        id: 346,
        code: '394',
        name: 'CONDOME MANUFACTURING',
      },
      {
        id: 156,
        code: '156',
        name: 'CONFECTIONERY',
      },
      {
        id: 1,
        code: '001',
        name: 'CONSTRUCTION',
      },
      {
        id: 330,
        code: '376',
        name: 'CONSULTANCY',
      },
      {
        id: 103,
        code: '103',
        name: 'CONSULTING ENGINEERS',
      },
      {
        id: 269,
        code: '277',
        name: 'CONSUMER CREDIT',
      },
      {
        id: 58,
        code: '058',
        name: 'CONTRUCTION OF ELECTRICAL EQUIPMENT',
      },
      {
        id: 407,
        code: '463',
        name: 'COOPERATIVE MANAGEMENT',
      },
      {
        id: 207,
        code: '207',
        name: 'CORPORATE CLOTHING',
      },
      {
        id: 188,
        code: '188',
        name: 'COTTON GINNERY',
      },
      {
        id: 358,
        code: '412',
        name: 'COUNSELING',
      },
      {
        id: 333,
        code: '379',
        name: 'COURIOR',
      },
      {
        id: 263,
        code: '271',
        name: 'CRUSHED STONES',
      },
      {
        id: 393,
        code: '449',
        name: 'CULTURAL ADVANCEMENT OF THE WOMEN',
      },
      {
        id: 386,
        code: '442',
        name: 'CULTURAL VILLAGE,LODGE,CAMPSITE WORKSHOP',
      },
      {
        id: 388,
        code: '444',
        name: 'CUTLERY AND CROCKERY',
      },
      {
        id: 311,
        code: '347',
        name: 'DAIRY PRODUCTS',
      },
      {
        id: 129,
        code: '129',
        name: 'DEAL IN LUBRICATING & OTHER OIL',
      },
      {
        id: 56,
        code: '056',
        name: 'DEALERS IN SKINS AND HIDES',
      },
      {
        id: 92,
        code: '092',
        name: 'DEALERS IN VETINARY INSTRUMENTS',
      },
      {
        id: 93,
        code: '093',
        name: 'DEALERS IN VETINARY MEDICINES',
      },
      {
        id: 75,
        code: '075',
        name: 'DEBENTURES MORTGAGES',
      },
      {
        id: 166,
        code: '166',
        name: 'DEBT COLLECTORS',
      },
      {
        id: 290,
        code: '307',
        name: 'DECORATIONS',
      },
      {
        id: 219,
        code: '219',
        name: 'DENTAL PRACTITIONERS',
      },
      {
        id: 231,
        code: '232',
        name: 'DENTAL SERVICES',
      },
      {
        id: 107,
        code: '107',
        name: 'DESIGNING',
      },
      {
        id: 178,
        code: '178',
        name: 'DESIGNING',
      },
      {
        id: 329,
        code: '375',
        name: 'DETERGENTS',
      },
      {
        id: 44,
        code: '044',
        name: 'DIGGING & PLACING POLES',
      },
      {
        id: 351,
        code: '405',
        name: 'DISTRIBUTION OF BRAKES,CLUTHES, TRAILERS',
      },
      {
        id: 359,
        code: '413',
        name: 'DISTRIBUTORS',
      },
      {
        id: 302,
        code: '321',
        name: 'DOCUMENTATION',
      },
      {
        id: 316,
        code: '352',
        name: 'DRILLING',
      },
      {
        id: 200,
        code: '200',
        name: 'DRIVING ACADEMY',
      },
      {
        id: 281,
        code: '294',
        name: 'DRUGS',
      },
      {
        id: 210,
        code: '210',
        name: 'DRY CLEANERS & LAUNDRY',
      },
      {
        id: 36,
        code: '036',
        name: 'DUMPING & PROCESSING',
      },
      {
        id: 342,
        code: '390',
        name: 'DUVET',
      },
      {
        id: 341,
        code: '389',
        name: 'DUVET AND BLANKETS',
      },
      {
        id: 78,
        code: '078',
        name: 'EDUCATORS',
      },
      {
        id: 20,
        code: '020',
        name: 'ELECTRICAL CONTRACTOR',
      },
      {
        id: 19,
        code: '019',
        name: 'ELECTRICAL ENGINEERS',
      },
      {
        id: 147,
        code: '147',
        name: 'ELECTRICAL WORKS',
      },
      {
        id: 104,
        code: '104',
        name: 'ELECTRICIANS FOR DOMESTIC & INDUSTRIAL',
      },
      {
        id: 136,
        code: '136',
        name: 'ELECTRONICS SERVICES',
      },
      {
        id: 171,
        code: '171',
        name: 'EMPLOYMENT AGENCY',
      },
      {
        id: 165,
        code: '165',
        name: 'ENGAGE IN SPORT WEAR',
      },
      {
        id: 102,
        code: '102',
        name: 'ENGAGEIN LAW,GENDER & DEVELOPMENT CONS.',
      },
      {
        id: 73,
        code: '073',
        name: 'ENGINEERING WORKS',
      },
      {
        id: 80,
        code: '080',
        name: 'ENTERPRISE',
      },
      {
        id: 180,
        code: '180',
        name: 'ENTERTAINMENT',
      },
      {
        id: 234,
        code: '235',
        name: 'ENTERTAINMENT PROMOTERS',
      },
      {
        id: 394,
        code: '450',
        name: 'ENTREPRENEURS & PRODUCTIVE EMPLOYEES',
      },
      {
        id: 197,
        code: '197',
        name: 'ENVIRONMENTAL SUPPLIES',
      },
      {
        id: 122,
        code: '122',
        name: 'ERECT BUS-SHELTERS',
      },
      {
        id: 87,
        code: '087',
        name: 'ESTABLISHMENT AND SUPPORT OF A SCHOOL',
      },
      {
        id: 89,
        code: '089',
        name: 'ESTABLISHMENT OF BURSARIES',
      },
      {
        id: 119,
        code: '119',
        name: 'EXPLORE, PROSPECT AND MINE ALL METAL',
      },
      {
        id: 220,
        code: '220',
        name: 'EXPLORING COMPANY',
      },
      {
        id: 287,
        code: '304',
        name: 'FABRICS',
      },
      {
        id: 288,
        code: '305',
        name: 'FABRICS',
      },
      {
        id: 88,
        code: '088',
        name: 'FAITH OR CREED IN TERRITORY OF SWAZILAND',
      },
      {
        id: 126,
        code: '126',
        name: 'FARMING',
      },
      {
        id: 216,
        code: '216',
        name: 'FATTERNING RANCH',
      },
      {
        id: 373,
        code: '428',
        name: 'FEEDLOT,LIVESTOCKBREEDING,DAIRY FARMING',
      },
      {
        id: 33,
        code: '033',
        name: 'FENCING',
      },
      {
        id: 34,
        code: '034',
        name: 'FENCING',
      },
      {
        id: 303,
        code: '330',
        name: 'FILING',
      },
      {
        id: 318,
        code: '354',
        name: 'FILLING STATION',
      },
      {
        id: 97,
        code: '097',
        name: 'FINANCIAL ACCOUNTING SERVICES',
      },
      {
        id: 150,
        code: '150',
        name: 'FINANCIAL ASSISTANCE',
      },
      {
        id: 205,
        code: '205',
        name: 'FIRE EXTINGUISHERS',
      },
      {
        id: 400,
        code: '456',
        name: 'FITTING AND REPAIRING TYRES',
      },
      {
        id: 212,
        code: '212',
        name: 'FLEET MANAGEMENT SERVICES',
      },
      {
        id: 138,
        code: '138',
        name: 'FLOWER AND GIFT SHOP',
      },
      {
        id: 52,
        code: '052',
        name: 'FOODSTUFF SUPPLY',
      },
      {
        id: 397,
        code: '453',
        name: 'FOREIGN CURRENCY',
      },
      {
        id: 110,
        code: '110',
        name: 'FORESTERS',
      },
      {
        id: 380,
        code: '435',
        name: 'FRANCHISE',
      },
      {
        id: 176,
        code: '176',
        name: 'FREIGHT FORWARDING',
      },
      {
        id: 401,
        code: '457',
        name: 'FRIEGHT COURIER SERVICES',
      },
      {
        id: 340,
        code: '387',
        name: 'FRUIT CANNERS',
      },
      {
        id: 218,
        code: '218',
        name: 'FRUITS & VEGETABLES',
      },
      {
        id: 79,
        code: '079',
        name: 'FUNERAL UNDERTAKERS',
      },
      {
        id: 112,
        code: '112',
        name: 'FURNISHING & GENERAL WAREHOUSEMEN',
      },
      {
        id: 325,
        code: '370',
        name: 'GADGETS',
      },
      {
        id: 252,
        code: '259',
        name: 'GAMBLING',
      },
      {
        id: 349,
        code: '397',
        name: 'GAMING AND BETTING',
      },
      {
        id: 50,
        code: '050',
        name: 'GARAGES AND WORKSHOP',
      },
      {
        id: 153,
        code: '153',
        name: 'GENERAL DEALER',
      },
      {
        id: 345,
        code: '393',
        name: 'GENERAL SUPPLY',
      },
      {
        id: 283,
        code: '300',
        name: 'GIFT SHOP',
      },
      {
        id: 84,
        code: '084',
        name: 'GLASS CUTTING',
      },
      {
        id: 28,
        code: '028',
        name: 'GLASSWARE',
      },
      {
        id: 83,
        code: '083',
        name: 'GLAZIERS',
      },
      {
        id: 348,
        code: '396',
        name: 'GOLF AND ENTERTAINMENT PROMOTERS',
      },
      {
        id: 267,
        code: '275',
        name: 'GRANITE',
      },
      {
        id: 377,
        code: '432',
        name: 'GRAPHIC DESIGNS',
      },
      {
        id: 29,
        code: '029',
        name: 'GRASS CUTTING',
      },
      {
        id: 352,
        code: '406',
        name: 'GREEN HEAT DISTRIBUTING',
      },
      {
        id: 39,
        code: '039',
        name: 'GROWING SUGAR CANE',
      },
      {
        id: 276,
        code: '288',
        name: 'GUESTHOUSE',
      },
      {
        id: 62,
        code: '062',
        name: 'HAIR DRESSING',
      },
      {
        id: 192,
        code: '192',
        name: 'HANDICRAFTS',
      },
      {
        id: 41,
        code: '041',
        name: 'HARDWARES',
      },
      {
        id: 142,
        code: '142',
        name: 'HARVESTING',
      },
      {
        id: 42,
        code: '042',
        name: 'HAULAGE & TRUCKING',
      },
      {
        id: 53,
        code: '053',
        name: 'HAULAGE AND KOMBIS',
      },
      {
        id: 167,
        code: '167',
        name: 'HEALTH AND SAFETY EQUIPMENTS',
      },
      {
        id: 313,
        code: '349',
        name: 'HEALTH CENTRE',
      },
      {
        id: 404,
        code: '460',
        name: 'HELPING YOUTH',
      },
      {
        id: 280,
        code: '292',
        name: 'HERBS',
      },
      {
        id: 364,
        code: '419',
        name: 'HIRING CARS AND TRAILERS',
      },
      {
        id: 77,
        code: '077',
        name: 'HIRING OUT ENGINEERS',
      },
      {
        id: 286,
        code: '303',
        name: 'HIRING TRACTORS',
      },
      {
        id: 365,
        code: '420',
        name: 'HOLIDAY-RESORT KEEPERS,HOTEL,LODGE',
      },
      {
        id: 398,
        code: '454',
        name: 'HORSE RIDING PROPRIETORS',
      },
      {
        id: 37,
        code: '037',
        name: 'HORTICULTURE',
      },
      {
        id: 184,
        code: '184',
        name: 'HORTICULTURE',
      },
      {
        id: 360,
        code: '415',
        name: 'HOSTING CORPORATE EVENTS',
      },
      {
        id: 70,
        code: '070',
        name: 'HOTEL MANAGEMENT',
      },
      {
        id: 98,
        code: '098',
        name: 'HOTEL PROPRIETARY',
      },
      {
        id: 395,
        code: '451',
        name: 'HYDRAULIC EQUIPMENT',
      },
      {
        id: 332,
        code: '378',
        name: 'IMMOVABLE PROPERTY',
      },
      {
        id: 387,
        code: '443',
        name: 'IMPORTERS',
      },
      {
        id: 31,
        code: '031',
        name: 'IMPORTERS & EXPORTERS OF VARIOUS GOODS',
      },
      {
        id: 117,
        code: '117',
        name: 'IMPORTERS AND EXPORTERS',
      },
      {
        id: 23,
        code: '023',
        name: 'INDUSTRIAL CHEMICALS',
      },
      {
        id: 272,
        code: '281',
        name: 'INDUSTRIAL WEARS',
      },
      {
        id: 233,
        code: '234',
        name: 'INDUSTRY',
      },
      {
        id: 198,
        code: '198',
        name: 'INFORMATION SYSTEMS',
      },
      {
        id: 273,
        code: '283',
        name: 'INSTALLATION OF ALARMS',
      },
      {
        id: 384,
        code: '439',
        name: 'INSTITUTION',
      },
      {
        id: 13,
        code: '013',
        name: 'INSURANCE BROKERS',
      },
      {
        id: 391,
        code: '447',
        name: 'INTALLING AND ELECTRIGATES',
      },
      {
        id: 367,
        code: '422',
        name: "INTERNET CAFE'S",
      },
      {
        id: 152,
        code: '152',
        name: 'INVESTIGATORS',
      },
      {
        id: 12,
        code: '012',
        name: 'INVESTMENT HOLDING',
      },
      {
        id: 72,
        code: '072',
        name: 'INVESTMENTS',
      },
      {
        id: 160,
        code: '160',
        name: 'JEWELLERY',
      },
      {
        id: 385,
        code: '441',
        name: 'KICHEN AND BEDROOM CUPBOARDS',
      },
      {
        id: 32,
        code: '032',
        name: 'LANDSCAPING',
      },
      {
        id: 74,
        code: '074',
        name: 'LAUNDRY',
      },
      {
        id: 312,
        code: '348',
        name: 'LAW',
      },
      {
        id: 298,
        code: '316',
        name: 'LEATHER MANUFACTURER',
      },
      {
        id: 326,
        code: '372',
        name: 'LEGAL ADVISORS',
      },
      {
        id: 361,
        code: '416',
        name: 'LENDING',
      },
      {
        id: 362,
        code: '417',
        name: 'LENDING AND INVESTING MONEY',
      },
      {
        id: 307,
        code: '342',
        name: 'LIQOUR',
      },
      {
        id: 186,
        code: '186',
        name: 'LIQUIDATORS',
      },
      {
        id: 347,
        code: '395',
        name: 'LIVESTOCK BREEDERS',
      },
      {
        id: 251,
        code: '258',
        name: 'LOTTO',
      },
      {
        id: 368,
        code: '423',
        name: 'LOUNGEBAR,ENTERTAINMENT CENTER',
      },
      {
        id: 108,
        code: '108',
        name: 'MANAGEMENT CONSULTANCY',
      },
      {
        id: 244,
        code: '251',
        name: 'MANAGEMENT SERVICES',
      },
      {
        id: 409,
        code: '465',
        name: 'MANUFACTURE',
      },
      {
        id: 228,
        code: '229',
        name: 'MANUFACTURER FLOOR POLISH',
      },
      {
        id: 121,
        code: '121',
        name: 'MANUFACTURER JEWELLERY',
      },
      {
        id: 69,
        code: '069',
        name: 'MANUFACTURER PHARMACEUTICAL',
      },
      {
        id: 239,
        code: '245',
        name: 'MANUFACTURER TYRES',
      },
      {
        id: 137,
        code: '137',
        name: 'MANUFACTURING BAGS',
      },
      {
        id: 86,
        code: '086',
        name: 'MANUFACTURING HOSPITAL GARMENTS',
      },
      {
        id: 172,
        code: '172',
        name: 'MANUFACTURING OF PAINTS',
      },
      {
        id: 230,
        code: '231',
        name: 'MANUFACTURING SCHOOL UNIFORMS',
      },
      {
        id: 225,
        code: '226',
        name: 'MANUFACTURING WATER TANKS',
      },
      {
        id: 278,
        code: '290',
        name: 'MARKETING',
      },
      {
        id: 235,
        code: '236',
        name: 'MARKETING MARULA PRODUCTS',
      },
      {
        id: 249,
        code: '256',
        name: 'MARKETING OF CHARCOAL',
      },
      {
        id: 258,
        code: '265',
        name: 'MASSESES',
      },
      {
        id: 223,
        code: '224',
        name: 'MECHANICAL WORKS',
      },
      {
        id: 2,
        code: '002',
        name: 'MEDIA & PUBLICATIONS',
      },
      {
        id: 10,
        code: '010',
        name: 'MEDICAL SERVICES',
      },
      {
        id: 357,
        code: '411',
        name: 'METER READING',
      },
      {
        id: 183,
        code: '183',
        name: 'MILLERS & BARLY',
      },
      {
        id: 177,
        code: '177',
        name: 'MINING',
      },
      {
        id: 140,
        code: '140',
        name: 'MONEY LENDING',
      },
      {
        id: 322,
        code: '359',
        name: 'MOTOR VEHICLE',
      },
      {
        id: 9,
        code: '009',
        name: 'MOTOR VEHICLE REPAIR',
      },
      {
        id: 141,
        code: '141',
        name: 'MUSIC PRODUCTIONS',
      },
      {
        id: 337,
        code: '383',
        name: 'NATURAL GAS',
      },
      {
        id: 243,
        code: '250',
        name: 'NEGOTIATING CONTRACT',
      },
      {
        id: 390,
        code: '446',
        name: 'NETWORKING TECHNOLOGIES',
      },
      {
        id: 399,
        code: '455',
        name: 'NEW AND SECOND HAND TYRES',
      },
      {
        id: 7,
        code: '007',
        name: 'NEW CAR SALES',
      },
      {
        id: 48,
        code: '048',
        name: 'OFFICE AND SCHOLASTIC FURNITURES',
      },
      {
        id: 6,
        code: '006',
        name: 'OFFICE MACHINES',
      },
      {
        id: 222,
        code: '223',
        name: 'OIL CLEANERS',
      },
      {
        id: 193,
        code: '193',
        name: 'OIL REFINNING',
      },
      {
        id: 253,
        code: '260',
        name: 'OPERATE A SCHOOL',
      },
      {
        id: 49,
        code: '049',
        name: 'OPERATE FILLING STATIONS',
      },
      {
        id: 132,
        code: '132',
        name: 'OPERATE INTERNET CAFE',
      },
      {
        id: 217,
        code: '217',
        name: 'OPERATING A GUEST HOUSE',
      },
      {
        id: 155,
        code: '155',
        name: 'OPERATING RECORDING STUDIOS',
      },
      {
        id: 226,
        code: '227',
        name: 'OPERATING WEATHER STATION',
      },
      {
        id: 256,
        code: '263',
        name: 'ORTHOPARDISTS',
      },
      {
        id: 27,
        code: '027',
        name: 'PAINTING BUILDINGS',
      },
      {
        id: 159,
        code: '159',
        name: 'PAINTS',
      },
      {
        id: 403,
        code: '459',
        name: 'PALLIATIVE CARE',
      },
      {
        id: 173,
        code: '173',
        name: 'PANEL BEATERS',
      },
      {
        id: 133,
        code: '133',
        name: 'PAPER & DISPOSABLE NAPKINS',
      },
      {
        id: 221,
        code: '221',
        name: 'PAPER CONVERTERS',
      },
      {
        id: 301,
        code: '320',
        name: 'PAPERWORK',
      },
      {
        id: 285,
        code: '302',
        name: 'PASSENGER SERVICE',
      },
      {
        id: 245,
        code: '252',
        name: 'PAWN BROKING & SHYLOCKING',
      },
      {
        id: 294,
        code: '311',
        name: 'PEPSI COLA DISTRIBUTORS',
      },
      {
        id: 279,
        code: '291',
        name: 'PERFUMES',
      },
      {
        id: 21,
        code: '021',
        name: 'PEST CONTROL CHEMICALS',
      },
      {
        id: 383,
        code: '438',
        name: 'PETROLEUM PRODUCTS',
      },
      {
        id: 116,
        code: '116',
        name: 'PHOTOGRAPHIC & ELECTRONIC EQUIPMENT',
      },
      {
        id: 353,
        code: '407',
        name: 'PHYSICAL GYM AND SAUNA',
      },
      {
        id: 254,
        code: '261',
        name: 'PHYSIOTHERAPISTS',
      },
      {
        id: 259,
        code: '266',
        name: 'PICTURE FRAMING',
      },
      {
        id: 292,
        code: '309',
        name: 'PIPES AND FITTINGS',
      },
      {
        id: 264,
        code: '272',
        name: 'PLASTICS',
      },
      {
        id: 106,
        code: '106',
        name: 'PLUMBING MATERIAL',
      },
      {
        id: 67,
        code: '067',
        name: 'POTTERY',
      },
      {
        id: 185,
        code: '185',
        name: 'POULTRY',
      },
      {
        id: 40,
        code: '040',
        name: 'POULTRY & PIGGERY',
      },
      {
        id: 319,
        code: '355',
        name: 'POWER MANUFACTURING',
      },
      {
        id: 339,
        code: '386',
        name: 'PREACHING GOSPEL',
      },
      {
        id: 81,
        code: '081',
        name: 'PRINTERS & PUBLISHERS',
      },
      {
        id: 211,
        code: '211',
        name: 'PRIVATE INVESTIGATION',
      },
      {
        id: 382,
        code: '437',
        name: 'PRIVATE SCHOOL',
      },
      {
        id: 175,
        code: '175',
        name: 'PRODUCING SOAD AND CANDLES',
      },
      {
        id: 94,
        code: '094',
        name: 'PROJECT MANAGEMENT & FACILITIES',
      },
      {
        id: 85,
        code: '085',
        name: 'PROMULGATE THE GOSPEL OF JESUS CHRIST',
      },
      {
        id: 130,
        code: '130',
        name: 'PROPERTY DEVELOPMENT',
      },
      {
        id: 189,
        code: '189',
        name: 'PROPERTY OWNING',
      },
      {
        id: 291,
        code: '308',
        name: 'PROTECTIVE CLOTHING',
      },
      {
        id: 51,
        code: '051',
        name: 'PROVIDE CASH LOANS',
      },
      {
        id: 149,
        code: '149',
        name: 'PROVIDING ASSISTANCE TO SMALL BUSINESSES',
      },
      {
        id: 405,
        code: '461',
        name: 'PROVIDING SHELTER',
      },
      {
        id: 148,
        code: '148',
        name: 'PUBLIC RELATIONS',
      },
      {
        id: 274,
        code: '284',
        name: 'PUBLISHING',
      },
      {
        id: 43,
        code: '043',
        name: 'PULLING CABLES',
      },
      {
        id: 125,
        code: '125',
        name: 'RAILWAY CONSTRUCTORS & ENGINEERS',
      },
      {
        id: 163,
        code: '163',
        name: 'RECORDING KEEPING',
      },
      {
        id: 158,
        code: '158',
        name: 'RECORDING STUDIOS',
      },
      {
        id: 268,
        code: '276',
        name: 'RECRUITMENT OF EMPLOYEES',
      },
      {
        id: 275,
        code: '285',
        name: 'REFRIGERATION',
      },
      {
        id: 35,
        code: '035',
        name: 'REFUSE COLLECTION',
      },
      {
        id: 124,
        code: '124',
        name: 'RENT OUT TENTS, TARPS AND COVERS',
      },
      {
        id: 336,
        code: '382',
        name: 'REPAIRING ELEVATORS,ESCALATORS,LIFTS',
      },
      {
        id: 17,
        code: '017',
        name: 'REPAIRS',
      },
      {
        id: 246,
        code: '253',
        name: 'REPUTABLE ELEVATOR SERVICE',
      },
      {
        id: 379,
        code: '434',
        name: 'RESEARCH AND EMPOWERMENT SERVICES',
      },
      {
        id: 356,
        code: '410',
        name: 'RESEARCH DEVELOPMENT',
      },
      {
        id: 123,
        code: '123',
        name: 'RESTAURANT',
      },
      {
        id: 262,
        code: '270',
        name: 'RIVER SAND',
      },
      {
        id: 309,
        code: '345',
        name: 'ROAD CONSTRUCTION',
      },
      {
        id: 271,
        code: '280',
        name: 'SAFETY EQUIPMENT',
      },
      {
        id: 63,
        code: '063',
        name: 'SALE & DISTRIBUTION OF OXYGEN_& GAS',
      },
      {
        id: 162,
        code: '162',
        name: 'SAWMILL PROPRIETORS',
      },
      {
        id: 408,
        code: '464',
        name: 'SCAFFOLDING',
      },
      {
        id: 206,
        code: '206',
        name: 'SCHOOL ARTS',
      },
      {
        id: 270,
        code: '279',
        name: 'SCHOOL UNIFORMS',
      },
      {
        id: 240,
        code: '246',
        name: 'SCRAP METALS',
      },
      {
        id: 321,
        code: '358',
        name: 'SCREEN PAINTERS',
      },
      {
        id: 343,
        code: '391',
        name: 'SEA FOOD',
      },
      {
        id: 323,
        code: '360',
        name: 'SECRETARIAL SERVICES',
      },
      {
        id: 91,
        code: '091',
        name: 'SECURITIES',
      },
      {
        id: 47,
        code: '047',
        name: 'SELL SALON PRODUCTS',
      },
      {
        id: 54,
        code: '054',
        name: 'SELLING AUTO SPARE PARTS & ACCESSORIES',
      },
      {
        id: 363,
        code: '418',
        name: 'SELLING BETTERIES',
      },
      {
        id: 109,
        code: '109',
        name: 'SELLING BRUSH CUTTERS & MOTORBIKES',
      },
      {
        id: 46,
        code: '046',
        name: 'SELLING REAL ESTATES INCLUDING PLOTS',
      },
      {
        id: 331,
        code: '377',
        name: 'SEWING MACHINE',
      },
      {
        id: 247,
        code: '254',
        name: 'SHIPPING',
      },
      {
        id: 306,
        code: '341',
        name: 'SHOES',
      },
      {
        id: 68,
        code: '068',
        name: 'SHOPPING COMPLEX',
      },
      {
        id: 250,
        code: '257',
        name: 'SHYLOCKING',
      },
      {
        id: 95,
        code: '095',
        name: 'SIGN WRITING',
      },
      {
        id: 284,
        code: '301',
        name: 'SILVERWARE',
      },
      {
        id: 203,
        code: '203',
        name: 'SILVICULTURE',
      },
      {
        id: 328,
        code: '374',
        name: 'SOFTNERS',
      },
      {
        id: 299,
        code: '317',
        name: 'SOYA',
      },
      {
        id: 277,
        code: '289',
        name: 'SPECIALIST',
      },
      {
        id: 354,
        code: '408',
        name: 'SPORTS AND LEISURE WEAR',
      },
      {
        id: 174,
        code: '174',
        name: 'SPRAY PAINTERS',
      },
      {
        id: 82,
        code: '082',
        name: 'STATIONERS & BOOKBINDERS',
      },
      {
        id: 144,
        code: '144',
        name: 'STATIONERY',
      },
      {
        id: 96,
        code: '096',
        name: 'STEEL ENGINEERING',
      },
      {
        id: 169,
        code: '169',
        name: 'STEELWORKS AND FABRICATION',
      },
      {
        id: 304,
        code: '331',
        name: 'STORAGE',
      },
      {
        id: 113,
        code: '113',
        name: 'SUGAR PLANTATION',
      },
      {
        id: 227,
        code: '228',
        name: 'SUGAR PREPACKING',
      },
      {
        id: 5,
        code: '005',
        name: 'SUPERMARKET',
      },
      {
        id: 375,
        code: '430',
        name: 'SUPPLIERS OF CHALK',
      },
      {
        id: 372,
        code: '427',
        name: 'SUPPLYING & SERVICING SOUNDS SYSTEM',
      },
      {
        id: 229,
        code: '230',
        name: 'SUPPLYING CANDLES',
      },
      {
        id: 30,
        code: '030',
        name: 'SUPPLYING OF BUILDING MATERIALS',
      },
      {
        id: 350,
        code: '404',
        name: 'SUPPLYING OF MILLITARY EQUIPMENTS',
      },
      {
        id: 64,
        code: '064',
        name: 'SUPPORT CHARITABLE INSTITUTION',
      },
      {
        id: 196,
        code: '196',
        name: 'SURVEYORS AND PROJECT MANAGEMENT',
      },
      {
        id: 265,
        code: '273',
        name: 'TAILOR',
      },
      {
        id: 320,
        code: '356',
        name: 'TEA MANUFACTURING',
      },
      {
        id: 151,
        code: '151',
        name: 'TECHNICAL ASSISTANCE',
      },
      {
        id: 134,
        code: '134',
        name: 'TELECOMMUNICATION COMPONENTS',
      },
      {
        id: 366,
        code: '421',
        name: 'TELEVISION AND TRANSMISION',
      },
      {
        id: 300,
        code: '318',
        name: 'TERAPHY',
      },
      {
        id: 16,
        code: '016',
        name: 'TEXTILE',
      },
      {
        id: 201,
        code: '201',
        name: 'TILES MAKING',
      },
      {
        id: 297,
        code: '315',
        name: 'TIMBER',
      },
      {
        id: 190,
        code: '190',
        name: 'TOLLGATES',
      },
      {
        id: 266,
        code: '274',
        name: 'TOMBSTONES',
      },
      {
        id: 38,
        code: '038',
        name: 'TOOLS',
      },
      {
        id: 236,
        code: '237',
        name: 'TOURISM',
      },
      {
        id: 199,
        code: '199',
        name: 'TOYS',
      },
      {
        id: 105,
        code: '105',
        name: 'TRAINING IN MANAGEMENT SHORT COURSES',
      },
      {
        id: 61,
        code: '061',
        name: 'TRANSPORT',
      },
      {
        id: 111,
        code: '111',
        name: 'TRAVEL AGENTS',
      },
      {
        id: 179,
        code: '179',
        name: 'TRAVEL AGENTS',
      },
      {
        id: 204,
        code: '204',
        name: 'TRUCKING',
      },
      {
        id: 208,
        code: '208',
        name: 'TRUSTEE',
      },
      {
        id: 334,
        code: '380',
        name: 'UPHOLESTRY',
      },
      {
        id: 182,
        code: '182',
        name: 'VEDIO RECORDING',
      },
      {
        id: 215,
        code: '215',
        name: 'VISION CARE',
      },
      {
        id: 118,
        code: '118',
        name: 'WAREHOUSING OF HELICOPTERS',
      },
      {
        id: 241,
        code: '247',
        name: 'WATER CONSERVATION',
      },
      {
        id: 145,
        code: '145',
        name: 'WATER SHED MANAGEMENT',
      },
      {
        id: 355,
        code: '409',
        name: 'WATERPROOFING',
      },
      {
        id: 389,
        code: '445',
        name: 'WEBSITE DESINING',
      },
      {
        id: 282,
        code: '299',
        name: 'WEDDING SERVICES',
      },
      {
        id: 224,
        code: '225',
        name: 'WELDING',
      },
      {
        id: 402,
        code: '458',
        name: 'WHEEL BALANCING AND WHEEL ALIGNMENT',
      },
      {
        id: 15,
        code: '015',
        name: 'WHOLESALERS',
      },
      {
        id: 57,
        code: '057',
        name: 'WIRING',
      },
      {
        id: 260,
        code: '267',
        name: 'WOOD CAVING',
      },
      {
        id: 315,
        code: '351',
        name: 'WOOD MACHINES',
      },
      {
        id: 143,
        code: '143',
        name: 'WOODWORKS',
      },
    ],

    offices: [
      {
        id: 5,
        grand_prefix: 'LHL',
        shrot_desc: 'HLATHIKHULU',
      },
      {
        id: 6,
        grand_prefix: 'LMK',
        shrot_desc: 'MANKAYANE',
      },
      {
        id: 2,
        grand_prefix: 'LMZ',
        shrot_desc: 'MANZINI',
      },
      {
        id: 1,
        grand_prefix: 'LMB',
        shrot_desc: 'MBABANE',
      },
      {
        id: 4,
        grand_prefix: 'LNH',
        shrot_desc: 'NHLANGANO',
      },
      {
        id: 7,
        grand_prefix: 'LPK',
        shrot_desc: 'PIGGS PEAK',
      },
      {
        id: 8,
        grand_prefix: 'HHL',
        shrot_desc: 'SEZ HHOHHO',
      },
      {
        id: 10,
        grand_prefix: 'LUL',
        shrot_desc: 'SEZ LUBOMBO',
      },
      {
        id: 9,
        grand_prefix: 'SHL',
        shrot_desc: 'SEZ SHISELWENI',
      },
      {
        id: 3,
        grand_prefix: 'LST',
        shrot_desc: 'SITEKI',
      },
    ],

    charYesNoType: [
      { id: 'Y', name: 'Yes' },
      { id: 'N', name: 'No' },
    ],

    premisesOwners: [
      {
        id: 2,
        code: 'L',
        name: 'Leased',
      },
      {
        id: 1,
        code: 'O',
        name: 'Owned',
      },
    ],

    businessLands: [
      {
        id: 1,
        code: 'F',
        name: 'Formal',
      },
      {
        id: 2,
        code: 'I',
        name: 'Informal',
      },
    ],

    landType: [
      { id: '0', name: 'SNL' },
      { id: '1', name: 'TDL' },
    ],
    yesNoType: [
      { id: '1', name: 'Yes' },
      { id: '0', name: 'No' },
    ],
    nameOfArea: [
      {
        id: 1,
        name: 'Name of area 1',
      },
      {
        id: 2,
        name: 'Name of area 2',
      },
      {
        id: 3,
        name: 'Name of area 3',
      },
      {
        id: 4,
        name: 'Name of area 4',
      },
    ],
    phoneIndicators: [
      { id: 1, phoneIndicator: '+93', countryCode: 'AF' },
      { id: 2, phoneIndicator: '+355', countryCode: 'AL' },
      { id: 3, phoneIndicator: '+213', countryCode: 'DZ' },
      { id: 4, phoneIndicator: '+1-684', countryCode: 'AS' },
      { id: 5, phoneIndicator: '+376', countryCode: 'AD' },
      { id: 6, phoneIndicator: '+244', countryCode: 'AO' },
      { id: 7, phoneIndicator: '+1-264', countryCode: 'AI' },
      { id: 8, phoneIndicator: '+672', countryCode: 'AQ' },
      { id: 9, phoneIndicator: '+1-268', countryCode: 'AG' },
      { id: 10, phoneIndicator: '+54', countryCode: 'AR' },
      { id: 11, phoneIndicator: '+374', countryCode: 'AM' },
      { id: 12, phoneIndicator: '+297', countryCode: 'AW' },
      { id: 13, phoneIndicator: '+61', countryCode: 'AU' },
      { id: 14, phoneIndicator: '+43', countryCode: 'AT' },
      { id: 15, phoneIndicator: '+994', countryCode: 'AZ' },
      { id: 16, phoneIndicator: '+1-242', countryCode: 'BS' },
      { id: 17, phoneIndicator: '+973', countryCode: 'BH' },
      { id: 18, phoneIndicator: '+880', countryCode: 'BD' },
      { id: 19, phoneIndicator: '+1-246', countryCode: 'BB' },
      { id: 20, phoneIndicator: '+375', countryCode: 'BY' },
      { id: 21, phoneIndicator: '+32', countryCode: 'BE' },
      { id: 22, phoneIndicator: '+501', countryCode: 'BZ' },
      { id: 23, phoneIndicator: '+229', countryCode: 'BJ' },
      { id: 24, phoneIndicator: '+1-441', countryCode: 'BM' },
      { id: 25, phoneIndicator: '+975', countryCode: 'BT' },
      { id: 26, phoneIndicator: '+591', countryCode: 'BO' },
      { id: 27, phoneIndicator: '+387', countryCode: 'BA' },
      { id: 28, phoneIndicator: '+267', countryCode: 'BW' },
      { id: 29, phoneIndicator: '+55', countryCode: 'BR' },
      { id: 30, phoneIndicator: '+246', countryCode: 'IO' },
      { id: 31, phoneIndicator: '+1-284', countryCode: 'VG' },
      { id: 32, phoneIndicator: '+673', countryCode: 'BN' },
      { id: 33, phoneIndicator: '+359', countryCode: 'BG' },
      { id: 34, phoneIndicator: '+226', countryCode: 'BF' },
      { id: 35, phoneIndicator: '+257', countryCode: 'BI' },
      { id: 36, phoneIndicator: '+855', countryCode: 'KH' },
      { id: 37, phoneIndicator: '+237', countryCode: 'CM' },
      { id: 38, phoneIndicator: '+1', countryCode: 'CA' },
      { id: 39, phoneIndicator: '+238', countryCode: 'CV' },
      { id: 40, phoneIndicator: '+1-345', countryCode: 'KY' },
      { id: 41, phoneIndicator: '+236', countryCode: 'CF' },
      { id: 42, phoneIndicator: '+235', countryCode: 'TD' },
      { id: 43, phoneIndicator: '+56', countryCode: 'CL' },
      { id: 44, phoneIndicator: '+86', countryCode: 'CN' },
      { id: 45, phoneIndicator: '+61', countryCode: 'CX' },
      { id: 46, phoneIndicator: '+61', countryCode: 'CC' },
      { id: 47, phoneIndicator: '+57', countryCode: 'CO' },
      { id: 48, phoneIndicator: '+269', countryCode: 'KM' },
      { id: 49, phoneIndicator: '+682', countryCode: 'CK' },
      { id: 50, phoneIndicator: '+506', countryCode: 'CR' },
      { id: 51, phoneIndicator: '+385', countryCode: 'HR' },
      { id: 52, phoneIndicator: '+53', countryCode: 'CU' },
      { id: 53, phoneIndicator: '+599', countryCode: 'CW' },
      { id: 54, phoneIndicator: '+357', countryCode: 'CY' },
      { id: 55, phoneIndicator: '+420', countryCode: 'CZ' },
      { id: 56, phoneIndicator: '+243', countryCode: 'CD' },
      { id: 57, phoneIndicator: '+45', countryCode: 'DK' },
      { id: 58, phoneIndicator: '+253', countryCode: 'DJ' },
      { id: 59, phoneIndicator: '+1-767', countryCode: 'DM' },
      { id: 60, phoneIndicator: '+1-809', countryCode: 'DO' },
      { id: 61, phoneIndicator: '+1-829', countryCode: 'DO' },
      { id: 62, phoneIndicator: '+1-849', countryCode: 'DO' },
      { id: 63, phoneIndicator: '+670', countryCode: 'TL' },
      { id: 64, phoneIndicator: '+593', countryCode: 'EC' },
      { id: 65, phoneIndicator: '+20', countryCode: 'EG' },
      { id: 66, phoneIndicator: '+503', countryCode: 'SV' },
      { id: 67, phoneIndicator: '+240', countryCode: 'GQ' },
      { id: 68, phoneIndicator: '+291', countryCode: 'ER' },
      { id: 69, phoneIndicator: '+372', countryCode: 'EE' },
      { id: 70, phoneIndicator: '+251', countryCode: 'ET' },
      { id: 71, phoneIndicator: '+500', countryCode: 'FK' },
      { id: 72, phoneIndicator: '+298', countryCode: 'FO' },
      { id: 73, phoneIndicator: '+679', countryCode: 'FJ' },
      { id: 74, phoneIndicator: '+358', countryCode: 'FI' },
      { id: 75, phoneIndicator: '+33', countryCode: 'FR' },
      { id: 76, phoneIndicator: '+689', countryCode: 'PF' },
      { id: 77, phoneIndicator: '+241', countryCode: 'GA' },
      { id: 78, phoneIndicator: '+220', countryCode: 'GM' },
      { id: 79, phoneIndicator: '+995', countryCode: 'GE' },
      { id: 80, phoneIndicator: '+49', countryCode: 'DE' },
      { id: 81, phoneIndicator: '+233', countryCode: 'GH' },
      { id: 82, phoneIndicator: '+350', countryCode: 'GI' },
      { id: 83, phoneIndicator: '+30', countryCode: 'GR' },
      { id: 84, phoneIndicator: '+299', countryCode: 'GL' },
      { id: 85, phoneIndicator: '+1-473', countryCode: 'GD' },
      { id: 86, phoneIndicator: '+1-671', countryCode: 'GU' },
      { id: 87, phoneIndicator: '+502', countryCode: 'GT' },
      { id: 88, phoneIndicator: '+44-1481', countryCode: 'GG' },
      { id: 89, phoneIndicator: '+224', countryCode: 'GN' },
      { id: 90, phoneIndicator: '+245', countryCode: 'GW' },
      { id: 91, phoneIndicator: '+592', countryCode: 'GY' },
      { id: 92, phoneIndicator: '+509', countryCode: 'HT' },
      { id: 93, phoneIndicator: '+504', countryCode: 'HN' },
      { id: 94, phoneIndicator: '+852', countryCode: 'HK' },
      { id: 95, phoneIndicator: '+36', countryCode: 'HU' },
      { id: 96, phoneIndicator: '+354', countryCode: 'IS' },
      { id: 97, phoneIndicator: '+91', countryCode: 'IN' },
      { id: 98, phoneIndicator: '+62', countryCode: 'ID' },
      { id: 99, phoneIndicator: '+98', countryCode: 'IR' },
      { id: 100, phoneIndicator: '+964', countryCode: 'IQ' },
      { id: 101, phoneIndicator: '+353', countryCode: 'IE' },
      { id: 102, phoneIndicator: '+44-1624', countryCode: 'IM' },
      { id: 103, phoneIndicator: '+972', countryCode: 'IL' },
      { id: 104, phoneIndicator: '+39', countryCode: 'IT' },
      { id: 105, phoneIndicator: '+225', countryCode: 'CI' },
      { id: 106, phoneIndicator: '+1-876', countryCode: 'JM' },
      { id: 107, phoneIndicator: '+81', countryCode: 'JP' },
      { id: 108, phoneIndicator: '+44-1534', countryCode: 'JE' },
      { id: 109, phoneIndicator: '+962', countryCode: 'JO' },
      { id: 110, phoneIndicator: '+7', countryCode: 'KZ' },
      { id: 111, phoneIndicator: '+254', countryCode: 'KE' },
      { id: 112, phoneIndicator: '+686', countryCode: 'KI' },
      { id: 113, phoneIndicator: '+383', countryCode: 'XK' },
      { id: 114, phoneIndicator: '+965', countryCode: 'KW' },
      { id: 115, phoneIndicator: '+996', countryCode: 'KG' },
      { id: 116, phoneIndicator: '+856', countryCode: 'LA' },
      { id: 117, phoneIndicator: '+371', countryCode: 'LV' },
      { id: 118, phoneIndicator: '+961', countryCode: 'LB' },
      { id: 119, phoneIndicator: '+266', countryCode: 'LS' },
      { id: 120, phoneIndicator: '+231', countryCode: 'LR' },
      { id: 121, phoneIndicator: '+218', countryCode: 'LY' },
      { id: 122, phoneIndicator: '+423', countryCode: 'LI' },
      { id: 123, phoneIndicator: '+370', countryCode: 'LT' },
      { id: 124, phoneIndicator: '+352', countryCode: 'LU' },
      { id: 125, phoneIndicator: '+853', countryCode: 'MO' },
      { id: 126, phoneIndicator: '+389', countryCode: 'MK' },
      { id: 127, phoneIndicator: '+261', countryCode: 'MG' },
      { id: 128, phoneIndicator: '+265', countryCode: 'MW' },
      { id: 129, phoneIndicator: '+60', countryCode: 'MY' },
      { id: 130, phoneIndicator: '+960', countryCode: 'MV' },
      { id: 131, phoneIndicator: '+223', countryCode: 'ML' },
      { id: 132, phoneIndicator: '+356', countryCode: 'MT' },
      { id: 133, phoneIndicator: '+692', countryCode: 'MH' },
      { id: 134, phoneIndicator: '+222', countryCode: 'MR' },
      { id: 135, phoneIndicator: '+230', countryCode: 'MU' },
      { id: 136, phoneIndicator: '+262', countryCode: 'YT' },
      { id: 137, phoneIndicator: '+52', countryCode: 'MX' },
      { id: 138, phoneIndicator: '+691', countryCode: 'FM' },
      { id: 139, phoneIndicator: '+373', countryCode: 'MD' },
      { id: 140, phoneIndicator: '+377', countryCode: 'MC' },
      { id: 141, phoneIndicator: '+976', countryCode: 'MN' },
      { id: 142, phoneIndicator: '+382', countryCode: 'ME' },
      { id: 143, phoneIndicator: '+1-664', countryCode: 'MS' },
      { id: 144, phoneIndicator: '+212', countryCode: 'MA' },
      { id: 145, phoneIndicator: '+258', countryCode: 'MZ' },
      { id: 146, phoneIndicator: '+95', countryCode: 'MM' },
      { id: 147, phoneIndicator: '+264', countryCode: 'NA' },
      { id: 148, phoneIndicator: '+674', countryCode: 'NR' },
      { id: 149, phoneIndicator: '+977', countryCode: 'NP' },
      { id: 150, phoneIndicator: '+31', countryCode: 'NL' },
      { id: 151, phoneIndicator: '+599', countryCode: 'AN' },
      { id: 152, phoneIndicator: '+687', countryCode: 'NC' },
      { id: 153, phoneIndicator: '+64', countryCode: 'NZ' },
      { id: 154, phoneIndicator: '+505', countryCode: 'NI' },
      { id: 155, phoneIndicator: '+227', countryCode: 'NE' },
      { id: 156, phoneIndicator: '+234', countryCode: 'NG' },
      { id: 157, phoneIndicator: '+683', countryCode: 'NU' },
      { id: 158, phoneIndicator: '+850', countryCode: 'KP' },
      { id: 159, phoneIndicator: '+1-670', countryCode: 'MP' },
      { id: 160, phoneIndicator: '+47', countryCode: 'NO' },
      { id: 161, phoneIndicator: '+968', countryCode: 'OM' },
      { id: 162, phoneIndicator: '+92', countryCode: 'PK' },
      { id: 163, phoneIndicator: '+680', countryCode: 'PW' },
      { id: 164, phoneIndicator: '+970', countryCode: 'PS' },
      { id: 165, phoneIndicator: '+507', countryCode: 'PA' },
      { id: 166, phoneIndicator: '+675', countryCode: 'PG' },
      { id: 167, phoneIndicator: '+595', countryCode: 'PY' },
      { id: 168, phoneIndicator: '+51', countryCode: 'PE' },
      { id: 169, phoneIndicator: '+63', countryCode: 'PH' },
      { id: 170, phoneIndicator: '+64', countryCode: 'PN' },
      { id: 171, phoneIndicator: '+48', countryCode: 'PL' },
      { id: 172, phoneIndicator: '+351', countryCode: 'PT' },
      { id: 173, phoneIndicator: '+1-787', countryCode: 'PR' },
      { id: 174, phoneIndicator: '+1-939', countryCode: 'PR' },
      { id: 175, phoneIndicator: '+974', countryCode: 'QA' },
      { id: 176, phoneIndicator: '+242', countryCode: 'CG' },
      { id: 177, phoneIndicator: '+262', countryCode: 'RE' },
      { id: 178, phoneIndicator: '+40', countryCode: 'RO' },
      { id: 179, phoneIndicator: '+7', countryCode: 'RU' },
      { id: 180, phoneIndicator: '+250', countryCode: 'RW' },
      { id: 181, phoneIndicator: '+590', countryCode: 'BL' },
      { id: 182, phoneIndicator: '+290', countryCode: 'SH' },
      { id: 183, phoneIndicator: '+1-869', countryCode: 'KN' },
      { id: 184, phoneIndicator: '+1-758', countryCode: 'LC' },
      { id: 185, phoneIndicator: '+590', countryCode: 'MF' },
      { id: 186, phoneIndicator: '+508', countryCode: 'PM' },
      { id: 187, phoneIndicator: '+1-784', countryCode: 'VC' },
      { id: 188, phoneIndicator: '+685', countryCode: 'WS' },
      { id: 189, phoneIndicator: '+378', countryCode: 'SM' },
      { id: 190, phoneIndicator: '+239', countryCode: 'ST' },
      { id: 191, phoneIndicator: '+966', countryCode: 'SA' },
      { id: 192, phoneIndicator: '+221', countryCode: 'SN' },
      { id: 193, phoneIndicator: '+381', countryCode: 'RS' },
      { id: 194, phoneIndicator: '+248', countryCode: 'SC' },
      { id: 195, phoneIndicator: '+232', countryCode: 'SL' },
      { id: 196, phoneIndicator: '+65', countryCode: 'SG' },
      { id: 197, phoneIndicator: '+1-721', countryCode: 'SX' },
      { id: 198, phoneIndicator: '+421', countryCode: 'SK' },
      { id: 199, phoneIndicator: '+386', countryCode: 'SI' },
      { id: 200, phoneIndicator: '+677', countryCode: 'SB' },
      { id: 201, phoneIndicator: '+252', countryCode: 'SO' },
      { id: 202, phoneIndicator: '+27', countryCode: 'ZA' },
      { id: 203, phoneIndicator: '+82', countryCode: 'KR' },
      { id: 204, phoneIndicator: '+211', countryCode: 'SS' },
      { id: 205, phoneIndicator: '+34', countryCode: 'ES' },
      { id: 206, phoneIndicator: '+94', countryCode: 'LK' },
      { id: 207, phoneIndicator: '+249', countryCode: 'SD' },
      { id: 208, phoneIndicator: '+597', countryCode: 'SR' },
      { id: 209, phoneIndicator: '+47', countryCode: 'SJ' },
      { id: 210, phoneIndicator: '+268', countryCode: 'SZ' },
      { id: 211, phoneIndicator: '+46', countryCode: 'SE' },
      { id: 212, phoneIndicator: '+41', countryCode: 'CH' },
      { id: 213, phoneIndicator: '+963', countryCode: 'SY' },
      { id: 214, phoneIndicator: '+886', countryCode: 'TW' },
      { id: 215, phoneIndicator: '+992', countryCode: 'TJ' },
      { id: 216, phoneIndicator: '+255', countryCode: 'TZ' },
      { id: 217, phoneIndicator: '+66', countryCode: 'TH' },
      { id: 218, phoneIndicator: '+228', countryCode: 'TG' },
      { id: 219, phoneIndicator: '+690', countryCode: 'TK' },
      { id: 220, phoneIndicator: '+676', countryCode: 'TO' },
      { id: 221, phoneIndicator: '+1-868', countryCode: 'TT' },
      { id: 222, phoneIndicator: '+216', countryCode: 'TN' },
      { id: 223, phoneIndicator: '+90', countryCode: 'TR' },
      { id: 224, phoneIndicator: '+993', countryCode: 'TM' },
      { id: 225, phoneIndicator: '+1-649', countryCode: 'TC' },
      { id: 226, phoneIndicator: '+688', countryCode: 'TV' },
      { id: 227, phoneIndicator: '+1-340', countryCode: 'VI' },
      { id: 228, phoneIndicator: '+256', countryCode: 'UG' },
      { id: 229, phoneIndicator: '+380', countryCode: 'UA' },
      { id: 230, phoneIndicator: '+971', countryCode: 'AE' },
      { id: 231, phoneIndicator: '+44', countryCode: 'GB' },
      { id: 232, phoneIndicator: '+1', countryCode: 'US' },
      { id: 233, phoneIndicator: '+598', countryCode: 'UY' },
      { id: 234, phoneIndicator: '+998', countryCode: 'UZ' },
      { id: 235, phoneIndicator: '+678', countryCode: 'VU' },
      { id: 236, phoneIndicator: '+379', countryCode: 'VA' },
      { id: 237, phoneIndicator: '+58', countryCode: 'VE' },
      { id: 238, phoneIndicator: '+84', countryCode: 'VN' },
      { id: 239, phoneIndicator: '+681', countryCode: 'WF' },
      { id: 240, phoneIndicator: '+212', countryCode: 'EH' },
      { id: 241, phoneIndicator: '+967', countryCode: 'YE' },
      { id: 242, phoneIndicator: '+260', countryCode: 'ZM' },
      { id: 243, phoneIndicator: '+263', countryCode: 'ZW' },
    ],

    applicationForOptions: [
      { id: 1, name: 'Application for 1' },
      { id: 2, name: 'Application for 2' },
      { id: 3, name: 'Application for 3' },
      { id: 4, name: 'Application for 4' },
    ],
    contactMethods: [
      { id: 1, name: 'SMS' },
      { id: 2, name: 'Email' },
      { id: 3, name: 'Both (SMS and Email)' },
    ],
    applicationTypes: [
      { id: 1, name: 'Licence Application' },
      { id: 2, name: 'Renewal' },
    ],
    typeOfApplicant: [
      { id: 1, name: 'Person' },
      { id: 2, name: 'Company' },
    ],
    typeOfRegistration: [
      { id: 1, name: 'Individual Registration' },
      { id: 2, name: 'Company Registration' },
    ],
    regulationsNumbers: [
      { id: 1, name: '1233' },
      { id: 2, name: '1234' },
      { id: 3, name: '1235' },
      { id: 4, name: '1236' },
    ],
    countries: [
      { id: 2, name: 'Afghanistan' },
      { id: 5, name: 'Albania' },
      { id: 62, name: 'Algeria' },
      { id: 11, name: 'American Samoa' },
      { id: 6, name: 'Andorra' },
      { id: 3, name: 'Angola' },
      { id: 4, name: 'Anguilla' },
      { id: 12, name: 'Antarctica' },
      { id: 14, name: 'Antigua and Barbuda' },
      { id: 9, name: 'Argentina' },
      { id: 10, name: 'Armenia' },
      { id: 1, name: 'Aruba' },
      { id: 15, name: 'Australia' },
      { id: 16, name: 'Austria' },
      { id: 17, name: 'Azerbaijan' },
      { id: 25, name: 'Bahamas' },
      { id: 24, name: 'Bahrain' },
      { id: 22, name: 'Bangladesh' },
      { id: 32, name: 'Barbados' },
      { id: 27, name: 'Belarus' },
      { id: 19, name: 'Belgium' },
      { id: 28, name: 'Belize' },
      { id: 20, name: 'Benin' },
      { id: 29, name: 'Bermuda' },
      { id: 34, name: 'Bhutan' },
      { id: 30, name: 'Bolivia' },
      { id: 26, name: 'Bosnia and Herzegovina' },
      { id: 36, name: 'Botswana' },
      { id: 35, name: 'Bouvet Island' },
      { id: 31, name: 'Brazil' },
      { id: 101, name: 'British Indian Ocean Territory' },
      { id: 33, name: 'Brunei' },
      { id: 23, name: 'Bulgaria' },
      { id: 21, name: 'Burkina Faso' },
      { id: 18, name: 'Burundi' },
      { id: 114, name: 'Cambodia' },
      { id: 44, name: 'Cameroon' },
      { id: 38, name: 'Canada' },
      { id: 50, name: 'Cape Verde' },
      { id: 54, name: 'Cayman Islands' },
      { id: 37, name: 'Central African Republic' },
      { id: 206, name: 'Chad' },
      { id: 41, name: 'Chile' },
      { id: 42, name: 'China' },
      { id: 53, name: 'Christmas Island' },
      { id: 39, name: 'Cocos (Keeling) Islands' },
      { id: 48, name: 'Colombia' },
      { id: 49, name: 'Comoros' },
      { id: 46, name: 'Congo' },
      { id: 45, name: 'Congo, The Democratic Republic of the' },
      { id: 47, name: 'Cook Islands' },
      { id: 51, name: 'Costa Rica' },
      { id: 43, name: 'C\u00f4te d\u2019Ivoire' },
      { id: 96, name: 'Croatia' },
      { id: 52, name: 'Cuba' },
      { id: 55, name: 'Cyprus' },
      { id: 56, name: 'Czech Republic' },
      { id: 60, name: 'Denmark' },
      { id: 58, name: 'Djibouti' },
      { id: 59, name: 'Dominica' },
      { id: 61, name: 'Dominican Republic' },
      { id: 212, name: 'East Timor' },
      { id: 63, name: 'Ecuador' },
      { id: 64, name: 'Egypt' },
      { id: 193, name: 'El Salvador' },
      { id: 85, name: 'Equatorial Guinea' },
      { id: 65, name: 'Eritrea' },
      { id: 68, name: 'Estonia' },
      { id: 69, name: 'Ethiopia' },
      { id: 72, name: 'Falkland Islands' },
      { id: 74, name: 'Faroe Islands' },
      { id: 71, name: 'Fiji Islands' },
      { id: 70, name: 'Finland' },
      { id: 73, name: 'France' },
      { id: 90, name: 'French Guiana' },
      { id: 178, name: 'French Polynesia' },
      { id: 13, name: 'French Southern territories' },
      { id: 76, name: 'Gabon' },
      { id: 83, name: 'Gambia' },
      { id: 78, name: 'Georgia' },
      { id: 57, name: 'Germany' },
      { id: 79, name: 'Ghana' },
      { id: 80, name: 'Gibraltar' },
      { id: 86, name: 'Greece' },
      { id: 88, name: 'Greenland' },
      { id: 87, name: 'Grenada' },
      { id: 82, name: 'Guadeloupe' },
      { id: 91, name: 'Guam' },
      { id: 89, name: 'Guatemala' },
      { id: 81, name: 'Guinea' },
      { id: 84, name: 'Guinea-Bissau' },
      { id: 92, name: 'Guyana' },
      { id: 97, name: 'Haiti' },
      { id: 94, name: 'Heard Island and McDonald Islands' },
      { id: 226, name: 'Holy See (Vatican City State)' },
      { id: 95, name: 'Honduras' },
      { id: 93, name: 'Hong Kong' },
      { id: 98, name: 'Hungary' },
      { id: 105, name: 'Iceland' },
      { id: 100, name: 'India' },
      { id: 99, name: 'Indonesia' },
      { id: 103, name: 'Iran' },
      { id: 104, name: 'Iraq' },
      { id: 102, name: 'Ireland' },
      { id: 106, name: 'Israel' },
      { id: 107, name: 'Italy' },
      { id: 108, name: 'Jamaica' },
      { id: 110, name: 'Japan' },
      { id: 109, name: 'Jordan' },
      { id: 111, name: 'Kazakstan' },
      { id: 112, name: 'Kenya' },
      { id: 115, name: 'Kiribati' },
      { id: 118, name: 'Kuwait' },
      { id: 113, name: 'Kyrgyzstan' },
      { id: 119, name: 'Laos' },
      { id: 129, name: 'Latvia' },
      { id: 120, name: 'Lebanon' },
      { id: 126, name: 'Lesotho' },
      { id: 121, name: 'Liberia' },
      { id: 122, name: 'Libyan Arab Jamahiriya' },
      { id: 124, name: 'Liechtenstein' },
      { id: 127, name: 'Lithuania' },
      { id: 128, name: 'Luxembourg' },
      { id: 130, name: 'Macao' },
      { id: 138, name: 'Macedonia' },
      { id: 134, name: 'Madagascar' },
      { id: 149, name: 'Malawi' },
      { id: 150, name: 'Malaysia' },
      { id: 135, name: 'Maldives' },
      { id: 139, name: 'Mali' },
      { id: 140, name: 'Malta' },
      { id: 137, name: 'Marshall Islands' },
      { id: 147, name: 'Martinique' },
      { id: 145, name: 'Mauritania' },
      { id: 148, name: 'Mauritius' },
      { id: 151, name: 'Mayotte' },
      { id: 136, name: 'Mexico' },
      { id: 75, name: 'Micronesia, Federated States of' },
      { id: 133, name: 'Moldova' },
      { id: 132, name: 'Monaco' },
      { id: 142, name: 'Mongolia' },
      { id: 146, name: 'Montserrat' },
      { id: 131, name: 'Morocco' },
      { id: 144, name: 'Mozambique' },
      { id: 141, name: 'Myanmar' },
      { id: 152, name: 'Namibia' },
      { id: 162, name: 'Nauru' },
      { id: 161, name: 'Nepal' },
      { id: 159, name: 'Netherlands' },
      { id: 7, name: 'Netherlands Antilles' },
      { id: 153, name: 'New Caledonia' },
      { id: 163, name: 'New Zealand' },
      { id: 157, name: 'Nicaragua' },
      { id: 154, name: 'Niger' },
      { id: 156, name: 'Nigeria' },
      { id: 158, name: 'Niue' },
      { id: 155, name: 'Norfolk Island' },
      { id: 174, name: 'North Korea' },
      { id: 143, name: 'Northern Mariana Islands' },
      { id: 160, name: 'Norway' },
      { id: 164, name: 'Oman' },
      { id: 165, name: 'Pakistan' },
      { id: 170, name: 'Palau' },
      { id: 177, name: 'Palestine' },
      { id: 166, name: 'Panama' },
      { id: 171, name: 'Papua New Guinea' },
      { id: 176, name: 'Paraguay' },
      { id: 168, name: 'Peru' },
      { id: 169, name: 'Philippines' },
      { id: 167, name: 'Pitcairn' },
      { id: 172, name: 'Poland' },
      { id: 175, name: 'Portugal' },
      { id: 173, name: 'Puerto Rico' },
      { id: 179, name: 'Qatar' },
      { id: 180, name: 'R\u00e9union' },
      { id: 181, name: 'Romania' },
      { id: 182, name: 'Russian Federation' },
      { id: 183, name: 'Rwanda' },
      { id: 189, name: 'Saint Helena' },
      { id: 116, name: 'Saint Kitts and Nevis' },
      { id: 123, name: 'Saint Lucia' },
      { id: 196, name: 'Saint Pierre and Miquelon' },
      { id: 227, name: 'Saint Vincent and the Grenadines' },
      { id: 234, name: 'Samoa' },
      { id: 194, name: 'San Marino' },
      { id: 197, name: 'Sao Tome and Principe' },
      { id: 184, name: 'Saudi Arabia' },
      { id: 186, name: 'Senegal' },
      { id: 203, name: 'Seychelles' },
      { id: 192, name: 'Sierra Leone' },
      { id: 187, name: 'Singapore' },
      { id: 199, name: 'Slovakia' },
      { id: 200, name: 'Slovenia' },
      { id: 191, name: 'Solomon Islands' },
      { id: 195, name: 'Somalia' },
      { id: 237, name: 'South Africa' },
      { id: 188, name: 'South Georgia and the South Sandwich Islands' },
      { id: 117, name: 'South Korea' },
      { id: 67, name: 'Spain' },
      { id: 125, name: 'Sri Lanka' },
      { id: 185, name: 'Sudan' },
      { id: 198, name: 'Suriname' },
      { id: 190, name: 'Svalbard and Jan Mayen' },
      { id: 202, name: 'Swaziland' },
      { id: 201, name: 'Sweden' },
      { id: 40, name: 'Switzerland' },
      { id: 204, name: 'Syria' },
      { id: 218, name: 'Taiwan' },
      { id: 209, name: 'Tajikistan' },
      { id: 219, name: 'Tanzania' },
      { id: 208, name: 'Thailand' },
      { id: 207, name: 'Togo' },
      { id: 210, name: 'Tokelau' },
      { id: 213, name: 'Tonga' },
      { id: 214, name: 'Trinidad and Tobago' },
      { id: 215, name: 'Tunisia' },
      { id: 216, name: 'Turkey' },
      { id: 211, name: 'Turkmenistan' },
      { id: 205, name: 'Turks and Caicos Islands' },
      { id: 217, name: 'Tuvalu' },
      { id: 220, name: 'Uganda' },
      { id: 221, name: 'Ukraine' },
      { id: 8, name: 'United Arab Emirates' },
      { id: 77, name: 'United Kingdom' },
      { id: 224, name: 'United States' },
      { id: 222, name: 'United States Minor Outlying Islands' },
      { id: 223, name: 'Uruguay' },
      { id: 225, name: 'Uzbekistan' },
      { id: 232, name: 'Vanuatu' },
      { id: 228, name: 'Venezuela' },
      { id: 231, name: 'Vietnam' },
      { id: 229, name: 'Virgin Islands, British' },
      { id: 230, name: 'Virgin Islands, U.S.' },
      { id: 233, name: 'Wallis and Futuna' },
      { id: 66, name: 'Western Sahara' },
      { id: 235, name: 'Yemen' },
      { id: 236, name: 'Yugoslavia' },
      { id: 238, name: 'Zambia' },
      { id: 239, name: 'Zimbabwe' },
    ],
    districts: [
      { id: 1, name: 'Hhohho' },
      { id: 2, name: 'Lubombo' },
      { id: 3, name: 'Manzini' },
      { id: 4, name: 'Shiselweni' },
    ],
    businessStyles: [
      { id: 1, name: 'Business style 1' },
      { id: 2, name: 'Business style 2' },
      { id: 3, name: 'Business style 3' },
      { id: 4, name: 'Business style 4' },
    ],

    privilegesOptions: [
      { id: 1, name: 'privileges 1' },
      { id: 2, name: 'privileges 2' },
      { id: 3, name: 'privileges 3' },
      { id: 4, name: 'privileges 4' },
    ],
    citizenships: [
      { id: 1, name: 'Swazi' },
      { id: 2, name: 'Zulu' },
      { id: 3, name: 'Portuguese' },
      { id: 4, name: 'British' },
    ],
    municipalities: [
      { id: 1, name: 'Dikgatlong' },
      { id: 2, name: 'Emthanjeni' },
      { id: 3, name: 'Frances Baard' },
      { id: 4, name: 'Ga-segonyana' },
      { id: 5, name: 'Gamagara' },
      { id: 6, name: 'Hantam' },
      { id: 7, name: 'Joe Morolong' },
      { id: 8, name: 'John Taolo Gaetsewe' },
      { id: 9, name: 'Kai Garib' },
      { id: 10, name: 'Kamiesberg' },
      { id: 11, name: 'Kareeberg' },
      { id: 12, name: 'Karoo Hoogland' },
      { id: 13, name: 'Kgatelopele' },
      { id: 14, name: 'Khai Ma' },
      { id: 15, name: 'Khara Hais' },
      { id: 16, name: 'Kheis' },
      { id: 17, name: 'Magareng' },
      { id: 18, name: 'Mier' },
      { id: 19, name: 'Nama-khoi' },
      { id: 20, name: 'Namakwa' },
      { id: 21, name: 'Phokwane' },
      { id: 22, name: 'Pixley Ka Seme' },
      { id: 23, name: 'Renosterburg' },
      { id: 24, name: 'Richtersveld' },
      { id: 25, name: 'Siyancuma' },
      { id: 26, name: 'Siyathemba' },
      { id: 27, name: 'Sol Plaatje' },
      { id: 28, name: 'Thembelihle' },
      { id: 29, name: 'Tsantsabane' },
      { id: 30, name: 'Ubuntu' },
      { id: 31, name: 'Umsobomvu' },
      { id: 32, name: 'ZF Mgcawu' },
    ],
  };
  return {
    applicationFormWizardInitialStates,
    applicationFormInitialStates,
    applicationFormDraftInitialStates,
    applicationFormHelperListsInitialStates,
  };
};

export default useApplicationFormInitialStates;
