import iconArrowDown from './iconArrowDown';
import iconArrowLearnMore from './iconArrowLearnMore';
import iconArrowLeft from './iconArrowLeft';
import iconArrowUpMenu from './iconArrowUpMenu';
import iconAt from './iconAt';
import iconBlogs from './iconBlogs';
import iconCalendar from './iconCalendar';
import iconCheckBox from './iconCheckBox';
import iconCheckedCheckBox from './iconCheckedCheckBox';
import iconCircularRadio from './iconCircularRadio';
import iconCircularRadioChecked from './iconCircularRadioChecked';

import iconClose from './iconClose';
import iconClosedEye from './iconClosedEye';
import iconComments from './iconComments';
import iconDelete from './iconDelete';
import iconInstagram from './iconInstagram';
import iconFacebook from './iconFacebook';
import iconTwitter from './iconTwitter';
import iconNotification from './iconNotification';
import iconGeneralLicense from './iconGeneralLicense';
import iconMarker from './iconMarker';
import iconWhatsApp from './iconWhatsApp';
import iconTelephone from './iconTelephone';
import iconLogo from './iconLogo';
import iconLogoMobile from './iconLogoMobile';
import iconSuccess from './iconSuccess';
import iconError from './iconError';
import iconHamMenu from './iconHamMenu';
import iconOpenedEye from './iconOpenedEye';
import iconArrowSelect from './iconArrowSelect';
import iconPhoto from './iconPhoto';
import iconMyAplication from './iconMyAplication';
import iconMyProfile from './iconMyProfile';
import iconLogout from './iconLogout';
import iconRadioChecked from './iconRadioChecked';
import iconRadio from './iconRadio';
import iconTooltip from './iconTooltip';
import iconUploadFile from './iconUploadFile';
import iconWizardStep from './iconWizardStep';
import iconDownload from './iconDownload';
import iconUsers from './iconUsers';
import iconLogs from './iconLogs';
import iconSeal from './iconSeal';
import iconSearch from './iconSearch';
import iconFilter from './iconFilter';
import iconEdit from './iconEdit';
import iconNewNotification from './iconNewNotification';
import iconSwitch from './iconSwitch';
import iconWarning from './iconWarning';
import iconPlay from './iconPlay';
import iconPause from './iconPause';
import iconReply from './iconReply';
import iconWatch from './iconWatch';
import iconCancel from './iconCancel';
import iconOk from './iconOk';
import iconTimer from './iconTimer';

import iconInfo from './iconInfo/index';
import iconInfoCircle from './iconInfo/indexCircle';

import iconGov from './iconGov';

const useIcons = () => {
  return {
    iconArrowDown,
    iconArrowLearnMore,
    iconArrowSelect,
    iconArrowUpMenu,
    iconAt,
    iconBlogs,
    iconCircularRadio,
    iconCircularRadioChecked,
    iconFacebook,
    iconInstagram,
    iconTwitter,
    iconNotification,
    iconGeneralLicense,
    iconMarker,
    iconWhatsApp,
    iconDownload,
    iconTelephone,
    iconLogo,
    iconLogoMobile,
    iconSuccess,
    iconClose,
    iconError,
    iconCheckBox,
    iconCheckedCheckBox,
    iconHamMenu,
    iconClosedEye,
    iconOpenedEye,
    iconPhoto,
    iconMyAplication,
    iconMyProfile,
    iconLogout,
    iconArrowLeft,
    iconRadio,
    iconRadioChecked,
    iconTooltip,
    iconUploadFile,
    iconWizardStep,
    iconUsers,
    iconLogs,
    iconSearch,
    iconSeal,
    iconFilter,
    iconEdit,
    iconDelete,
    iconComments,
    iconNewNotification,
    iconSwitch,
    iconCalendar,
    iconWarning,
    iconPlay,
    iconPause,
    iconReply,
    iconWatch,
    iconInfo,
    iconInfoCircle,
    iconCancel,
    iconOk,
    iconTimer,
    iconGov
  };
};

export default useIcons;
