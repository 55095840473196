const votingTypes = () => {
  const SAVE_VOTE_TIME = 'SAVE_VOTE_TIME';
  const GET_VOTE_TIME = 'GET_VOTE_TIME';
  const VOTE_APPLICATION = 'VOTE_APPLICATION';
  const SET_STOPWATCH_STATE = 'SET_STOPWATCH_STATE';
  const SET_STOPWATCH_CURRENT_TIME = 'SET_STOPWATCH_CURRENT_TIME';

  return {
    SAVE_VOTE_TIME,
    GET_VOTE_TIME,
    VOTE_APPLICATION,
    SET_STOPWATCH_STATE,
    SET_STOPWATCH_CURRENT_TIME,
  };
};
export default votingTypes;
