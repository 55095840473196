import React from 'react';

const iconCancel= ({ color = '#B6BBC1', size = 1 }) => {
  return (
    <svg
      width={(size * 20).toString()}
      height={(size * 20).toString()}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M10.0004 19.5999C12.5465 19.5999 14.9883 18.5885 16.7886 16.7881C18.589 14.9878 19.6004 12.546 19.6004 9.9999C19.6004 7.45382 18.589 5.01203 16.7886 3.21168C14.9883 1.41133 12.5465 0.399902 10.0004 0.399902C7.45431 0.399902 5.01252 1.41133 3.21217 3.21168C1.41182 5.01203 0.400391 7.45382 0.400391 9.9999C0.400391 12.546 1.41182 14.9878 3.21217 16.7881C5.01252 18.5885 7.45431 19.5999 10.0004 19.5999ZM8.44879 6.7515C8.22247 6.53291 7.91935 6.41196 7.60471 6.41469C7.29007 6.41743 6.9891 6.54363 6.76661 6.76612C6.54412 6.98861 6.41792 7.28959 6.41518 7.60422C6.41245 7.91886 6.5334 8.22198 6.75199 8.4483L8.30359 9.9999L6.75199 11.5515C6.63738 11.6622 6.54596 11.7946 6.48307 11.941C6.42018 12.0874 6.38707 12.2449 6.38569 12.4042C6.38431 12.5636 6.41467 12.7216 6.475 12.869C6.53534 13.0165 6.62445 13.1505 6.73712 13.2632C6.84979 13.3758 6.98377 13.465 7.13125 13.5253C7.27872 13.5856 7.43674 13.616 7.59607 13.6146C7.75541 13.6132 7.91287 13.5801 8.05928 13.5172C8.20568 13.4543 8.33809 13.3629 8.44879 13.2483L10.0004 11.6967L11.552 13.2483C11.7783 13.4669 12.0814 13.5878 12.3961 13.5851C12.7107 13.5824 13.0117 13.4562 13.2342 13.2337C13.4567 13.0112 13.5829 12.7102 13.5856 12.3956C13.5883 12.0809 13.4674 11.7778 13.2488 11.5515L11.6972 9.9999L13.2488 8.4483C13.4674 8.22198 13.5883 7.91886 13.5856 7.60422C13.5829 7.28959 13.4567 6.98861 13.2342 6.76612C13.0117 6.54363 12.7107 6.41743 12.3961 6.41469C12.0814 6.41196 11.7783 6.53291 11.552 6.7515L10.0004 8.3031L8.44879 6.7515Z" 
        fill={color}
      />
    </svg>
  );
};

export default iconCancel;
