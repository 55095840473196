import React from 'react';

const iconDownload = ({ color = '#394D94', size = 1 }) => {
  return (
    <svg
      width={(size * 25).toString()}
      height={(size * 21).toString()}
      viewBox="0 0 25 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.6797 11.9016V17.3115C24.6797 18.7869 23.4502 20.0164 21.9748 20.0164H3.38461C1.9092 20.0164 0.679688 18.7869 0.679688 17.3115V11.9016C0.679688 11.5082 1.02395 11.1639 1.41739 11.1639C1.81083 11.1639 2.1551 11.5082 2.1551 11.9016V17.3115C2.1551 18 2.69608 18.541 3.38461 18.541H21.9748C22.6633 18.541 23.2043 18 23.2043 17.3115V11.9016C23.2043 11.5082 23.5485 11.1639 23.942 11.1639C24.3354 11.1639 24.6797 11.459 24.6797 11.9016ZM11.2535 14.2131C11.6469 14.6066 12.1879 14.8033 12.6797 14.8033C13.1715 14.8033 13.7125 14.6066 14.1059 14.2131L17.5485 10.7705C17.8436 10.4754 17.8436 10.0328 17.5485 9.7377C17.2535 9.44262 16.8108 9.44262 16.5158 9.7377L13.4174 12.8361V0.737705C13.4174 0.344262 13.0731 0 12.6797 0C12.2862 0 11.942 0.344262 11.942 0.737705V12.7869L8.84362 9.68852C8.54854 9.39344 8.10592 9.39344 7.81084 9.68852C7.51575 9.98361 7.51575 10.4262 7.81084 10.7213L11.2535 14.2131Z"
        fill={color}
      />
    </svg>
  );
};

export default iconDownload;
