import React from 'react';

const iconGov = ({ color = '#FFFFFF', size = 1 }) => {
  return (
    <svg
      width={(size * 25).toString()}
      height={(size * 25).toString()}
      viewBox="0 0 500 500"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
          <path d="m244.5,0c-135.2,0-244.5,109.3-244.5,244.5s109.3,244.5 244.5,244.5 244.5-109.3 244.5-244.5-109.3-244.5-244.5-244.5zm-73.8,33.3c-12.9,18.1-23.9,37.2-33.1,57.2h-55.5c24.2-25.6 54.5-45.4 88.6-57.2zm-106.1,78h64.2c-15.1,38.9-23.4,80.4-24.6,122.8h-83.1c2.1-45.9 17.9-88.2 43.5-122.8zm0,266.4c-25.6-34.6-41.4-76.9-43.5-122.8h83.1c1.2,42.4 9.8,83.9 25,122.8h-64.6zm17.5,20.8h56c9.3,20 20.3,39.3 33,57.4-34.2-11.8-64.7-31.7-89-57.4zm152,69.5c-10.4-0.5-20.7-1.7-30.7-3.5-16.6-20.5-30.8-42.6-42.3-65.9h73v69.4zm-0-90.3h-82.5c-16.1-38.8-25.3-80.3-26.6-122.8h109.1v122.8zm0-143.6h-109.1c1.3-42.4 10.4-84 26.4-122.8h82.7v122.8zm.1-143.6h-0.1-73.2c11.6-23.5 25.8-45.6 42.5-65.9 10-1.9 20.3-3 30.8-3.5v69.4zm190.2,20.8c25.6,34.6 41.5,76.8 43.5,122.8h-83.1c-1.2-42.4-9.8-83.9-25-122.8h64.6zm-17.5-20.8h-56c-9.3-20-20.3-39.3-33.1-57.4 34.3,11.8 64.8,31.7 89.1,57.4zm-152-69.4c10.4,0.5 20.7,1.7 30.7,3.5 16.6,20.5 30.8,42.6 42.3,65.9h-73v-69.4zm-0,90.2h82.5c16.1,38.7 25.3,80.3 26.6,122.8h-109.1v-122.8zm0,143.6h109.1c-1.3,42.4-10.4,84-26.4,122.8h-82.7l-0-122.8zm-.1,213v-69.4h0.1 73.2c-11.6,23.5-25.8,45.6-42.5,65.9-10,1.9-20.3,3-30.8,3.5zm63-12c12.7-18.1 23.7-37.4 32.9-57.4h56.3c-24.4,25.7-54.9,45.6-89.2,57.4zm41.7-78.2c15-38.9 23.5-80.4 25.2-122.8h83.3c-2.1,45.9-17.9,88.2-43.5,122.8h-65z"/>
    </svg>
  );
};






export default iconGov;
