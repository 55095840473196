// import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import useApi from '../../../api';
import useHelpers from '../../../helpers';
import useModels from '../../../models';

const useApplicationTrackingInformation = () => {
  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { usePromises, useNavigation, useBase64ToBlobUrl } =
    useQuickFunctions();
  const { navigateTo } = useNavigation();
  const {
    promiseInProgressArea: promiseGetPrivateDocumentTrackingInformation,
  } = usePromises('getPrivateDocumentTrackingInformation');
  const { promiseInProgressArea: promiseGetAdvertisingFeeMTN } = usePromises(
    'getAdvertisingFeeMTN'
  );
  // Api
  const { useActions } = useApi();
  const { dispatch, usePrivateDocumentsActions, useApplicationReviewActions } =
    useActions();
  const { actGetPrivateDocumentTrackingInformation } =
    usePrivateDocumentsActions();
  const { actGetAdvertisingFeeMTN } = useApplicationReviewActions();
  // Models
  const { useSelectors } = useModels();
  const { useSelector, useApplicationFormSelectors, useUserSelectors } =
    useSelectors();
  const { applicationFormHelpDataSelector, applicationFormSelector } =
    useApplicationFormSelectors();
  const [individualBusinessTypeState, setIndividualBusinessTypeState] =
    useState(0);
  const { userSelector } = useUserSelectors();
  const {
    licenceTypes,
    businessRelationships,
    natureOfLicences,
    areas,
    businessNatureTypes,
    businessPropertys,
    offices,
    premisesOwners,
    businessLands,
    charYesNoType,
    typeOfRegistration,
  } = useSelector(applicationFormHelpDataSelector);
  const { applicationForm } = useSelector(applicationFormSelector);
  const { profile, userRolesList } = useSelector(userSelector);

  // hooks

  // React

  /**Handlers */
  // handler: get value from id
  const handleListValue = (id, list) => {
    const value = _.find(list, (listRow) => listRow.id === id);
    return value ? value : '';
  };

  // handler: comment title
  const handleCommentTitle = () => {
    const commentBy = `${
      applicationForm.requestForChangesComment.commentMadeBy.name
    } ${applicationForm.requestForChangesComment.commentMadeBy?.surname} - ${
      applicationForm.requestForChangesComment.commentMadeBy.roleId &&
      handleUserRoleType(
        applicationForm.requestForChangesComment.commentMadeBy.roleId
      )
    }`;
    switch (applicationForm.status.id) {
      case 7:
        return `Application for changes required by ${commentBy}`;
      case 9:
        return `This Application was Rejected by the Eswatini Liquor Licensing`;
      case 13:
        return `Request for Issuance Fee Payment changes required by ${commentBy}`;
      case 15:
        return `Request for Advertising Fee Payment changes required by ${commentBy}`;
      case 18:
        return `Request for Inspection report changes required by ${commentBy}`;
      default:
        break;
    }
  };
  // handler: user role type
  const handleUserRoleType = (roleId) => {
    return _.find(userRolesList, (userRole) => userRole.id === roleId).name;
  };
  // Handlers: Phone field
  const handleIndicative = (choice, phone) => {
    const indicativeCode = choice.phoneIndicator
      ? choice.phoneIndicator.replace(/\D/g, '')
      : '268';
    const finalPhone = `(+${indicativeCode}) ${phone}`;
    return finalPhone;
  };

  // Handlers: redirects
  const handleRedirectToApplicationForm = () => {
    navigateTo(`/application-form`);
  };

  // Handlers: private document
  const handleGetPrivateLink = (url) => {
    window.open(url);
  };

  const handleGetPrivateDocumentView = (documentName) => {
    dispatch(
      actGetPrivateDocumentTrackingInformation(
        {
          documentName: documentName.replace(/\.[^/.]+$/, '') + '.pdf',
          applicationId: applicationForm.id,
        },
        handleGetPrivateLink
      )
    );
  };

  const handleSetAdvertisingFeeMtnPdf = (base64) => {
    const { url } = useBase64ToBlobUrl(base64);
    window.open(url);
  };

  const handleGetAdvertisingFeeMtn = () => {
    dispatch(
      actGetAdvertisingFeeMTN(applicationForm.id, handleSetAdvertisingFeeMtnPdf)
    );
  };

  const handleBusinessIndividual = () => {
    // Individual Business + Urban area + Privately-Owned = ID + Title Deed (individualBusinessType = 1)
    if (
      applicationForm.business_relationship_id == 1 &&
      applicationForm.business_property_id == 2 &&
      applicationForm.premises_owner_id == '1' &&
      applicationForm.personal_identification_number[7] != 0
    ) {
      setIndividualBusinessTypeState(1);
      // Individual Business + Urban area + Leased-Owned = ID + Title Deed (individualBusinessType = 1)
    } else if (
      applicationForm.business_relationship_id == 1 &&
      applicationForm.business_property_id == 2 &&
      applicationForm.premises_owner_id == '2' &&
      applicationForm.personal_identification_number[7] != 0
    ) {
      setIndividualBusinessTypeState(2);
    } else if (
      applicationForm.business_relationship_id == 1 &&
      applicationForm.business_property_id == 2 &&
      applicationForm.premises_owner_id == '1' &&
      applicationForm.personal_identification_number[7] == 0
    ) {
      setIndividualBusinessTypeState(3);
      // Individual Business + Urban area + Leased-Owned = ID + Title Deed (individualBusinessType = 1)
    } else if (
      applicationForm.business_relationship_id == 1 &&
      applicationForm.business_property_id == 2 &&
      applicationForm.premises_owner_id == '2' &&
      applicationForm.personal_identification_number[7] == 0
    ) {
      setIndividualBusinessTypeState(4);
    } else {
      setIndividualBusinessTypeState(0);
    }
  };

  // Only to revalidate the Licence required documents
  useEffect(() => {
    handleBusinessIndividual();
  }, [
    applicationForm.business_relationship_id,
    applicationForm.business_property_id,
    applicationForm.premises_owner_id,
  ]);
  return {
    promiseGetPrivateDocumentTrackingInformation,
    promiseGetAdvertisingFeeMTN,
    profile,

    licenceTypes,
    businessRelationships,
    natureOfLicences,
    areas,
    businessNatureTypes,
    businessPropertys,
    offices,
    premisesOwners,
    businessLands,
    charYesNoType,
    typeOfRegistration,
    individualBusinessTypeState,

    handleListValue,

    applicationForm,

    handleCommentTitle,

    handleRedirectToApplicationForm,

    handleIndicative,
    handleGetPrivateDocumentView,
    handleGetAdvertisingFeeMtn,
  };
};

export default useApplicationTrackingInformation;
