import React from 'react';

const iconReply = ({ color = '#394D94', size = 1 }) => {
  return (
    <svg
      width={(size * 16).toString()}
      height={(size * 12).toString()}
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9999 3.99962H2.27592L5.13792 1.13762C5.25936 1.01189 5.32655 0.843484 5.32504 0.668686C5.32352 0.493888 5.2534 0.32668 5.1298 0.203075C5.00619 0.0794693 4.83898 0.00935665 4.66419 0.0078377C4.48939 0.00631876 4.32099 0.073515 4.19525 0.194954L0.195253 4.19495C0.073132 4.32147 0.00488281 4.49045 0.00488281 4.66629C0.00488281 4.84213 0.073132 5.0111 0.195253 5.13762L4.19525 9.13762C4.32153 9.26021 4.49059 9.32877 4.66659 9.32877C4.84258 9.32877 5.01164 9.26021 5.13792 9.13762C5.2629 9.0126 5.33311 8.84306 5.33311 8.66629C5.33311 8.48951 5.2629 8.31997 5.13792 8.19495L2.27592 5.33295H13.9999C14.1767 5.33295 14.3463 5.40319 14.4713 5.52822C14.5963 5.65324 14.6666 5.82281 14.6666 5.99962V11.333C14.6666 11.5098 14.7368 11.6793 14.8618 11.8044C14.9869 11.9294 15.1564 11.9996 15.3333 11.9996C15.5101 11.9996 15.6796 11.9294 15.8047 11.8044C15.9297 11.6793 15.9999 11.5098 15.9999 11.333V5.99962C15.9999 5.46919 15.7892 4.96048 15.4141 4.58541C15.0391 4.21033 14.5304 3.99962 13.9999 3.99962Z"
        fill={color}
      />
    </svg>
  );
};

export default iconReply;
