const applicationRenewalsTypes = () => {
  const SET_CURRENT_RENEWAL_FORM_STEP = 'SET_CURRENT_RENEWAL_FORM_STEP';
  const SET_CURRENT_RENEWAL_FORM_RENEW_STEP = 'SET_CURRENT_RENEWAL_FORM_RENEW_STEP';
  const SET_ACTIVE_RENEWAL_FORM_STEPS = 'SET_ACTIVE_RENEWAL_FORM_STEPS';
  const CANCEL_RENEWAL = 'CANCEL_RENEWAL';
  const CANCEL_RENEWAL_RENEW = 'CANCEL_RENEWAL_RENEW';

  const GET_APPLICATIONS_RENEWALS_TABLE = 'GET_APPLICATIONS_RENEWALS_TABLE';

  const GET_APPLICATION_RENEWAL = 'GET_APPLICATION_RENEWAL';
  const APPLICATION_RENEWAL_READY = 'APPLICATION_RENEWAL_READY';
  const SUBMIT_APPLICATION_RENEWAL = 'SUBMIT_APPLICATION_RENEWAL';
  const SAVE_APPLICATION_RENEWAL = 'SAVE_APPLICATION_RENEWAL';
  const UPDATE_APPLICATION_RENEWAL = 'UPDATE_APPLICATION_RENEWAL';
  const DELETE_APPLICATION_RENEWAL = 'DELETE_APPLICATION_RENEWAL';
  const APPROVE_APPLICATION_RENEWAL = 'APPROVE_APPLICATION_RENEWAL';
  const REJECT_APPLICATION_RENEWAL = 'REJECT_APPLICATION_RENEWAL';
  const REMOVE_PROOF_OF_PAYMENT = 'REMOVE_PROOF_OF_PAYMENT';
  const GET_RENEWAL_PAYMENT_PDF = 'GET_RENEWAL_PAYMENT_PDF';
  return {
    SET_CURRENT_RENEWAL_FORM_STEP,
    SET_CURRENT_RENEWAL_FORM_RENEW_STEP,
    SET_ACTIVE_RENEWAL_FORM_STEPS,
    CANCEL_RENEWAL,
    CANCEL_RENEWAL_RENEW,

    GET_APPLICATIONS_RENEWALS_TABLE,

    GET_APPLICATION_RENEWAL,
    APPLICATION_RENEWAL_READY,
    SUBMIT_APPLICATION_RENEWAL,
    SAVE_APPLICATION_RENEWAL,
    UPDATE_APPLICATION_RENEWAL,
    DELETE_APPLICATION_RENEWAL,
    APPROVE_APPLICATION_RENEWAL,
    REJECT_APPLICATION_RENEWAL,
    REMOVE_PROOF_OF_PAYMENT,
    GET_RENEWAL_PAYMENT_PDF,
  };
};
export default applicationRenewalsTypes;
