const applicationReviewTypes = () => {
  const SET_CURRENT_APPLICATION_REVIEW_STEP =
    'SET_CURRENT_APPLICATION_REVIEW_STEP';
  const SET_ACTIVE_APPLICATION_REVIEW_STEPS =
    'SET_ACTIVE_APPLICATION_REVIEW_STEPS';

  const APPROVE_APPLICATION = 'APPROVE_APPLICATION';
  const RESET_FIELD_APPLICATION_REVIEW = 'RESET_FIELD_APPLICATION_REVIEW';

  const UPLOAD_INSPECTION = 'UPLOAD_INSPECTION';
  const GET_INSPECTION_REPORT = 'GET_INSPECTION_REPORT';

  const REJECT_PAYMENT = 'REJECT_PAYMENT';
  const APPROVE_PAYMENT = 'APPROVE_PAYMENT';

  const EXTEND_DOCUMENTATION_TIME = 'EXTEND_DOCUMENTATION_TIME';
  const EXTEND_ISSUANCE_FEE_TIME = 'EXTEND_ISSUANCE_FEE_TIME';

  const REJECT_APPLICATION = 'REJECT_APPLICATION';
  const GRANT_APPLICATION = 'GRANT_APPLICATION';

  const GET_ADVERTISING_FEE_MTN = 'GET_ADVERTISING_FEE_MTN';
  const GET_ISSUANCE_FEE_MTN = 'GET_ISSUANCE_FEE_MTN';

  const SEND_COMMENT = 'SEND_COMMENT';
  const SET_STATUS_APPLICATION_REVIEW = 'SET_STATUS_APPLICATION_REVIEW';
  const SET_CURRENT_STEP_APPLICATION_REVIEW =
    'SET_CURRENT_STEP_APPLICATION_REVIEW';
  const GET_SAPS_AND_MUNICIPALITY_REPORTS = 'GET_SAPS_AND_MUNICIPALITY_REPORTS';
  const SET_SAPS_AND_MUNICIPALITY_REPORTS_DRAFT =
    'SET_SAPS_AND_MUNICIPALITY_REPORTS_DRAFT';
  const SEND_INSPECTION_REPORT = 'SEND_INSPECTION_REPORT';
  const RESET_APPLICATION_REVIEW = 'RESET_APPLICATION_REVIEW';
  const SUBMIT_FOR_VOTE = 'SUBMIT_FOR_VOTE';
  const EDIT_INSPECTION_FORM = 'EDIT_INSPECTION_FORM';

  return {
    SET_CURRENT_APPLICATION_REVIEW_STEP,
    SET_ACTIVE_APPLICATION_REVIEW_STEPS,

    APPROVE_APPLICATION,

    RESET_FIELD_APPLICATION_REVIEW,

    UPLOAD_INSPECTION,
    GET_INSPECTION_REPORT,

    REJECT_APPLICATION,

    EXTEND_DOCUMENTATION_TIME,
    EXTEND_ISSUANCE_FEE_TIME,

    REJECT_PAYMENT,
    APPROVE_PAYMENT,

    GET_ADVERTISING_FEE_MTN,
    GET_ISSUANCE_FEE_MTN,

    SEND_COMMENT,
    SET_STATUS_APPLICATION_REVIEW,
    SET_CURRENT_STEP_APPLICATION_REVIEW,
    GET_SAPS_AND_MUNICIPALITY_REPORTS,
    SET_SAPS_AND_MUNICIPALITY_REPORTS_DRAFT,
    SEND_INSPECTION_REPORT,
    RESET_APPLICATION_REVIEW,
    SUBMIT_FOR_VOTE,
    EDIT_INSPECTION_FORM,
    GRANT_APPLICATION,
  };
};
export default applicationReviewTypes;
