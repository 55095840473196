import React, { Suspense } from 'react';
import useLayouts from '../../layouts';
import useComponents from '../../components';
import useControllers from '../../../controllers';

// eslint-disable-next-line react/prop-types
const EmailVerification = () => {
  // Layouts
  const { PublicContentLayout } = useLayouts();

  // Components
  const { ActivityIndicator, Modal } = useComponents();

  // Controllers
  const { useScreenHooks } = useControllers();
  const { useEmailVerification } = useScreenHooks();
  const { modalState, registerState, handleCloseModal } =
    useEmailVerification();

  useEmailVerification();

  return (
    <PublicContentLayout background={'secondary'}>
      <Suspense fallback={<ActivityIndicator />}>
        <>
          <ActivityIndicator />
          <Modal
            type={registerState}
            showModal={modalState.show}
            onClose={handleCloseModal}
            title={modalState.title}
            description={modalState.description}
          />
        </>
      </Suspense>
    </PublicContentLayout>
  );
};

export default EmailVerification;
