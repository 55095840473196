import React from 'react';

const iconLogs = ({ color = '#B9C780', size = 1, className = '' }) => {
  return (
    <svg
      className={className}
      width={(size * 24).toString()}
      height={(size * 24).toString()}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.8719 10.2L20.5919 9.57599C20.2559 8.78399 20.1119 8.44799 19.7999 7.63199L20.9759 5.56799C21.1439 5.27999 21.0959 4.91999 20.8559 4.70399L19.3199 3.16799C19.0799 2.92799 18.7199 2.87999 18.4559 3.04799L16.3919 4.22399C15.5999 3.88799 15.2639 3.74399 14.4479 3.43199L13.8239 1.15199C13.7279 0.839993 13.4399 0.623993 13.1279 0.623993H10.9439C10.6079 0.623993 10.3199 0.839993 10.2479 1.15199L9.62387 3.43199C8.83187 3.76799 8.49587 3.91199 7.67987 4.22399L5.61587 3.04799C5.32788 2.87999 4.96788 2.92799 4.75187 3.16799L3.21587 4.70399C2.97587 4.94399 2.92787 5.30399 3.09587 5.56799L4.27187 7.63199C3.93587 8.42399 3.79187 8.75999 3.47987 9.57599L1.19987 10.2C0.887875 10.296 0.671875 10.584 0.671875 10.896V13.08C0.671875 13.416 0.887875 13.704 1.19987 13.776L3.47987 14.4C3.81587 15.192 3.95987 15.528 4.27187 16.344L3.09587 18.408C2.92787 18.696 2.97587 19.056 3.21587 19.272L4.75187 20.808C4.99187 21.048 5.35187 21.096 5.61587 20.928L7.67987 19.752C8.47188 20.088 8.80787 20.232 9.62387 20.544L10.2479 22.824C10.3439 23.136 10.6319 23.352 10.9439 23.352H13.1279C13.4639 23.352 13.7519 23.136 13.8239 22.824L14.4479 20.544C15.2399 20.208 15.5759 20.064 16.3919 19.752L18.4559 20.928C18.7439 21.096 19.1039 21.048 19.3199 20.808L20.8559 19.272C21.0959 19.032 21.1439 18.672 20.9759 18.408L19.7999 16.344C20.1359 15.552 20.2799 15.216 20.5919 14.4L22.8719 13.776C23.1839 13.68 23.3999 13.392 23.3999 13.08V10.896C23.3999 10.584 23.1839 10.296 22.8719 10.2ZM11.9999 17.04C9.21587 17.04 6.95988 14.784 6.95988 12C6.95988 9.21599 9.21587 6.95999 11.9999 6.95999C14.7839 6.95999 17.0399 9.21599 17.0399 12C17.0399 14.784 14.7839 17.04 11.9999 17.04Z"
        fill={color}
      />
    </svg>
  );
};

export default iconLogs;
