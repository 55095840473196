import React from 'react';

const iconDelete = ({ color = '#394D94', size = 1 }) => {
  return (
    <svg
      width={(size * 14).toString()}
      height={(size * 16).toString()}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.04718 0C6.31334 0 5.8043 0.272903 5.42876 0.559198C5.05323 0.845492 4.79089 1.11768 4.34324 1.29604C4.1847 1.35406 4.06984 1.51541 4.06692 1.68421H0.0800781V2.52632H13.9748V1.68421H9.98797C9.98505 1.51541 9.87019 1.35406 9.71166 1.29604C9.26093 1.11692 8.98486 0.843029 8.61297 0.559198C8.24108 0.275366 7.74656 0 7.04718 0ZM1.34324 2.94737V14.1118C1.34324 15.3714 1.97157 16 3.24455 16H10.8235C12.0781 16 12.7117 15.3714 12.7117 14.1118V2.94737H1.34324ZM3.65903 5.68421H4.50113V13.2632H3.65903V5.68421ZM6.60639 5.68421H7.4485V13.2632H6.60639V5.68421ZM9.55376 5.68421H10.3959V13.2632H9.55376V5.68421Z"
        fill={color}
      />
    </svg>
  );
};

export default iconDelete;
