const applicationFormTypes = () => {
  const SET_CURRENT_APPLICATION_FORM_STEP = 'SET_CURRENT_APPLICATION_FORM_STEP';
  const SET_ACTIVE_APPLICATION_FORM_STEPS = 'SET_ACTIVE_APPLICATION_FORM_STEPS';

  const GET_APPLICATION_HELP_DATA = 'GET_APPLICATION_HELP_DATA';
  const GET_AREA_FROM_OFFICE = 'GET_AREA_FROM_OFFICE';

  const SUBMIT_APPLICATION_FORM_STEP = 'SUBMIT_APPLICATION_FORM_STEP';
  const RESET_FIELD_APPLICATION_FORM = 'RESET_FIELD_APPLICATION_FORM';

  const GET_APPLICATION_BY_ID_FROM_REVIEW = 'GET_APPLICATION_BY_ID_FROM_REVIEW';
  const GET_APPLICATION_FILES = 'GET_APPLICATION_FILES';

  const ADVERTISING_PAYMENT_SUCCESS = 'ADVERTISING_PAYMENT_SUCCESS';
  const APPLICATION_DOCUMENTS_SAVED = 'APPLICATION_DOCUMENTS_SAVED';

  const SUBMIT_ISSUANCE_FEE_PAYMENT = 'SUBMIT_ISSUANCE_FEE_PAYMENT';
  const SAVE_DRAFT_ISSUANCE_FEE_PAYMENT = 'SAVE_DRAFT_ISSUANCE_FEE_PAYMENT';
  const GET_DRAFT_ISSUANCE_FEE_PAYMENT = 'GET_DRAFT_ISSUANCE_FEE_PAYMENT';

  const SUBMIT_APPLICATION_FORM_STEP_2 = 'SUBMIT_APPLICATION_FORM_STEP_2';
  const SUBMIT_APPLICATION_FORM_FINAL = 'SUBMIT_APPLICATION_FORM_FINAL';
  const ENABLE_SECOND_STEP = 'ENABLE_SECOND_STEP';
  const ENABLE_FINAL_STEP = 'ENABLE_FINAL_STEP';
  const CANCEL_APPLICATION = 'CANCEL_APPLICATION';
  const USER_APPLICATION_SAVED = 'USER_APPLICATION_SAVED';
  const SET_APPLICATION_FORM_FROM_TABLE = 'SET_APPLICATION_FORM_FROM_TABLE';
  const APPLICATION_READY = 'APPLICATION_READY';
  const SET_APPLICATION_DATE = 'SET_APPLICATION_DATE';
  const SET_DOCUMENTS_DRAFT = 'SET_DOCUMENTS_DRAFT';
  const UPLOAD_APPLICATION_FEE = 'UPLOAD_APPLICATION_FEE';
  const GET_LICENSE_FEE_PDF = 'GET_LICENSE_FEE_PDF';
  const APPROVE_APPLICATION_FEE = 'APPROVE_APPLICATION_FEE';
  const GET_LICENSE_PDF = 'GET_LICENSE_PDF';

  const GET_PIN_DATA = 'GET_PIN_DATA';

  return {
    SET_CURRENT_APPLICATION_FORM_STEP,
    SET_ACTIVE_APPLICATION_FORM_STEPS,

    GET_APPLICATION_HELP_DATA,
    GET_AREA_FROM_OFFICE,

    SUBMIT_APPLICATION_FORM_STEP,
    RESET_FIELD_APPLICATION_FORM,

    GET_APPLICATION_BY_ID_FROM_REVIEW,

    ADVERTISING_PAYMENT_SUCCESS,
    APPLICATION_DOCUMENTS_SAVED,

    SUBMIT_ISSUANCE_FEE_PAYMENT,
    SAVE_DRAFT_ISSUANCE_FEE_PAYMENT,
    GET_DRAFT_ISSUANCE_FEE_PAYMENT,

    USER_APPLICATION_SAVED,
    SUBMIT_APPLICATION_FORM_STEP_2,
    SUBMIT_APPLICATION_FORM_FINAL,
    ENABLE_SECOND_STEP,
    ENABLE_FINAL_STEP,
    CANCEL_APPLICATION,
    SET_APPLICATION_FORM_FROM_TABLE,
    APPLICATION_READY,
    SET_APPLICATION_DATE,
    SET_DOCUMENTS_DRAFT,
    UPLOAD_APPLICATION_FEE,
    GET_LICENSE_FEE_PDF,
    APPROVE_APPLICATION_FEE,
    GET_APPLICATION_FILES,
    GET_LICENSE_PDF,

    GET_PIN_DATA,
  };
};
export default applicationFormTypes;
