import React from 'react';

const iconInfo = ({ color = '#FF9500', size = 2 }) => {
  return (
    <svg
      width={(size * 20).toString()}
      height={(size * 20).toString()}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
            fillRule="evenodd" 
            clipRule="evenodd" 
            d="M16.5137 6.19796C18.0437 3.47796 21.9577 3.47796 23.4857 6.19796L34.6457 26.038C36.1457 28.706 34.2197 31.998 31.1617 31.998H8.8397C5.7797 31.998 3.8537 28.706 5.3537 26.038L16.5137 6.19796ZM21.9997 26C21.9997 26.5304 21.789 27.0391 21.4139 27.4142C21.0388 27.7892 20.5301 28 19.9997 28C19.4693 28 18.9606 27.7892 18.5855 27.4142C18.2104 27.0391 17.9997 26.5304 17.9997 26C17.9997 25.4695 18.2104 24.9608 18.5855 24.5857C18.9606 24.2107 19.4693 24 19.9997 24C20.5301 24 21.0388 24.2107 21.4139 24.5857C21.789 24.9608 21.9997 25.4695 21.9997 26ZM19.9997 9.99996C19.4693 9.99996 18.9606 10.2107 18.5855 10.5857C18.2104 10.9608 17.9997 11.4695 17.9997 12V18C17.9997 18.5304 18.2104 19.0391 18.5855 19.4142C18.9606 19.7892 19.4693 20 19.9997 20C20.5301 20 21.0388 19.7892 21.4139 19.4142C21.789 19.0391 21.9997 18.5304 21.9997 18V12C21.9997 11.4695 21.789 10.9608 21.4139 10.5857C21.0388 10.2107 20.5301 9.99996 19.9997 9.99996Z" 
            fill={color} 
      />
    </svg>
  );
};

export default iconInfo;
