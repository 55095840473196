import React from 'react';

const iconClose = ({ color = '#394D94', size = 1 }) => {
  return (
    <svg
      width={(size * 32).toString()}
      height={(size * 32).toString()}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.33741 0.563952L4.59075 0.787645L16 12.1973L27.4093 0.787645C28.4595 -0.262548 30.1622 -0.262548 31.2124 0.787645C32.1818 1.75706 32.2564 3.28246 31.4361 4.33741L31.2124 4.59075L19.8027 16L31.2124 27.4093C32.2626 28.4595 32.2626 30.1622 31.2124 31.2124C30.243 32.1818 28.7176 32.2563 27.6626 31.4361L27.4093 31.2124L16 19.8027L4.59075 31.2124C3.54056 32.2626 1.83784 32.2626 0.787648 31.2124C-0.181763 30.243 -0.256336 28.7176 0.563934 27.6626L0.787648 27.4093L12.1974 16L0.787648 4.59075C-0.262547 3.54055 -0.262547 1.83784 0.787648 0.787645C1.75706 -0.181764 3.28247 -0.256318 4.33741 0.563952Z"
        fill={color}
      />
    </svg>
  );
};

export default iconClose;
