import React from 'react';

const iconAt = ({ color = '#FFFFFF', size = 1 }) => {
  return (
    <svg
      width={(size * 24).toString()}
      height={(size * 24).toString()}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9999 2.24915C10.2567 2.24898 8.54522 2.71622 7.04387 3.60219C5.54252 4.48815 4.30616 5.76045 3.46356 7.28656C2.62097 8.81268 2.20295 10.5368 2.25305 12.2793C2.30315 14.0219 2.81953 15.7191 3.74842 17.1943C4.6773 18.6695 5.98471 19.8687 7.53448 20.6669C9.08426 21.4652 10.8197 21.8333 12.5601 21.733C14.3005 21.6327 15.9821 21.0676 17.4299 20.0965C18.8777 19.1255 20.0387 17.7841 20.7919 16.2119C20.2587 16.4095 19.6935 16.5069 19.1249 16.4991C17.4105 16.4991 16.4508 15.7487 15.9476 15.1191C15.8304 14.972 15.7245 14.8163 15.6309 14.6532C15.0948 15.3877 14.3487 15.9426 13.491 16.2447C12.6333 16.5468 11.7041 16.582 10.826 16.3457C9.94788 16.1094 9.16195 15.6125 8.57175 14.9207C7.98155 14.2289 7.61478 13.3745 7.51974 12.4701C7.4247 11.5657 7.60587 10.6537 8.03936 9.85433C8.47285 9.05493 9.13834 8.4056 9.94816 7.99188C10.758 7.57816 11.6741 7.41947 12.5759 7.5367C13.4777 7.65393 14.3228 8.04159 14.9999 8.64861V8.24915C14.9999 8.05023 15.0789 7.85947 15.2196 7.71881C15.3602 7.57816 15.551 7.49915 15.7499 7.49915C15.9488 7.49915 16.1396 7.57816 16.2803 7.71881C16.4209 7.85947 16.4999 8.05023 16.4999 8.24915V11.9991C16.4999 13.3678 16.9553 14.9991 19.1249 14.9991C21.2198 14.9991 21.7164 13.4782 21.7481 12.1414C21.7488 12.094 21.7499 12.0467 21.7499 11.9991C21.747 9.41418 20.7188 6.93593 18.891 5.10809C17.0631 3.28024 14.5849 2.25207 11.9999 2.24915Z"
        fill={color}
      />
      <path
        d="M12 14.9991C13.6569 14.9991 15 13.656 15 11.9991C15 10.3423 13.6569 8.99915 12 8.99915C10.3431 8.99915 9 10.3423 9 11.9991C9 13.656 10.3431 14.9991 12 14.9991Z"
        fill={color}
      />
    </svg>
  );
};

export default iconAt;
