import React from 'react';

const iconLogoMobile = ({ size = 1 }) => {
  return (
    <svg
      width={(size * 29).toString()}
      height={(size * 32).toString()}
      viewBox="0 0 29 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.79074 13.0367C8.7843 13.0818 9.57463 13.2173 10.3499 13.4732C11.5467 13.8722 12.751 14.2711 13.9478 14.67C14.3016 14.7829 14.6478 14.9034 15.0016 15.0163C15.2048 15.084 15.2198 15.1066 15.122 15.3023C14.8059 15.9722 14.4973 16.6421 14.1811 17.312C13.7822 18.1701 13.3983 19.0357 12.9618 19.8711C12.5553 20.6539 12.0209 21.354 11.2682 21.8432C10.3499 22.4454 9.40151 22.4604 8.43053 21.9787C7.51224 21.5271 6.83482 20.797 6.2703 19.9615C5.72836 19.1636 5.32943 18.2905 5.10362 17.3496C4.87781 16.4012 4.81007 15.4528 5.21652 14.527C5.52513 13.8345 6.05954 13.3904 6.78213 13.2023C7.17353 13.1119 7.57246 13.0743 7.79074 13.0367Z"
        fill="#B9C780"
      />
      <path
        d="M14.6478 26.0884C14.6929 25.2303 14.8134 24.1615 14.9714 23.2131C15.1596 22.0841 15.4306 20.9701 15.679 19.8561C15.8822 18.9378 16.1005 18.0346 16.3187 17.1163C16.394 16.7926 16.4693 16.469 16.5446 16.1528C16.5596 16.1002 16.6048 16.055 16.6349 16.0023C16.6725 16.0399 16.7327 16.0776 16.7553 16.1227C17.0639 16.7625 17.365 17.4098 17.6736 18.0496C18.1177 18.9679 18.5768 19.8862 19.0134 20.812C19.5177 21.8809 19.879 22.9948 20.0747 24.1615C20.2704 25.3207 20.2855 26.4873 20.007 27.6314C19.7811 28.5497 19.4123 29.4003 18.7198 30.0626C18.2456 30.5218 17.7037 30.8153 17.0112 30.7627C16.4843 30.725 16.0854 30.454 15.7542 30.0702C15.3929 29.6487 15.1822 29.1594 15.0316 28.625C14.7908 27.7895 14.6478 26.9465 14.6478 26.0884Z"
        fill="#B9C780"
      />
      <path
        d="M18.1252 15.6786C18.2757 15.7238 18.4188 15.7614 18.5693 15.8141C19.3596 16.0926 20.1499 16.3711 20.9327 16.6571C21.9414 17.0184 22.965 17.312 23.9209 17.8087C25.622 18.6894 26.9995 19.8937 27.7973 21.6851C28.1812 22.5507 28.3543 23.454 28.2339 24.3948C28.1586 24.9669 27.9704 25.4938 27.564 25.9153C27.1048 26.4045 26.5403 26.5852 25.878 26.4798C25.0575 26.3443 24.4102 25.9002 23.8156 25.3583C22.679 24.3271 21.836 23.0701 21.1134 21.7303C20.436 20.4733 19.8112 19.1786 19.1714 17.8991C18.8478 17.2593 18.5241 16.627 18.208 15.9872C18.1704 15.9044 18.1327 15.8216 18.0951 15.7388C18.1026 15.7163 18.1101 15.7012 18.1252 15.6786Z"
        fill="#B9C780"
      />
      <path
        d="M5.48743 4.86993C5.51001 4.20756 5.63044 3.51508 6.08958 2.92798C6.54873 2.3484 7.13583 2.11507 7.881 2.27313C8.63369 2.43873 9.22079 2.87529 9.75521 3.39465C10.5907 4.20003 11.2681 5.1409 11.795 6.16456C12.6982 7.91834 13.5563 9.70223 14.4294 11.4711C14.595 11.8022 14.7606 12.1334 14.9187 12.4646C14.9488 12.5173 14.9413 12.5926 14.9563 12.6528C14.8886 12.6528 14.8208 12.6603 14.7606 12.6453C14.3918 12.5173 14.0155 12.3818 13.6466 12.2539C13.1198 12.0732 12.6004 11.885 12.0735 11.7119C10.9445 11.3356 9.83048 10.9442 8.82186 10.2893C7.72293 9.57427 6.81217 8.67857 6.19496 7.51189C5.74334 6.69898 5.50248 5.84091 5.48743 4.86993Z"
        fill="#B9C780"
      />
      <path
        d="M13.6693 2.80002C13.6392 2.35593 13.7144 1.82904 14.0004 1.35484C14.2564 0.925807 14.6252 0.677418 15.137 0.64731C15.98 0.594621 16.665 0.970969 17.2897 1.46775C18.2306 2.22044 18.8478 3.19894 19.2016 4.34304C19.5026 5.32907 19.5177 6.33015 19.2919 7.33124C19.0962 8.18931 18.7048 8.95706 18.2682 9.70975C17.8392 10.4399 17.4252 11.17 17.0037 11.9001C16.9736 11.9528 16.9209 11.9829 16.8833 12.0281C16.8456 11.9829 16.7929 11.9528 16.7704 11.9001C16.2435 10.6807 15.7015 9.46136 15.1897 8.23447C14.8435 7.40651 14.4972 6.57102 14.2037 5.728C13.8875 4.82476 13.6542 3.8839 13.6693 2.80002Z"
        fill="#B9C780"
      />
      <path
        d="M3.40249 0.0301069C3.36486 0.0978494 3.33475 0.165592 3.28959 0.225808C2.77023 0.955922 2.33367 1.73872 2.00248 2.56669C1.76162 3.16884 1.58097 3.80863 1.41538 4.4409C1.0315 5.8936 0.911073 7.37641 0.971289 8.86674C1.0014 9.65707 1.06914 10.4474 1.20462 11.2227C1.35516 12.1184 1.58097 13.0066 1.79925 13.8872C2.0928 15.0614 2.53689 16.1905 3.0412 17.2969C3.60572 18.5464 4.26809 19.7357 5.02831 20.8798C5.72832 21.9411 6.49607 22.9421 7.33909 23.883C8.40039 25.0647 9.55201 26.1637 10.8466 27.1045C11.4036 27.511 11.9757 27.8949 12.5477 28.2863C12.7209 28.4067 12.909 28.497 13.0972 28.6024C13.2327 28.6777 13.2854 28.7906 13.323 28.9411C13.451 29.4153 13.609 29.882 13.752 30.3562C13.7596 30.3788 13.7671 30.4013 13.7897 30.4917C13.6241 30.439 13.4886 30.4089 13.3757 30.3486C11.6596 29.4906 10.0789 28.4368 8.62619 27.1723C7.18102 25.9153 5.90897 24.5077 4.7724 22.9722C3.98959 21.9109 3.27454 20.8045 2.69496 19.6228C2.19818 18.6066 1.75409 17.5604 1.32506 16.5141C1.09925 15.9571 0.911073 15.37 0.775588 14.7829C0.54978 13.8119 0.323972 12.8334 0.188487 11.8474C0.0605285 10.9668 0.00031298 10.0786 0.00031298 9.1904C-0.00721397 7.94845 0.120744 6.71404 0.414295 5.5022C0.798169 3.92907 1.40785 2.46131 2.36377 1.1441C2.61969 0.790329 2.91324 0.46667 3.19927 0.135484C3.24443 0.0827956 3.3197 0.0451612 3.37991 -4.76837e-07C3.37238 -4.76837e-07 3.38744 0.015053 3.40249 0.0301069Z"
        fill="#B9C780"
      />
      <path
        d="M24.2897 15.355C23.522 15.3173 22.7919 15.1442 22.0768 14.9109C21.2564 14.6399 20.4435 14.3539 19.623 14.0754C19.2015 13.9324 18.78 13.7894 18.3585 13.6464C18.2531 13.6087 18.1929 13.5636 18.2456 13.4431C18.8252 12.1786 19.3972 10.9141 19.9994 9.65707C20.2854 9.06244 20.6994 8.54308 21.2639 8.17426C22.0392 7.66996 22.837 7.70006 23.6349 8.12157C24.5532 8.6033 25.193 9.36352 25.7274 10.2291C26.0812 10.8087 26.3371 11.4259 26.4951 12.0807C26.6532 12.7582 26.7134 13.4356 26.4575 14.098C26.2166 14.7378 25.7349 15.1066 25.0801 15.2496C24.8242 15.2948 24.5607 15.3173 24.2897 15.355Z"
        fill="#B9C780"
      />
      <path
        d="M28.4145 28.2035C28.3543 28.3013 28.3016 28.3992 28.2263 28.482C27.3758 29.4755 26.3747 30.296 25.193 30.868C24.7413 31.0863 24.2671 31.2519 23.8005 31.4401C23.0252 31.7562 22.1972 31.8616 21.3768 31.9745C20.9252 32.0422 20.8951 31.997 21.0531 31.568C21.1058 31.4175 21.1359 31.2594 21.166 31.0938C21.1886 30.9583 21.2413 30.8981 21.3843 30.8906C21.8962 30.8605 22.4155 30.8454 22.9198 30.7702C23.4015 30.6949 23.8682 30.5669 24.3349 30.4314C24.8241 30.2809 25.3134 30.1304 25.7725 29.9046C26.3295 29.6261 26.8564 29.2798 27.3833 28.9336C27.6994 28.7228 27.9779 28.4594 28.2715 28.226C28.3016 28.2035 28.3392 28.1884 28.3693 28.1658C28.3919 28.1809 28.3994 28.1884 28.4145 28.2035Z"
        fill="#B9C780"
      />
    </svg>
  );
};

export default iconLogoMobile;
