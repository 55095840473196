import React from 'react';

const iconLogout = ({ color = '#F3F5F7', size = 1 }) => {
  return (
    <svg
      width={(size * 20).toString()}
      height={(size * 26).toString()}
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.696 8.24C19.696 7.976 19.6 7.736 19.408 7.568L15.856 3.99199C15.472 3.60799 14.872 3.60799 14.488 3.99199C14.104 4.37599 14.104 4.976 14.488 5.36L16.408 7.28H6.88C6.352 7.28 5.92 7.712 5.92 8.24C5.92 8.768 6.352 9.2 6.88 9.2H16.432L14.512 11.12C14.128 11.504 14.128 12.104 14.512 12.488C14.704 12.68 14.944 12.776 15.184 12.776C15.424 12.776 15.664 12.68 15.856 12.488L19.408 8.936C19.6 8.744 19.696 8.504 19.696 8.24Z"
        fill={color}
      />
      <path
        d="M4.432 2.48H10.48C11.008 2.48 11.44 2.048 11.44 1.52C11.44 0.991998 11.008 0.559998 10.48 0.559998H4.432C2.488 0.559998 0.879999 2.168 0.879999 4.112V12.344C0.879999 14.312 2.488 15.92 4.432 15.92H10.48C11.008 15.92 11.44 15.488 11.44 14.96C11.44 14.432 11.008 14 10.48 14H4.432C3.52 14 2.8 13.256 2.8 12.368V4.112C2.8 3.224 3.544 2.48 4.432 2.48Z"
        fill={color}
      />
    </svg>
  );
};

export default iconLogout;
