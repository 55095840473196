import React from 'react';

const iconMyAplication = ({ color = '#F3F5F7', size = 1, className = '' }) => {
  return (
    <svg
      className={className}
      width={(size * 24).toString()}
      height={(size * 20).toString()}
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9359 0.883281C11.8839 0.889953 11.833 0.905217 11.7859 0.928281L1.2259 6.20828C1.06549 6.28673 0.956742 6.46095 0.956742 6.63953C0.956742 6.81811 1.06549 6.99234 1.2259 7.07078L11.7859 12.3508C11.92 12.419 12.0867 12.419 12.2209 12.3508L22.7809 7.07078C22.9413 6.99234 23.05 6.81811 23.05 6.63953C23.05 6.46095 22.9413 6.28673 22.7809 6.20828L12.2209 0.928281C12.1334 0.884757 12.0325 0.868818 11.9359 0.883281ZM1.42839 9.51578C1.2121 9.51602 1.00707 9.68092 0.960318 9.8921C0.91359 10.1033 1.02994 10.3392 1.2259 10.4308L11.7859 15.7108C11.92 15.779 12.0867 15.779 12.2209 15.7108L22.7809 10.4308C23.0067 10.3169 23.1086 10.0078 22.9947 9.78202C22.8808 9.55622 22.5717 9.45439 22.3459 9.56827L12.0034 14.7433L1.66088 9.56827C1.58941 9.53169 1.50865 9.51348 1.42839 9.51578ZM1.42839 12.8758C1.2121 12.876 1.00707 13.0409 0.960318 13.2521C0.91359 13.4633 1.02994 13.6993 1.2259 13.7908L11.7859 19.0708C11.92 19.139 12.0867 19.139 12.2209 19.0708L22.7809 13.7908C23.0067 13.6769 23.1086 13.3678 22.9947 13.142C22.8808 12.9162 22.5717 12.8144 22.3459 12.9283L12.0034 18.1033L1.66088 12.9283C1.58941 12.8917 1.50865 12.8735 1.42839 12.8758Z"
        fill={color}
      />
    </svg>
  );
};

export default iconMyAplication;
