import React, { useEffect } from 'react';
import axios from 'axios';
import useGeneralHooks from '../../controllers/generalHooks';
import useComponents from '../../views/components';
import useTypes from '../../strings/types';

const useInterceptor = (store) => {
  const { useToast } = useGeneralHooks();
  const { error } = useToast();
  const { Toast } = useComponents();
  const { useAuthTypes } = useTypes();
  const { LEAVE_BROADCASTING } = useAuthTypes();

  const handleResponseSuccess = (response) => {
    return response;
  };

  const handleResponseError = (errorRequest) => {
    switch (errorRequest?.response?.status) {
      case 401:
        store.dispatch({ type: LEAVE_BROADCASTING });
        break;
      case 400:
        error(
          <Toast
            text={errorRequest?.response?.data?.message}
            listOfErrors={errorRequest?.response?.data?.errors}
            state={'error'}
          />,
          {
            position: 'top-right',
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            toastId: Math.random(),
          }
        );
        break;
      case 409:
        error(
          <Toast
            text={errorRequest?.response?.data?.message}
            listOfErrors={errorRequest?.response?.data?.errors}
            state={'error'}
          />,
          {
            position: 'top-right',
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            toastId: Math.random(),
          }
        );
        break;
      case 422:
        error(
          <Toast
            text={errorRequest?.response?.data?.message}
            listOfErrors={errorRequest?.response?.data?.errors}
            state={'error'}
          />,
          {
            position: 'top-right',
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            toastId: Math.random(),
          }
        );
        break;
      case 500:
        error(
          <Toast
            text={errorRequest?.response?.data?.message}
            listOfErrors={[]}
            state={'error'}
          />,
          {
            position: 'top-right',
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            toastId: Math.random(),
          }
        );
        break;
      case 504:
        error(
          <Toast text={'Request Timeout'} listOfErrors={[]} state={'error'} />,
          {
            position: 'top-right',
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            toastId: Math.random(),
          }
        );
        break;
      default:
        console.error(error);
    }
    throw error;
  };

  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.params = {};
    axios.interceptors.response.use(handleResponseSuccess, handleResponseError);
  }, []);
};

export default useInterceptor;
