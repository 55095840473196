import React from 'react';

const iconTimer = ({ color = '#B6BBC1', size = 1 }) => {
  return (
    <svg
      width={(size * 20).toString()}
      height={(size * 20).toString()}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M10.0004 19.5999C12.5465 19.5999 14.9883 18.5885 16.7886 16.7881C18.589 14.9878 19.6004 12.546 19.6004 9.9999C19.6004 7.45382 18.589 5.01203 16.7886 3.21168C14.9883 1.41133 12.5465 0.399902 10.0004 0.399902C7.45431 0.399902 5.01252 1.41133 3.21217 3.21168C1.41182 5.01203 0.400391 7.45382 0.400391 9.9999C0.400391 12.546 1.41182 14.9878 3.21217 16.7881C5.01252 18.5885 7.45431 19.5999 10.0004 19.5999ZM11.2004 5.1999C11.2004 4.88164 11.074 4.57642 10.8489 4.35137C10.6239 4.12633 10.3187 3.9999 10.0004 3.9999C9.68213 3.9999 9.37691 4.12633 9.15186 4.35137C8.92682 4.57642 8.80039 4.88164 8.80039 5.1999V9.9999C8.80046 10.3181 8.92693 10.6233 9.15199 10.8483L12.5456 14.2431C12.6571 14.3546 12.7894 14.443 12.9351 14.5034C13.0808 14.5637 13.2369 14.5948 13.3946 14.5948C13.5523 14.5948 13.7084 14.5637 13.8541 14.5034C13.9997 14.443 14.1321 14.3546 14.2436 14.2431C14.3551 14.1316 14.4435 13.9993 14.5039 13.8536C14.5642 13.7079 14.5953 13.5518 14.5953 13.3941C14.5953 13.2364 14.5642 13.0803 14.5039 12.9346C14.4435 12.789 14.3551 12.6566 14.2436 12.5451L11.2004 9.5031V5.1999Z"
        fill={color}
      />
    </svg>
  );
};

export default iconTimer;
