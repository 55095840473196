import React from 'react';

const iconOk = ({ color = '#B6BBC1', size = 1 }) => {
  return (
    <svg
      width={(size * 24).toString()}
      height={(size * 24).toString()}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M12.0004 21.5999C14.5465 21.5999 16.9883 20.5885 18.7886 18.7881C20.589 16.9878 21.6004 14.546 21.6004 11.9999C21.6004 9.45382 20.589 7.01203 18.7886 5.21168C16.9883 3.41133 14.5465 2.3999 12.0004 2.3999C9.45431 2.3999 7.01252 3.41133 5.21217 5.21168C3.41182 7.01203 2.40039 9.45382 2.40039 11.9999C2.40039 14.546 3.41182 16.9878 5.21217 18.7881C7.01252 20.5885 9.45431 21.5999 12.0004 21.5999ZM16.4488 10.4483C16.6674 10.222 16.7883 9.91886 16.7856 9.60422C16.7829 9.28959 16.6567 8.98861 16.4342 8.76612C16.2117 8.54363 15.9107 8.41743 15.5961 8.41469C15.2814 8.41196 14.9783 8.53291 14.752 8.7515L10.8004 12.7031L9.24879 11.1515C9.02247 10.9329 8.71935 10.812 8.40471 10.8147C8.09007 10.8174 7.7891 10.9436 7.56661 11.1661C7.34412 11.3886 7.21792 11.6896 7.21518 12.0042C7.21245 12.3189 7.3334 12.622 7.55199 12.8483L9.95199 15.2483C10.177 15.4733 10.4822 15.5996 10.8004 15.5996C11.1186 15.5996 11.4238 15.4733 11.6488 15.2483L16.4488 10.4483Z" 
        fill={color}
      />
    </svg>
  );
};

export default iconOk;
